import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { OsintService } from 'datalayer/services/osint/osint.service';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { has, head } from 'lodash-es';
import { Observable, fromEvent, of } from 'rxjs';
import { catchError, debounceTime, map, startWith } from 'rxjs/operators';
import { TargetRecycleBinService } from 'src/app/modules/data-layer/services/recycle-bin/target-recycle-bin.service';
import { ProfilerService } from 'src/app/modules/profiler/services/profiler.service';
import { DataIMExport } from 'src/app/modules/search-intel/models/search-intel.model';
import { SearchIntelService } from 'src/app/modules/search-intel/services/search-intel.service';
import { CountryPipe } from 'src/app/pipes/country.pipe';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { ImageService } from 'src/app/services/image/image.service';
import { QueryService } from 'src/app/services/query/query.service';
import { RoleManagementService } from 'src/app/services/roles/role-management.service';
import { RedirectSnackBarService } from 'src/app/services/snack-bar.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { TargetService } from 'src/app/services/target/target.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { UserService } from 'src/app/services/user/user.service';
import { BaseComponent } from 'src/app/shared/classes/base.component';
import { Platforms } from 'src/app/shared/models/radical-monitoring-options.model';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { TimeZone } from 'src/app/shared/models/timezone';
import {
  isValidIMSI,
  isValidImeiIgnoreChecksum,
} from 'src/app/shared/util/helper';
import {
  matomoActions,
  matomoCategories,
} from 'src/app/shared/values/matomo-config';

@Component({
  selector: 'app-target-form',
  templateUrl: './target-form.component.html',
  styleUrls: ['./target-form.component.scss'],
})
export class TargetFormComponent extends BaseComponent implements OnInit {
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  title = 'Create a target';
  usernames: string[];
  createTargetForm: UntypedFormGroup;
  editMode = false;
  errorMsg = false;
  errorMsgForImsi = false;
  errorMsgForIfas = false;
  errorMsgForTmsi = false;
  errorMsgForImei = false;
  errorMsgForImage = false;
  isAdmin = false;
  isSupport = false;
  isPower = false;
  noPhotoUrl = 'assets/static/images/no_photo.svg';
  avatarUrl: any = this.noPhotoUrl;
  avatar: File = null;
  countryCode = '';
  countries = [];
  filteredCountries: Observable<any[]>;
  countryControl = new UntypedFormControl();
  submitButtonText: string;
  timezones: { offset: string; label: string; tzCode: string }[];
  phoneNumberUtil = PhoneNumberUtil.getInstance();
  currentTarget: TargetItem;
  intelSearchFlag = false;
  createCase = false;
  targetRecycleBin: { [key: string]: any[] } = {};
  reEnabledSeeds: { [key: string]: string[] } = {};
  deletedValues: { [key: string]: any } = {};
  enableAdInt: boolean;
  instantMessagingProfileIds: DataIMExport;
  matomo = {
    actions: matomoActions,
    categories: matomoCategories,
  };
  allowedCharacters = {
    number: '1234567890',
    telno: '1234567890+',
  };

  constructor(
    public dialogRef: MatDialogRef<TargetFormComponent>,
    @Inject(MAT_DIALOG_DATA) public targetData: any, // { target: TargetItem, isIntelSearch: boolean } | TargetItem,
    private queryService: QueryService,
    private targetService: TargetService,
    private roleManagementService: RoleManagementService,
    private translationService: TranslationService,
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private countryPipe: CountryPipe,
    private router: Router,
    private profilerService: ProfilerService,
    private imageService: ImageService,
    private osintService: OsintService,
    private targetRecycleBinService: TargetRecycleBinService,
    private appConfigService: AppConfigService,
    private searchIntelService: SearchIntelService,
    private redirectSnackBarService: RedirectSnackBarService
  ) {
    super();

    this.enableAdInt = this.appConfigService.getConfigVariable('enableAdInt');
  }

  @ViewChild('countryInput', { static: true }) countryInput: ElementRef;

  ngOnInit() {
    if (has(this.targetData, 'isIntelSearch')) {
      this.currentTarget = this.targetData.target;
      this.intelSearchFlag = this.targetData.isIntelSearch;
      this.instantMessagingProfileIds =
        this.targetData.instantMessagingProfileIds;
    } else {
      this.currentTarget = this.targetData;
    }

    if (has(this.targetData, 'ifas')) {
      this.currentTarget.ifas = this.targetData.ifas;
    }

    if (has(this.targetData, 'msisdns')) {
      this.currentTarget.telnos = this.targetData.msisdns;
    }

    this.isAdmin = this.roleManagementService.userIsAdmin();
    this.isSupport = this.roleManagementService.userIsSupportUser();
    this.isPower = this.roleManagementService.userIsPowerUser();
    this.editMode = this.currentTarget?.alias?.length > 0;
    this.title =
      this.currentTarget?.alias?.length > 0
        ? 'Edit a target'
        : 'Create a target';
    this.submitButtonText =
      this.currentTarget?.alias?.length > 0 ? 'Update' : 'Create';
    this.countries = this.countryPipe.getAllCountries();
    this.timezones = TimeZone;

    this.getRecycleBin();

    if (!this.currentTarget) {
      this.currentTarget = {
        telnos: [],
        imsis: [],
        countries: [],
        names: [],
        ifas: [],
      };
    } else {
      this.currentTarget.telnos =
        this.currentTarget.telnos && this.currentTarget.telnos.length > 0
          ? this.currentTarget.telnos
          : [];
      this.currentTarget.imsis =
        this.currentTarget.imsis && this.currentTarget.imsis.length > 0
          ? this.currentTarget.imsis
          : [];
      this.currentTarget.countries =
        this.currentTarget.countries && this.currentTarget.countries.length > 0
          ? this.currentTarget.countries
          : [];
      this.currentTarget.names =
        this.currentTarget.names && this.currentTarget.names.length > 0
          ? this.currentTarget.names
          : [];
      this.currentTarget.ifas =
        this.currentTarget.ifas && this.currentTarget.ifas.length > 0
          ? this.currentTarget.ifas
          : [];
    }

    this.subscriptions.push(
      this.userService.getAllUsernames().subscribe((usernames: string[]) => {
        this.usernames = usernames;
      })
    );

    this.initForm();

    this.filteredCountries = fromEvent(
      this.countryInput.nativeElement,
      'keydown'
    ).pipe(
      debounceTime(300),
      startWith(null),
      map((elem: string | null) =>
        elem
          ? this._filter(this.countryInput.nativeElement.value)
          : this.countries.slice()
      )
    );
  }

  createCaseState(event: MatCheckboxChange) {
    this.createCase = event.checked;
  }

  onRemoveTelno(telno: string, key = 'telnos') {
    if (
      !this.targetService.getValidPhone(this.phoneNumberUtil, telno) ||
      !this.isValueUnique('telnos', telno)
    ) {
      const index = this.currentTarget.telnos.indexOf(telno, 0);
      if (index > -1) {
        this.currentTarget.telnos.splice(index, 1);
        this.createTargetForm.controls['telnos'].setValue(
          this.currentTarget.telnos.join(', ')
        );
      }
      return;
    }

    const inRecycleBin = this.wasDeleted(telno, key);

    if (inRecycleBin) {
      this.removeFromLocalRecycleBin(telno, key);
    } else {
      if (this.targetRecycleBin[key]) {
        this.targetRecycleBin[key].push(telno);
      } else {
        this.targetRecycleBin[key] = [telno];
      }
      this.deleteReEnabledSeeds(key, telno);
      this.deletedValues[telno] = key;
    }
    const notDeletedTelnos = this.currentTarget.telnos.filter(
      (t) => !this.deletedValues[t]
    );
    this.createTargetForm.controls[key].setValue(notDeletedTelnos.join(', '));
  }

  addTelno(event, key = 'telnos') {
    const input = event.input;
    const value = event.value.trim();
    const checkIfPastedVal: string[] = value.split('+').filter(Boolean);

    if (value !== '') {
      if (checkIfPastedVal.length > 1) {
        checkIfPastedVal.forEach((pastedValue) =>
          this.handleTelnoValues(pastedValue.replace(',', ''), key)
        );
      } else {
        this.handleTelnoValues(value, key);
      }
    }

    this.createTargetForm.controls[key].setValue(
      this.currentTarget.telnos.join(', ')
    );
    input.value = '';
  }

  public preventUserFromAddingCharacters(
    event: KeyboardEvent,
    allowedCharacters: string
  ) {
    if (!allowedCharacters.includes(event.key)) event.preventDefault();
  }

  private handleTelnoValues(value: string, key: string): void {
    value = '+'.concat(value);
    const isValidValue = this.editValidationPhone(value);
    const msisdn = isValidValue ? isValidValue : value;
    const wasDeleted = this.wasDeleted(msisdn, key);

    if (wasDeleted) {
      this.removeFromLocalRecycleBin(msisdn, key);
    } else {
      if (this.currentTarget.telnos?.indexOf(msisdn) > -1) {
        return;
      }
      this.currentTarget.telnos.push(msisdn);
      this.deleteReEnabledSeeds(key, msisdn);
    }
  }

  onRemoveAdId(adId) {
    const index = this.currentTarget.ifas.indexOf(adId, 0);
    if (index > -1) {
      this.currentTarget.ifas.splice(index, 1);
      this.createTargetForm.controls['ifas'].setValue(
        this.currentTarget.ifas.join(', ')
      );
    }
  }

  addAdId(event) {
    const input = event.input;
    const value = event.value.trim();
    if (value !== '') {
      this.currentTarget.ifas.push(value);
      this.createTargetForm.controls['ifas'].setValue(
        this.currentTarget.ifas.join(', ')
      );
      input.value = '';
    }
  }

  onRemoveImsi(email) {
    const index = this.currentTarget.imsis.indexOf(email, 0);
    if (index > -1) {
      this.currentTarget.imsis.splice(index, 1);
      this.createTargetForm.controls['imsis'].setValue(
        this.currentTarget.imsis.join(', ')
      );
    }
  }

  addImsi(event) {
    const input = event.input;
    const value = event.value.trim();
    if (this.currentTarget.imsis.indexOf(value) > -1) {
      input.value = '';
      return;
    }
    if (value !== '') {
      this.currentTarget.imsis.push(value);
      this.createTargetForm.controls['imsis'].setValue(
        this.currentTarget.imsis.join(', ')
      );
      input.value = '';
    }
  }

  onRemoveCountry(country) {
    const index = this.currentTarget.countries.indexOf(country, 0);
    if (index > -1) {
      this.currentTarget.countries.splice(index, 1);
      this.createTargetForm.controls['countries'].setValue(
        this.currentTarget.countries.join(', ')
      );
    }
    this.updateContryCode();
  }

  countryValidation(event) {
    const country = event.option.viewValue;
    const index = this.currentTarget.countries.indexOf(country);

    if (index < 0) {
      this.currentTarget.countries.push(country.trim());
      this.createTargetForm.controls['countries'].setValue(
        this.currentTarget.countries.join(', ')
      );
    }

    this.countryInput.nativeElement.value = '';
    this.updateContryCode();
  }

  updateContryCode() {
    this.countryCode = '';
    const selectedCountry = this.countryCodeFromCountryList();
    if (selectedCountry) {
      this.countryCode = selectedCountry.toLowerCase();
      this.createTargetForm.controls['countryCode'].setValue(this.countryCode);
    } else {
      this.createTargetForm.controls['countryCode'].setValue('');
    }
  }

  private countryCodeFromCountryList(): string | undefined {
    const firstCountry = head(this.currentTarget.countries);
    let selectedCountry: string | undefined = undefined;
    if (firstCountry) {
      selectedCountry = this.countries.find(function (obj) {
        return (
          obj.countryName.toLowerCase().trim() ===
          firstCountry.toLowerCase().trim()
        );
      })?.countryCode;
    }
    return selectedCountry;
  }

  editValidationPhone(value) {
    return this.targetService.getValidPhone(this.phoneNumberUtil, value);
  }

  isValueUnique(targetProperty: string, value): boolean {
    return (
      this.currentTarget[targetProperty]?.filter((item) => item === value)
        .length === 1
    );
  }

  editValidationImsi(imsi) {
    return isValidIMSI(imsi) && this.isValueUnique('imsis', imsi);
  }

  editValidationAdId(adid) {
    return this.queryService.isValidIfa(adid);
  }

  private initForm(): void {
    const alias = (this.currentTarget && this.currentTarget.alias) || '';
    const names =
      this.currentTarget && this.currentTarget.names
        ? this.joinByComma(this.currentTarget.names)
        : '';
    const telnos =
      this.currentTarget && this.currentTarget.telnos
        ? this.joinByComma(this.currentTarget.telnos)
        : '';
    const ifas =
      this.currentTarget && this.currentTarget.ifas
        ? this.joinByComma(this.currentTarget.ifas)
        : '';

    const imsis =
      this.currentTarget && this.currentTarget.imsis
        ? this.joinByComma(this.currentTarget.imsis)
        : '';
    const imeis =
      this.currentTarget &&
      this.currentTarget.imeis &&
      this.currentTarget.imeis.length > 0
        ? this.joinByComma(this.currentTarget.imeis)
        : '';
    const user =
      this.currentTarget &&
      this.currentTarget.assignedUsers &&
      this.currentTarget.assignedUsers.length > 0
        ? this.currentTarget.assignedUsers[0]
        : '';
    const photo =
      this.currentTarget &&
      this.currentTarget.photos &&
      this.currentTarget.photos.length > 0
        ? this.currentTarget.photos[0]
        : this.noPhotoUrl;
    const countries =
      this.currentTarget && this.currentTarget.countries
        ? this.joinByComma(this.currentTarget.countries)
        : [];
    this.countryCode =
      (this.currentTarget && this.currentTarget.countryCode) ||
      this.countryCodeFromCountryList() ||
      '';
    const timeZone = this.setTargetTimezone();
    const socialProfiles =
      (this.currentTarget && this.currentTarget.socialProfiles) || [];
    const usernames =
      (this.currentTarget && this.currentTarget.usernames) || [];
    const userIds = (this.currentTarget && this.currentTarget.userIds) || [];
    const nationalId =
      (this.currentTarget && this.currentTarget.nationalId) || '';
    const jobs = (this.currentTarget && this.currentTarget.jobs) || [];
    const emails = (this.currentTarget && this.currentTarget.emails) || [];
    const addresses =
      (this.currentTarget && this.currentTarget.addresses) || [];
    const gender = (this.currentTarget && this.currentTarget.gender) || '';
    const voterId = (this.currentTarget && this.currentTarget.voterId) || '';
    const photos = (this.currentTarget && this.currentTarget.photos) || [];
    const landTelnos =
      (this.currentTarget && this.currentTarget.landTelnos) || [];

    this.createTargetForm = new UntypedFormGroup({
      names: new UntypedFormControl(names),
      telnos: new UntypedFormControl(telnos),
      ifas: new UntypedFormControl(ifas),
      nationalId: new UntypedFormControl(nationalId),
      alias: new UntypedFormControl(alias, [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$/),
      ]),
      imsis: new UntypedFormControl(imsis),
      // 'tmsis': new FormControl(tmsis),
      imeis: new UntypedFormControl(imeis),
      user: new UntypedFormControl(user),
      avatar: new UntypedFormControl(photo !== this.noPhotoUrl ? photo : ''),
      countries: new UntypedFormControl(countries),
      timeZone: new UntypedFormControl(timeZone),
      countryCode: new UntypedFormControl(this.countryCode),
      socialProfiles: new UntypedFormControl(socialProfiles),
      usernames: new UntypedFormControl(usernames),
      userIds: new UntypedFormControl(userIds),
      jobs: new UntypedFormControl(jobs),
      emails: new UntypedFormControl(emails),
      addresses: new UntypedFormControl(addresses),
      gender: new UntypedFormControl(gender),
      voterId: new UntypedFormControl(voterId),
      photos: new UntypedFormControl(photos),
      landTelnos: new UntypedFormControl(landTelnos),
    });

    if (this.editMode) {
      this.createTargetForm.get('alias').disable();
    }
    this.avatarUrl =
      photo === this.noPhotoUrl
        ? photo
        : this.imageService.getPhotoUrl(photo, true);

    if (this.isAdmin || this.isSupport || this.isPower) {
      this.createTargetForm.controls['user'].setValidators([
        Validators.required,
      ]);
    }
  }

  private joinByComma(value: any) {
    return value.join(', ');
  }

  async onSubmit() {
    this.errorMsg = false;
    this.errorMsgForImsi = false;
    this.errorMsgForImei = false;
    this.errorMsgForIfas = false;

    if (!this.isAdmin && !this.isSupport && !this.isPower) {
      this.createTargetForm.controls['user'].setValue(
        this.localStorageService.getCurrentUser().identity
      );
    }

    if (this.createTargetForm.valid) {
      this.errorMsg = this.hasErrorMsisdn(this.phoneNumberUtil);
      this.errorMsgForImsi = this.hasErrorImsi();
      this.errorMsgForImei = this.hasErrorImei();
      this.errorMsgForIfas = this.hasErrorIfas();

      if (
        this.editMode &&
        this.currentTarget &&
        this.currentTarget.alias &&
        this.currentTarget.alias.length > 0
      ) {
        if (
          !(
            this.errorMsg ||
            this.errorMsgForImsi ||
            this.errorMsgForImei ||
            this.errorMsgForIfas
          )
        ) {
          const editTargetSubscription = this.targetService
            .editTargetProfiler(this.currentTarget, this.createTargetForm.value)
            .subscribe(
              () => {
                this.showMessage(
                  this.translationService.translate(
                    'Target edited successfully!'
                  )
                );
                this.onClose({ saved: true, target: undefined });
              },
              (error: any) => {
                this.showMessage(
                  this.translationService.translate(
                    error.messages
                      ? error.messages
                      : 'Target has not been edited'
                  )
                );
                editTargetSubscription.unsubscribe();
              },
              () => {
                this.targetRecycleBinService
                  .saveToTargetRecycleBin(this.currentTarget.id, {
                    values: Object.entries(this.deletedValues),
                    recycleData: this.targetRecycleBin,
                    reEnabledSeeds: Object.entries(this.reEnabledSeeds),
                  })
                  .subscribe(() => {
                    if (this.router.url.includes('overview')) {
                      this.profilerService.refreshTargetOnEditingForm.next(
                        true
                      );
                    }
                  });
                editTargetSubscription.unsubscribe();
              }
            );
        }
      } else {
        if (
          !(
            this.errorMsg ||
            this.errorMsgForImsi ||
            this.errorMsgForImei ||
            this.errorMsgForTmsi
          )
        ) {
          const rawValue: TargetItem = this.createTargetForm.getRawValue();
          if (rawValue.socialProfiles.length) {
            for (const elem of rawValue.socialProfiles) {
              await this.getUserId(rawValue, elem).then(
                (id: string) => (elem.userId = id)
              );
            }
          }
          this.subscriptions.push(
            this.targetService
              .createTargetProfiler(rawValue, {
                createCase: this.createCase,
                addImProfiles: false,
              })
              .subscribe(
                (data: TargetItem) => {
                  if (data) {
                    this.profilerService.targetData.next(null);
                    if (this.targetData && this.targetData.case) {
                      this.dialogRef.close({ saved: true, target: data });
                    } else {
                      this.showMessage(
                        this.translationService.translate(
                          'Target created successfully!'
                        )
                      );
                      this.profilerService.restoreDashboardSections();
                      if (
                        this.instantMessagingProfileIds &&
                        'profileIds' in this.instantMessagingProfileIds &&
                        this.instantMessagingProfileIds.profileIds.length
                      ) {
                        this.subscriptions.push(
                          this.searchIntelService
                            .moveInstantMessagingProfiles(
                              this.instantMessagingProfileIds,
                              [data.id]
                            )
                            .pipe(
                              catchError((err) =>
                                of(
                                  `move IM profiles failed ${JSON.stringify(
                                    err
                                  )}`
                                )
                              )
                            )
                            .subscribe((res) => {
                              this.finishSaveProcess(data);
                            })
                        );
                      } else {
                        this.finishSaveProcess(data);
                      }
                    }
                  }
                },
                (error: any) => {
                  const errorMessage = error?.messages;

                  if (
                    this.redirectSnackBarService.shouldShowRedirectSnackBar(
                      errorMessage
                    )
                  ) {
                    this.redirectSnackBarService.showRedirectSnackBar(
                      errorMessage
                    );
                  } else {
                    this.showMessage(
                      this.translationService.translate(
                        error.messages
                          ? error.messages
                          : 'Target has not been created'
                      )
                    );
                  }
                }
              )
          );
        }
      }
    }
  }

  private finishSaveProcess(data: TargetItem): void {
    if (!this.router.url.includes('link-analysis')) {
      const url = ['targets', data.id, 'overview'];
      this.redirectTo(url);
    }
    this.onClose({ saved: true, target: data });
  }

  // work around for changing route when we are on same page
  private redirectTo(url: string[]) {
    this.router
      .navigateByUrl('/targets', { skipLocationChange: true })
      .then(() => this.router.navigate(url));
  }

  private getUserId(obj: TargetItem, elem): Promise<string> {
    return new Promise((resolve, reject) => {
      if (elem.userId) {
        resolve(
          elem.userId.includes(elem.platform)
            ? elem.userId
            : `${elem.userId}@${elem.platform}`
        );
      }

      if (elem.platform === Platforms.FACEBOOK && !elem.userId) {
        this.osintService.queryFacebookPlatform(elem.link).subscribe((id) => {
          resolve(id);
        });
      }

      if (elem.platform !== Platforms.FACEBOOK && !elem.userId) {
        /* eslint-disable-next-line no-useless-escape */
        const userId = elem.link.match(/\/([^\/?#]+)[^\/]*$/)
          ? /* eslint-disable-next-line no-useless-escape */
            elem.link.match(/\/([^\/?#]+)[^\/]*$/)[1]
          : head(obj.usernames);
        resolve(`${userId}@${elem.platform}`);
      }
    });
  }

  private hasErrorImsi(): boolean {
    const imsis = this.targetService.splitByComma(
      this.createTargetForm.getRawValue().imsis
    );
    return imsis.some(
      (imsi) => !isValidIMSI(imsi) || !this.isValueUnique('imsis', imsi)
    );
  }

  private hasErrorIfas(): boolean {
    let hasError = false;
    const ifas = this.targetService.splitByComma(
      this.createTargetForm.getRawValue().ifas
    );
    ifas.forEach((ifa) => {
      if (!this.queryService.isValidIfa(ifa)) {
        hasError = true;
      }
    });
    return hasError;
  }
  // Hidden for now
  // private hasErrorTmsi(): boolean {
  //   let hasError = false;
  //   const tmsis = this.targetService.splitByComma(this.createTargetForm.getRawValue().tmsis);
  //   tmsis.forEach(tmsi => {
  //     if (!this.queryService.isValidImsi(tmsi)) {
  //       hasError = true;
  //     }
  //   });
  //   return hasError;
  // }

  private hasErrorImei(): boolean {
    const imeis = this.targetService.splitByComma(
      this.createTargetForm.getRawValue().imeis
    );
    return imeis.some(
      (imei) =>
        !isValidImeiIgnoreChecksum(imei) || this.isValueUnique('imeis', imei)
    );
  }

  private hasErrorMsisdn(phoneNumberUtil): boolean {
    let hasError = false;
    const phones = this.targetService.splitByComma(
      this.createTargetForm.getRawValue().telnos
    );
    phones.forEach((phone) => {
      try {
        if (
          !this.targetService.getValidPhone(phoneNumberUtil, phone) ||
          !this.isValueUnique('telnos', phone)
        ) {
          hasError = true;
        }
      } catch (e) {
        hasError = true;
      }
    });
    return hasError;
  }

  onClose(
    data: { saved: boolean; target: TargetItem } = {
      saved: false,
      target: undefined,
    }
  ): void {
    this.dialogRef.close(data);
  }

  onAvatarUpload(event: any): void {
    this.avatar = event.target.files[0];
    const validImageTypes = [
      'image/gif',
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/svg',
    ];
    if (
      this.avatar.size < 2097152 &&
      validImageTypes.includes(this.avatar.type)
    ) {
      // roughly 2MB
      this.errorMsgForImage = false;

      const avatarSubscription = this.imageService
        .uploadImage(this.avatar)
        .subscribe(
          (result) => {
            this.avatarUrl = this.imageService.getPhotoUrl(`/file/${result}`);
            this.createTargetForm.controls['avatar'].setValue(
              `/file/${result}`
            );
          },
          (error: string) =>
            this.showMessage(
              this.translationService.translate(
                'Something went wrong with the photo. Please try again.'
              )
            )
        );
      this.subscriptions.push(avatarSubscription);
    } else {
      this.errorMsgForImage = true;
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.countries.filter((country) =>
      country.countryName.toLowerCase().includes(filterValue)
    );
  }

  setTargetTimezone() {
    if (this.editMode && this.currentTarget && this.currentTarget.timeZone) {
      return this.currentTarget.timeZone;
    } else {
      // TODO: if target has telno set timezone based on target's first telno country code
      // if target doesn't have telno--> show browser timezone
      return undefined;
    }
  }

  wasDeleted(value: string, key: string): boolean {
    let wasDeleted = false;
    if (Object.keys(this.targetRecycleBin).length) {
      if (this.targetRecycleBin[key]) {
        for (const deletedValue of this.targetRecycleBin[key]) {
          if (deletedValue.toLocaleLowerCase() === value.toLocaleLowerCase()) {
            wasDeleted = true;
            break;
          }
        }
      }
    }
    return wasDeleted;
  }

  getRecycleBin() {
    if (this.editMode) {
      this.targetRecycleBinService
        .getTargetRecycleBin(this.currentTarget.id)
        .subscribe((data: { result: { [key: string]: any[] } }) => {
          this.targetRecycleBin = { ...data.result };
        });
    }
  }

  removeFromLocalRecycleBin(value: string, key: string): void {
    if (this.targetRecycleBin[key]) {
      const index: number = this.targetRecycleBin[key].findIndex(
        (e) => e.toLocaleLowerCase() === value.toLocaleLowerCase()
      );
      if (index >= 0) {
        this.targetRecycleBin[key].splice(index, 1);
        this.reEnableSeeds(key, value);
      }
    }

    if (this.deletedValues[value.toLocaleLowerCase()]) {
      delete this.deletedValues[value.toLocaleLowerCase()];
    }
  }

  private reEnableSeeds(key: string, value: string): void {
    if (this.reEnabledSeeds[key]) {
      this.reEnabledSeeds[key].push(value);
    } else {
      this.reEnabledSeeds[key] = [value];
    }
  }

  private deleteReEnabledSeeds(key: string, value: string): void {
    if (this.reEnabledSeeds[key]) {
      const index: number = this.reEnabledSeeds[key].findIndex(
        (e) => e.toLocaleLowerCase() === value.toLocaleLowerCase()
      );
      if (index >= 0) {
        this.reEnabledSeeds[key].splice(index, 1);
      }
    }
  }
}
