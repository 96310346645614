import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from '@app/config';
import { LayoutService } from '@fe-platform/shared-ui/intellectus';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  map,
  Subject,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { AuthState } from '../authentication/auth-state.enum';
import { AuthService } from '../authentication/auth.service';
import { LocalStorageService } from '../storage/local-storage.service';
import { UserSettingsService } from '../user/user-settings.service';

@Injectable({
  providedIn: 'root',
})
export class DiscoveryVersionService {
  private readonly discoveryNavigationLinks = {
    oldDiscovery: '/discovery',
    newDiscovery: '/account/discovery/0',
  };
  private discoveryV2EnabledSrc: BehaviorSubject<boolean> = new BehaviorSubject(
    null
  );
  public discoveryV2Enabled$ = this.discoveryV2EnabledSrc.asObservable();
  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private readonly layoutService: LayoutService,
    private readonly userSettingsService: UserSettingsService,
    private readonly localStorageService: LocalStorageService,
    private appConfigService: AppConfigService,
    private authService: AuthService
  ) {
    this.authService.authState$
      .pipe(
        filter((val) => val != AuthState.unknown),
        tap((val) => {
          if (val === AuthState.authenticated) {
            this.init();
          } else if (val === AuthState.unauthenticated) {
            this.destroy();
          }
        })
      )
      .subscribe();
  }

  init(): void {
    combineLatest([
      this.layoutService.isMobile$,
      this.userSettingsService.getUserSettings().pipe(take(1)),
      this.appConfigService.authenticatedConfigLoaded$,
    ])
      .pipe(
        map(([isMobile, userSettings, configsLoaded]) => {
          if (configsLoaded) {
            const oldDiscoveryEnabled = this.appConfigService.getConfigVariable(
              'oldDiscoveryEnabled'
            );
            if (!oldDiscoveryEnabled) {
              userSettings.discoveryV2Enabled = true;
              this.userSettingsService.setUserSettingsValue({
                discoveryV2Enabled: true,
              });
              this.discoveryV2EnabledSrc.next(null);
            }
          }
          if (this.discoveryV2EnabledSrc.getValue() == null) {
            if (isMobile) {
              this.discoveryV2EnabledSrc.next(true as boolean);
              this.toggleDiscoveryModule(false, false);
            } else {
              const discoveryV2Enabled =
                userSettings.discoveryV2Enabled === undefined
                  ? true
                  : !!userSettings.discoveryV2Enabled;
              this.discoveryV2EnabledSrc.next(discoveryV2Enabled as boolean);
              this.toggleDiscoveryModule(!discoveryV2Enabled as boolean, false);
            }
            return;
          }
          const enableDiscoveryV2 = this.appConfigService.getConfigVariable(
            'enableDiscoveryV2'
          )
            ? typeof userSettings.discoveryV2Enabled === 'undefined'
              ? true
              : userSettings.discoveryV2Enabled
            : !!userSettings.discoveryV2Enabled;

          this.discoveryV2EnabledSrc.next(enableDiscoveryV2 as boolean);
          if (typeof userSettings.discoveryV2Enabled === 'undefined') {
            this.toggleDiscoveryModule(!enableDiscoveryV2 as boolean);
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  destroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.discoveryV2EnabledSrc.next(null);
  }

  toggleDiscoveryModule(
    discoveryV2Enabled: boolean,
    redirectToDiscovery = true
  ): void {
    this.userSettingsService
      .setUserSettingsValue({ discoveryV2Enabled: !discoveryV2Enabled })
      .pipe(
        map((userSettings) => userSettings.discoveryV2Enabled),
        tap((discoveryV2Enabled) => {
          this.localStorageService.setShowDiscoveryOnboarding(
            discoveryV2Enabled
          );
          const toggleActiveNavigationLink = discoveryV2Enabled
            ? this.discoveryNavigationLinks.newDiscovery
            : this.discoveryNavigationLinks.oldDiscovery;
          this.discoveryV2EnabledSrc.next(discoveryV2Enabled as boolean);
          if (
            [
              this.discoveryNavigationLinks.oldDiscovery,
              this.discoveryNavigationLinks.newDiscovery,
            ].some((url) => this.router.url.includes(url))
          ) {
            this.userSettingsService.discoveryV2Enabled.next(
              discoveryV2Enabled
            );
            if (!redirectToDiscovery) return;
            this.router.navigateByUrl(toggleActiveNavigationLink, {
              state: {
                showOnboarding:
                  toggleActiveNavigationLink ===
                  this.discoveryNavigationLinks.newDiscovery,
              },
            });
          }
        }),
        take(1)
      )
      .subscribe();
  }
}
