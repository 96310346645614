import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
} from 'rxjs/operators';
import { TableActionsService } from 'src/app/modules/analysis/shared/services/table-actions.service';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { BillingService } from 'src/app/services/billing/billing.service';
import { UserBillingService } from 'src/app/services/billing/user-billing.service';
import { CaseService } from 'src/app/services/case/case.service';
import { RedirectSnackBarService } from 'src/app/services/snack-bar.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { TargetRenewalService } from 'src/app/services/target/target-renewal.service';
import { TargetService } from 'src/app/services/target/target.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { BaseComponent } from 'src/app/shared/classes/base.component';
import {
  BillingActionType,
  BillingActions,
  BillingPlan,
} from 'src/app/shared/models/billing-action.model';
import { Case } from 'src/app/shared/models/case.model';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import {
  matomoActions,
  matomoCategories,
} from 'src/app/shared/values/matomo-config';

@Component({
  selector: 'app-create-case-dialog',
  templateUrl: './create-case-dialog.component.html',
  styleUrls: ['./create-case-dialog.component.scss'],
})
export class CreateCaseDialogComponent extends BaseComponent implements OnInit {
  @ViewChild('targetInput') targetInput: ElementRef<HTMLInputElement>;

  caseCreditsChargesEnabled = false;
  expireCaseDays: number;
  caseCreditsMessage: string;
  titleError: string;
  descriptionError: string;
  urlImage: string = undefined;
  showAddTarget: boolean;
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  allTargets: TargetItem[];
  selectedTargets: TargetItem[] = [];
  filteredTargets: Observable<TargetItem[]>;
  targetQueue: TargetItem[] = [];
  creditsForExpired = 0;
  billingPlan: BillingPlan<BillingActions, BillingActionType>;
  targetCreditsChargesEnabled = false;
  expireTargetDays: number;
  targetCreditsMessage: string;
  hasCasesSlotsAvailable = true;
  hasConcurrentLimits = false;

  constructor(
    public dialogRef: MatDialogRef<CreateCaseDialogComponent>,
    private caseService: CaseService,
    private localStorageService: LocalStorageService,
    private translationService: TranslationService,
    private appConfigService: AppConfigService,
    private targetService: TargetService,
    private billingService: BillingService,
    private tableActionsService: TableActionsService,
    private targetRenewalService: TargetRenewalService,
    private userBillingService: UserBillingService,
    private router: Router,
    private redirectSnackBarService: RedirectSnackBarService
  ) {
    super();
    this.caseCreditsChargesEnabled = this.appConfigService.getConfigVariable(
      'enableCreditChargesForCase'
    );
    this.expireCaseDays =
      this.appConfigService.getConfigVariable('expireCaseDays');
    this.targetCreditsChargesEnabled = this.appConfigService.getConfigVariable(
      'enableCreditChargesForTarget'
    );
    this.expireTargetDays =
      this.appConfigService.getConfigVariable('expireTargetDays');
    this.hasConcurrentLimits = this.appConfigService.getConfigVariable(
      'hasConcurrentLimits'
    );
    this.hasCasesSlotsAvailable =
      !this.hasConcurrentLimits ||
      (this.hasConcurrentLimits &&
        this.userBillingService.hasAvailableConcurrentSlots('Case'));
  }

  createCaseForm: UntypedFormGroup = new UntypedFormGroup({
    title: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(255),
      Validators.pattern(/^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/),
    ]),
    description: new UntypedFormControl('', [Validators.maxLength(1024)]),
    targetCtrl: new UntypedFormControl(''),
  });
  matomo = {
    actions: matomoActions,
    categories: matomoCategories,
  };

  ngOnInit() {
    this.billingPlan = this.billingService.getBillingPlan().getValue();

    this.caseCreditsMessage = this.translationService.interpolate(
      'Management for a new case is free of charge for #{days} days',
      { days: this.expireCaseDays.toString() }
    );
    this.targetCreditsMessage = this.translationService.interpolate(
      'Management for a new target is free of charge for #{days} days',
      { days: this.expireTargetDays.toString() }
    );

    this.setupCaseTitleValidationListener();
    this.setupCaseDescriptionValidationListener();

    this.fetchAllTargets();
  }

  ngAfterViewInit() {
    this.filteredTargets = this.createCaseForm.controls[
      'targetCtrl'
    ].valueChanges.pipe(
      startWith(''),
      debounceTime(500),
      distinctUntilChanged(),
      map((target: string | null) =>
        target ? this.filterTargetAlias(target) : this.allTargets.slice()
      )
    );
  }

  nextStep() {
    this.showAddTarget = true;
  }

  private filterTargetAlias(value: string): TargetItem[] {
    const filterValue = value.toLowerCase();
    return this.allTargets.filter(
      (target) => target.alias.toLowerCase().indexOf(filterValue) === 0
    );
  }

  private fetchAllTargets() {
    this.targetService.fetchAllTargets();
    this.subscriptions.push(
      this.targetService
        .getAllTargets()
        .pipe(
          map((targets: TargetItem[]) =>
            targets.filter((target) =>
              target.assignedUsers.includes(
                this.localStorageService.getCurrentUser().identity
              )
            )
          )
        )
        .subscribe({
          next: (targets: TargetItem[]) => {
            this.allTargets = targets;
            this.refreshTargetInput();
          },
        })
    );
  }

  selectedTargetChips(event: MatAutocompleteSelectedEvent): void {
    const target: TargetItem = <TargetItem>event.option.value;

    this.selectedTargets.push(target);
    this.allTargets = this.allTargets.filter(
      (targetItem) => targetItem !== target
    );

    this.targetInput.nativeElement.value = '';
    this.createCaseForm.controls['targetCtrl'].setValue(null);
    this.addToTargetQueue(target);
    this.checkRenewalCreditsCount();
    this.refreshTargetInput();
  }

  removeTargetChips(target: TargetItem): void {
    const index = this.selectedTargets.indexOf(target);
    if (index >= 0) {
      this.selectedTargets.splice(index, 1);
      this.allTargets.unshift(target);
    }
    this.targetQueue = this.targetQueue.filter(
      (targetItem) => targetItem.alias !== target.alias
    );
    this.checkRenewalCreditsCount();
    this.refreshTargetInput();
  }

  private refreshTargetInput() {
    this.createCaseForm.controls['targetCtrl'].disable();
    this.createCaseForm.controls['targetCtrl'].enable();
  }

  checkRenewalCreditsCount() {
    const expiredTargetsCount = this.targetQueue.filter(
      (i) => i.expired
    ).length;
    this.creditsForExpired =
      expiredTargetsCount *
      this.billingPlan[BillingActions.TARGET_MANAGEMENT].cost;
  }

  private addToTargetQueue(target: TargetItem) {
    const allTargetsList = [...this.allTargets, ...this.selectedTargets];
    allTargetsList.forEach((targetItem) => {
      if (targetItem.alias === target.alias) this.targetQueue.push(targetItem);
    });
  }

  private setupCaseTitleValidationListener() {
    this.subscriptions.push(
      this.createCaseForm.controls['title'].valueChanges
        .pipe(debounceTime(400), distinctUntilChanged())
        .subscribe(() => {
          this.caseTitleValidation();
        })
    );
  }

  private setupCaseDescriptionValidationListener() {
    this.subscriptions.push(
      this.createCaseForm.controls['description'].valueChanges
        .pipe(debounceTime(400), distinctUntilChanged())
        .subscribe(() => {
          this.caseDescriptionValidation();
        })
    );
  }

  private caseTitleValidation() {
    if (this.createCaseForm.controls['title'].hasError('required')) {
      this.titleError = this.translationService.translate('Title is required');
    } else if (this.createCaseForm.controls['title'].hasError('maxlength')) {
      this.titleError = this.translationService.translate(
        'You have more than 255 characters'
      );
    } else if (this.createCaseForm.controls['title'].hasError('pattern')) {
      this.titleError = this.translationService.translate(
        'Must be at least 1 character long and maximum characters allowed are 255, special charactes and preceding or following spaces are not allowed'
      );
    } else {
      this.titleError = '';
    }
  }

  private caseDescriptionValidation() {
    if (this.createCaseForm.controls['description'].hasError('maxlength')) {
      this.descriptionError = this.translationService.translate(
        'You have more than 1024 characters'
      );
    } else {
      this.descriptionError = '';
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  newCase(value) {
    if (this.createCaseForm.valid) {
      const targetIds: any[] = this.targetQueue.map(
        (target: TargetItem) => target.id
      );
      const expiredTargets = this.targetQueue
        .filter((i: TargetItem) => this.targetRenewalService.isTargetExpired(i))
        .map((i) => i.id);

      if (expiredTargets.length) {
        this.subscriptions.push(
          this.tableActionsService
            .renewMultipleTargets(expiredTargets)
            .subscribe((renewed) => {
              if (!renewed) {
                return;
              }
              this.createCase(value, targetIds);
            })
        );
      } else {
        this.createCase(value, targetIds);
      }
    }
  }

  createCase(
    caseValue: { title: string; description: string },
    assignedTargets: TargetItem[]
  ) {
    const obj: Case = {
      caseName: caseValue.title,
      caseColor: '#005CFF',
      caseDescription: caseValue.description,
      assignedTargets,
      assignedUsers: [this.localStorageService.getCurrentUser().identity],
    };

    this.caseService.createCase(obj).subscribe(
      (result) => {
        if (result) {
          this.showMessage(
            this.translationService.translate('Case created successfully!')
          );
          this.dialogRef.close(true);
        }
      },
      (error: any) => {
        const errorMessage = error?.messages;

        if (
          this.redirectSnackBarService.shouldShowRedirectSnackBar(errorMessage)
        ) {
          this.redirectSnackBarService.showRedirectSnackBar(errorMessage);
        } else {
          this.showMessage(
            this.translationService.translate(
              error.messages ? error.messages : 'Case has not been created'
            )
          );
        }
      }
    );
  }

  navigateToCases(): void {
    this.dialogRef.close();
    this.router.navigate(['core', 'cases']);
  }

  toggleShowAddtarget() {
    this.showAddTarget = !this.showAddTarget;
  }
}
