import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AppConfigService } from '@app/config';
import { Observable, combineLatest, filter, map, skipWhile, take } from 'rxjs';
import { UserSettingsService } from '../user/user-settings.service';

@Injectable({
  providedIn: 'root',
})
export class DiscoveryV2Guard implements CanActivate {
  constructor(
    private userSettingsService: UserSettingsService,
    private router: Router,
    private appConfigService: AppConfigService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    this.appConfigService.fetchAuthenticatedConfig();
    return combineLatest([
      this.userSettingsService.getUserSettings().pipe(filter(Boolean)),
      this.appConfigService.authenticatedConfigLoaded$.pipe(
        skipWhile((v) => !v)
      ),
    ]).pipe(
      take(1),
      map(([userSettings]) => {
        const discoveryV2Enabled =
          userSettings.discoveryV2Enabled === undefined
            ? true
            : userSettings.discoveryV2Enabled &&
              this.appConfigService.getConfigVariable('enableDiscoveryV2');

        if (state.url === '/discovery' && discoveryV2Enabled) {
          if (!this.router.url.includes('/account/discovery')) {
            this.router.navigateByUrl(
              '/account/discovery',
              this.router.getCurrentNavigation().extras
            );
          } else {
            this.router.navigateByUrl(
              '/account/discovery/0',
              this.router.getCurrentNavigation().extras
            );
          }
          return false;
        } else if (
          state.url.includes('/account/discovery') &&
          !discoveryV2Enabled
        ) {
          this.router.navigate(['discovery']);
          return false;
        }
        return true;
      })
    );
  }
}
