import { TranslationDictionary } from '../translationDictionary.interface';

export const es: TranslationDictionary = {
  ' Create a new one ': ' Cree uno nuevo ',
  ' more': ' mostrar más',
  '#{clParameterType} entered is not valid. Please try another one':
    'El #{clParameterType} introducido no es válido. Por favor, pruebe con otro',
  "#{msisdn} has been used on target's phone #{imei}":
    '#{msisdn} se ha utilizado en el teléfono objetivo #{imei}',
  '#{socialType} registration started': 'Registro de  #{socialType} iniciado',
  '#{socialType} registration succeed. Trying to get email otp code':
    'Registro de #{socialType} satisfactorio. Intentando obtener el email con código otp',
  '1 day': '1 día',
  '1 hour': '1 hora',
  '1 minute': '1 minutos',
  '1 week': '1 semana',
  '15 minutes': '15 minutos',
  '2 days': '2 días',
  '2-Step Verification': 'Verificación en dos pasos',
  '2-Step Verification is OFF': 'La verificación de dos pasos está DESACTIVADA',
  '3 days': '3 días',
  '30 minutes': '30 minutos',
  "A second step after entering your password verifies it's you signing in.":
    'Un segundo paso después de ingresar su contraseña verifica que es usted quien inicia sesión.',
  ACTIVE: 'ACTIVO',
  'AD ID': 'AD ID',
  'ADD ID': '',
  'ADDITIONAL LAYERS': 'CAPAS ADICIONALES',
  'ALL QUERIES FOUND': 'TODAS LAS CONSULTAS ENCONTRADAS',
  'ALL UNREACHABLE QUERIES': 'TODAS LAS CONSULTAS NO ENCONTRADAS',
  APPs: 'Apps',
  'AT&T post-paid only': 'AT&T post pago únicamente',
  Abandon: 'Abandonar',
  About: 'Acerca de',
  'Accept friends': 'Aceptar amigos',
  'Accepting requests': 'Aceptando solicitudes',
  'Access to the target will be free for #{days} days':
    'El acceso al objetivo será gratuito por #{days} días',
  Account: 'Cuenta',
  'Account info': 'Información de cuenta',
  Accounts: 'Cuentas',
  'Act number': 'Número de Acta',
  Action: 'acción',
  'Action has been successfully created':
    'Esta acción ha sido creada con éxito',
  'Action is started': 'Acción iniciada',
  'Action is stopped': 'Acción detenida',
  'Action started by': 'Acción iniciada por',
  Actions: 'Acciones',
  Activate: 'Activar',
  'Activate 2-step verification': 'Activar la verificación en dos pasos',
  'Activate Now!': '¡Activar ahora!',
  'Activate OTP': 'Activar OTP',
  Activated: 'Activado',
  Active: 'Activo',
  'Active Locate': 'Localizar Activo',
  'Active in': 'Activo en',
  'Active locate': 'Localizar activo',
  'Active locate query submitted successfully!':
    '¡Consulta de localización activa enviada con éxito!',
  'Active proximity items': 'Elementos de proximidad activos',
  Activities: 'Actividades',
  Activity: 'Actividad',
  'Activity Log': 'Registro de actividades',
  'Activity Patterns': 'Patrones de actividad',
  'Activity log': 'Historial de actividad',
  'Activity patterns': 'Patrones de actividad',
  'Activity timeline': 'Historial de actividad',
  'Ad IDs': 'Ad IDs',
  Add: 'Agregar',
  Adding: 'Agregando',
  'Add Group': 'Añadir Grupo',
  'Add IDs': '',
  'Add MSISDN': 'Agregar MSISDN',
  'Add Profile to My Target': 'Agregar perfil a Mi Objetivo',
  'Add URL': 'Agregar URL',
  'Add a Facebook account and click "Enchance target" to retrieve more information':
    'Agregue una cuenta de Facebook o haga clic en "Mejorar Perfil" para obtener más información',
  'Add a new note': 'Agregar una nueva nota',
  'Add a note': 'Agregar una nota',
  'Add a valid MSISDN': 'Agregar un MSISDN válido',
  'Add an existing target': 'Agregar un objetivo existente',
  'Add and analyse': 'Agregar y analizar',
  'Add at least 1 MSISDN or IMEI': 'Agregue al menos 1 MSISDN o IMEI',
  'Add at least 1 MSISDN or IMEI or IMSI':
    'Agregue al menos 1 MSISDN o IMEI o IMSI',
  'Add case': 'Agregar caso',
  'Add court number': '',
  'Add email': 'Agregar email',
  'Add entity': 'Agregar entidad',
  'Add group name': 'Agregar nombre de grupo',
  'Add hashtag for this query': 'Añadir hashtag para esta consulta',
  'Add media content': 'Agregar contenido multimedia',
  'Add name and image url': 'Agregar nombre y URL de imagen',
  'Add new': 'Agregar nuevo',
  'Add new Target': 'Agregar nuevo Objetivo',
  'Add new item': 'Agregar ítem nuevo',
  'Add new keywords list': 'Agregar nueva lista de palabras clave',
  'Add new place': 'Añadir Nuevo Lugar',
  'Add new platform': 'Agregar nueva plataforma',
  'Add new value': 'Agregar nuevo valor',
  'Add note': 'Agregar nota',
  'Add phone number': 'Agregar número telefónico',
  'Add profile to target': 'Agregar perfil a objetivo',
  'Add seeds and click "Enchance target" to get "Most common faces"':
    'Agregar semillas y haga clic en "Mejorar perfil" para obtener los "Rostros más comunes"',
  'Add seeds and click "Enchance target" to retrieve employment and education':
    'Agregar semillas y haga clic en "Mejorar perfil" para obtener información sobre empleador y educación',
  'Add seeds and click "Enchance target" to retrieve instant messaging apps':
    'Agregar semillas y haga clic en "Mejorar perfil" para obtener apps de mensajería instantánea',
  'Add seeds and click "Enchance target" to retrieve media':
    'Agregar semillas o haga clic en "Mejorar perfil" para obtener imágenes',
  'Add seeds and click "Enchance target" to retrieve relationship information':
    'Agregar semillas y haga clic en "Mejorar perfil" para obtener información sobre relaciones',
  'Add seeds and click "Enchance target" to retrieve social profiles':
    'Agregar semillas y haga clic en "Mejorar perfil" para obtener perfiles sociales',
  'Add seeds or Perform an OSINT query to retrieve more information':
    'Agregue semillas o realice una consulta OSINT para obtener más información',
  'Add selected targets': 'Agregar objetivos seleccionados',
  'Add social network': 'Agregar red social',
  'Add social profiles to the targets to view data':
    'Agregue perfiles sociales a los objetivos para ver datos',
  'Add target': 'Agregar objetivo',
  'Add targets': 'Agregar objetivos',
  "Add targets by searching for target's Alias.":
    'Agregue objetivos buscando el alias del objetivo.',
  'Add targets by searching for target’s Alias.': '',
  'Add to "My target"': 'Agregar a "Mi objetivo"',
  'Add to an existing target': 'Añadir a un objetivo existente',
  'Add to case': 'Añadir al caso',
  'Add to target': 'Agregar al objetivo',
  'Add to Mission': 'Agregar a misión',
  'Add user': 'Agregar usuario',
  'Add from recent searches': 'Agregado de búsquedas recientes',
  Added: 'Agregada',
  'Added Details': 'Detalles agregados',
  'Added Note': 'Notas agregadas',
  'Added Photo': 'Foto agregada',
  'Added Seed': 'Semilla agregada',
  'Added on': 'Añadido el',
  Address: 'Dirección',
  'Address(es)': 'Dirección',
  Addresses: 'Direcciones',
  'Additional parameters': 'Parámetros adicionales',
  Adint: 'Adint',
  'Adint history': 'Historia de un poco',
  'AdId copied': 'AdId copiado',
  'AdId session': 'Sesión de AdId',
  Admin: 'Admin',
  Advanced: 'Avanzado',
  'Advanced Geo Query': 'Consulta geográfica avanzada',
  'Advanced Information Request': 'Solicitar Informacion Avanzada',
  'Advanced Options': 'Opciones Avanzadas',
  'Advanced Webint': 'WebInt avanzado',
  'Advanced Webint query created successfully!':
    '¡Consulta de Webint avanzada creada con éxito!',
  'Advanced geolocation': 'Geolocalización avanzada',
  'Advanced osint': 'OSINT Avanzado',
  'Advanced search will add media, relations, insights and more from social profiles':
    'La búsqueda avanzada agregará imagenes y videos, relaciones, estadísticas y más de redes sociales.',
  'Advanced search will gather more intel from MSISDN, email & social profiles and analyse their content such as media, relations, posts and more':
    'La búsqueda avanzada obtendrá más información de inteligencia sobre el MSISDN, correo electrónico y perfiles sociales y analizará su contenido como imágenes, relaciones publicaciones y más',
  'Advanced webint is already in progress.':
    'Webint Avanzado ya está en progreso',
  'Affiliated person': 'Persona Afiliada',
  Afghanistan: 'Afganistán',
  Age: 'Edad',
  'Age range': 'rango de edad',
  'Age of Location': 'Edad de Ubicación',
  Albania: 'Albania',
  'Album name': 'Nombre del álbum',
  'Album url': 'URL del álbum',
  Alerts: 'Alertas',
  Algeria: 'Argelia',
  Alias: 'Alias',
  'Alias exists. Please use another alias':
    'Este Alias ya existe. Por favor, utilice otro alias',
  'Alias is too short': 'El Alias es demasiado corto',
  Aliases: 'Alias',
  All: 'Todo',
  'All IP addresses': 'Todas las direcciones IP',
  'All MSISDNs': 'Todos los números de teléfono',
  'All NIK': '',
  'All Site names': 'Todos los sitios',
  'All Types': 'Todos los tipos',
  'All accounts': 'Todas las cuentas',
  'All addresses': 'Todos los direcciones',
  'All associated domains': 'Todos los dominios asociados',
  'All caught up': 'No hay notificaciones nuevas',
  'All companies': 'Todas las empresas',
  'All contacts': 'Todos los contactos',
  'All courses': 'Todos los cursos',
  'All educations': 'Toda la educación',
  'All emails': 'Todos los correos electrónicos',
  'All family relations': 'Todas las relaciones familiares',
  'All fans': 'Todos los fans',
  'All friend relations': 'Todas las relaciones de amigos',
  'All groups': 'Todos los grupos',
  'All hashtags': 'Todos los hashtags',
  'All hours': 'Todas las horas',
  'All industries': 'Todas las industrias',
  'All jobs': 'Todos los trabajos',
  'All landlines': 'Todas las líneas fijas',
  'All locations': 'Todas las ubicaciones',
  'All mentions': 'Todas las menciones',
  'All messages': 'Todos los mensajes',
  'All names': 'Todos los nombres',
  'All organizations': 'Todas las organizaciones',
  'All other info': 'Toda la demás información',
  'All other relations': 'Todas las demás relaciones',
  'All phone numbers': 'Todos los números de teléfono',
  'All places': 'Todos los lugares',
  'All sent messages': 'Todos los mensajes enviados',
  'All skills': 'Todas las habilidades',
  'All social platforms': 'Todas las plataformas sociales',
  'All sources': 'Todas las fuentes',
  'All the answers are anonymous.': 'Todas las respuestas son anónimas.',
  'All the data collected will be completely anonymous.':
    'Toda la información recolectada es completamente anónima.',
  'All types': 'Todos los tipos',
  'All urls': 'Todas las URLs',
  'All usernames': 'Todos los usuarios',
  'All users': 'Todas las usuarias',
  'All vehicle ID (VIN)': 'Todos los ID de vehículos',
  'All vehicle make': 'Todas las marcas de vehículos',
  'All vehicle models': 'Todos los modelos de vehículos',
  'All volunteering actions': 'Todas las acciones de voluntarios',
  'All week': 'Toda la semana',
  'All work relations': 'Todas las relaciones laborales',
  'Allowed file types: images': 'Tipos de archivo permitidos: imágenes',
  'Always obtain accurate geolocation information when available':
    'Siempre obtener información precisa de geolocalización cuando esté disponible',
  'Always obtain the peer details and geolocate when available':
    'Obtener información sobre el participante de la llamada y geolocalizar siempre que esté disponible',
  'Always obtain the peer details when available':
    'Obtener información sobre el participante de la llamada siempre que esté disponible',
  'Always show descriptions': 'Mostrar siempre descripciones',
  Amount: 'Cantidad',
  'An email with the QR was sent to':
    'Se envió un correo electrónico con el QR a',
  'An error has occurred': 'Se ha producido un error',
  'An international #{type} to or from #{msisdn} coming from #{country} has happened at least #{frequency} time(s).':
    'Un #{type} internacional hacia o desde #{msisdn} procedente de #{country} ha ocurrido al menos #{frequency} hora(s).',
  Analyse: 'Analizar',
  'Analysing data': 'Analizando datos',
  Analysis: 'Análisis',
  'Analysis includes': 'El análisis incluye',
  Analytics: 'Analítica',
  Analyze: 'Analizar',
  'Analyze in progress': 'Analizar en progreso',
  'Analyze location': 'Analizar ubicación',
  'Analyze locations': 'Analizar ubicaciónes',
  'Analyzed content': 'Contenido analizado',
  'Analyzing Results': 'Analizar resultados',
  'Analyzing and merging the results for':
    'Analizar y fusionar los resultados para',
  'Analyzing and unmerging the results for':
    'Analizando y deshaciendo los resultados para',
  'Analyzing results': 'Analizando resultados',
  And: 'y',
  'And more': 'y más',
  Andorra: 'Andorra',
  Angola: 'Angola',
  Anguilla: 'Anguila',
  'Antigua and Barbuda': 'Antigua y Barbuda',
  Any: 'Cualquiera',
  Anytime: 'En cualquier momento',
  'AoI Query': 'Consulta AoI',
  Application: 'Aplicación',
  Applications: 'Aplicaciones',
  Apply: 'Aplicar',
  Apps: 'Aplicaciones',
  April: 'abril',
  Archive: 'Archivo',
  'Are you still in the same investigation?':
    '¿Sigue trabajando en la misma investigación?',
  'Are you sure want to clear all the history ?':
    '¿Seguro que quieres borrar todo el historial?',
  'Are you sure want to clear the history ?':
    '¿Seguro que quieres borrar el historial?',
  'Are you sure want to delete the Activity log entry?': '',
  'Are you sure you want to delete case permanently?':
    '¿Está seguro que desea eliminar el caso de forma permanente?',
  'Are you sure you want to delete investigation permanently?':
    '¿Está seguro que desea eliminar esta investigación de forma permanente?',
  'Are you sure you want to delete target permanently?':
    '¿Está seguro que desea eliminar el objetivo de forma permanente?',
  'Are you sure you want to delete this search?':
    '¿Está seguro de que desea eliminar esta búsqueda?',
  'Are you sure you want to disable the geofencing?':
    '¿Está seguro que desea desactivar la geocerca?',
  'Are you sure you want to leave the program?':
    '¿Está seguro qque desea dejar el programa?',
  'Are you sure you want to remove target from case permanently?':
    '¿Está seguro que desea remover el objetivo del caso permanentemente?',
  'Are you sure you want to remove target from this case?':
    '¿Está seguro que desea eliminar el objetivo de este caso?',
  'Are you sure you want to remove this target?':
    '¿Está seguro que desea remover este objetivo?',
  'Are you sure you want to reset the user password for':
    '¿Está seguro de que desea restablecer la contraseña de usuario para',
  'Are you sure you wish to resubmit?':
    '¿Estás seguro que desea volver a enviarlo?',
  'Are you sure you wish to save this result?':
    '¿Estás seguro de que desea guardar este resultado?',
  Area: 'Área',
  'Area of Interest Query': 'Consulta de área de interés',
  'Area of interest': 'Área de Interés',
  Argentina: 'Argentina',
  Armenia: 'Armenia',
  Articles: 'Artículos',
  Ascending: 'Ascendente',
  'Assign to target or add to existing':
    'Asignar a segmentar o agregar a existente',
  'Assigned targets changed': 'Cambiaron los objetivos asignados',
  'Associated MSISDN with account': 'MSISDN asociado con la cuenta',
  'Associated domains': 'Dominios asociados',
  'Associated with': 'Asociado con',
  'Attended same institution': 'Asistió a la misma institución',
  August: 'agosto',
  Australia: 'Australia',
  Austria: 'Austria',
  'Authenticating to system': 'Autenticarse en el sistema',
  'Auto detect': 'Auto detectar',
  'Autocomplete all': 'Autocompletar todo',
  'Automatic interactions': 'Interacciones automáticas',
  'Available Credits': 'Créditos Disponibles',
  'Available Data': 'Datos disponibles',
  'Available to assign': 'Disponible para asignar',
  Avatar: 'Avatar',
  'Avatar id': 'Id Avatar',
  'Avatar in error state, please contact administrators':
    'El avatar tiene un estado de error, por favor, contacte a su administrador.',
  'Avatar remote view ended': 'La vista remota del Avatar finalizó',
  Avatars: 'Avatares',
  'Average activity': 'Medio actividad',
  'Average matches per session': 'Coincidentes promedio por sesión',
  Azerbaijan: 'Azerbaiyán',
  'BASE LAYERS': 'CAPAS BASE',
  BLOCKED: 'BLOQUEADO',
  BUSY: 'OCUPADO',
  Back: 'Atrás',
  'Back to "Cases and targets"': 'Volver a "Casos y objetivos"',
  'Back to "Core"': 'Regresar a "Core"',
  'Back to Call Logs': 'Regresar al Registro de Llamadas',
  'Back to Case': 'Volver al caso',
  'Back to Dashboard': 'Volver al tablero',
  'Back to login': 'Atrás para iniciar sesión',
  'Back to previous query': 'Volver a la consulta anterior',
  Bahrain: 'Baréin',
  Balance: 'Equilibrar',
  Bangladesh: 'Bangladés',
  'Bank details': 'Información bancaria',
  Barbados: '',
  Basic: 'Básico',
  'Basic OSINT': 'OSINT Básico',
  'Basic OSINT Search': 'Búsqueda básica de OSINT',
  'Basic OSINT will discover more seeds':
    'OSINT Básico descubrirá más semillas',
  'Basic Osint query created successfully!':
    'Consulta básica de Osint creada con éxito!',
  Belarus: 'Bielorrusia',
  Belgium: 'Bélgica',
  Belize: 'Belice',
  Benin: 'Benín',
  Bermuda: '',
  Betweenness: 'Relación',
  Bhutan: 'Bután',
  'Billing error': 'Error de facturación',
  Bio: 'Biografía',
  'Birth date': 'Fecha de nacimiento',
  'Birth location': 'Lugar de Nacimiento',
  Birthday: 'Cumpleaños',
  Blacklist: 'Lista negra',
  'Blacklist number': 'Número de lista negra',
  Blacklisted: 'En lista negra',
  Block: 'Cuadra',
  Blocked: 'Bloqueado',
  Bolivia: 'Bolivia',
  'Bosnia and Herzegovina': 'Bosnia y Herzegovina',
  'Both screenWidth and screenHeight must be specified':
    'Tanto el ancho como la altura de la pantalla deben ser especificados.',
  Botswana: 'Botsuana',
  Brazil: 'Brasil',
  'Breached results for': 'Fuga de datos para',
  'British Indian Ocean Territory': 'Territorio Británico del Océano Índico',
  'British Virgin Islands': '',
  Bright: 'Brillo',
  Broadcaster: 'Locutor',
  Browse: 'Buscar',
  'Browse files': 'Búsqueda de archivos',
  Browser: 'Navegador',
  Brunei: 'Brunéi',
  Bulgaria: 'Bulgaria',
  'Burkina Faso': 'Burkina Faso',
  Burundi: '',
  Busy: 'Ocupado',
  'Button to start adding content to it':
    'en el botón empezar para agregar contenido',
  CALLEE: 'Número Llamado',
  CALLER: 'Número Llamador',
  'CDR Statistics': 'Estadísticas de CDR',
  CURP: 'CURP',
  CVE: '',
  Calculate: 'Calcular',
  Calculating: 'Calculando',
  'Calculates the extended connections of a node. Can identify nodes with influence over the whole network, and not just those directly connected to it.':
    'Calcula las conexiones extendidas de un nodo. Puede calcular los nodos con influencia en la red. y no solamente los conectados a esta.',
  Call: 'Llamada',
  'Call Analysis': 'Análisis de llamadas',
  'Call Log': 'Registro de llamadas',
  'Call Log Analysis': 'Análisis de registro de llamadas',
  'Call Log Cancel Request': 'Solicitud de cancelación de registro de llamadas',
  'Call Log MSISDN': 'Registro de llamadas MSISDN',
  'Call Log Pending': 'Registro de llamadas pendiente',
  'Call Log Request': 'Solicitud de registro de llamadas',
  'Call Log Request Pending': 'Registro llamadas solictud pendiente',
  'Call Log for': 'Registro de llamadas para',
  'was uploaded successfully': 'ha sido cargado satisfactoriamente',
  'is importing': 'está siendo importado',
  'could not be mapped automatically': 'no pudo ser mapeado automáticamente',
  'has failed to upload': 'ha fallado su carga',
  'Call Log has been requested': 'El registro de llamadas ha sido solicitado',
  'Call Logs': 'Registro de llamadas',
  'Call Logs uploaded successfully!':
    'Registros de llamadas cargados correctamente.',
  'Call analysis': 'Análisis de llamadas',
  'Call detail records for': 'Registros de detalles de llamadas para',
  'Call duration': 'Duración de llamada',
  'Call in': 'Entrada',
  'Names from Call ID Apps': 'Nombres en Apps de ID de Llamada',
  'Call log': 'Registro de llamadas',
  'Call log MSISDN is different from target MSISDN.':
    'El registro de llamadas MSISDN es diferente del MSISDN de destino.',
  'Call log analysis': 'Análisis de registro de llamadas',
  'Call log map': 'Mapa de registro de llamadas',
  'Call log pending': 'Registro de llamadas pendiente',
  'Call log recommandation archived.':
    'Recomendación de registro de llamadas archivado',
  'Call log recommandation unarchived.':
    'Recomendación de registro de llamadas desarchivado',
  'Call log record': 'Registro de historial de llamadas',
  'Call log report in PDF': 'Reporte de Registro de Llamadas en PDF',
  'Call log request #{type} for #{duration} months':
    'Solicitud de CDR por #{type} de #{duration} meses',
  'Call log request is already in pending':
    'Su solicitud de registro de llamadas está pendiente',
  'Call log requested': 'Registro de llamadas solicitado',
  'Call log successfully processed!':
    '¡Registro de llamadas procesado correctamente!',
  'Call log top associates': 'Registro de llamadas principales asociados',
  'Call log top relation': 'Relación importante del registro de llamadas',
  'Call log upload for #{type}': 'Una existosa Carga de #{type}',
  'Call log upload request for author':
    'Solicitud de carga del registro de llamadas para la autora',
  'requested at': 'solicitado en',
  'has been canceled and refunded': 'ha sido cancelado y reembolsado',
  'Call logs': 'Registro de llamadas',
  'Call logs interaction depth':
    'Interacción de registros de llamada a detalle',
  'Call logs should be uploaded as a single .csv file':
    'Los registros de llamadas deben cargarse como un solo archivo .csv',
  'Call logs should be uploaded as a single .csv or .xlsx file':
    'Los registros de llamadas deben cargarse como un solo archivo .csv o .xlsx',
  'Call out': 'Salida',
  Called: 'Llamado',
  Callee: 'Llamante',
  Caller: 'Llamador',
  Callerid: 'Callerid',
  Calls: 'Llamadas',
  'Calls and SMS': 'Llamadas y SMS',
  'Calls duration': 'Duración de las llamadas',
  'Calls number': 'Número de llamadas',
  Cambodia: 'Camboya',
  Cameroon: 'Camerún',
  Canada: 'Canadá',
  Cancel: 'Cancelar',
  'Cancel Request sent successfully':
    'Cancelación de solicitud enviada con éxito',
  'Cancel all uploads': 'Cancelar todas las cargas',
  'Cancel request sent successfully!':
    '¡Peticion cancelada satisfactoriamente!',
  Cancelled: 'Cancelado',
  'Cancelled request': 'Solicitud cancelada',
  'Cannot update schema': 'No se pueda actualizar el esquema',
  'Cannot upload file': 'El archivo no puedo ser cargado',
  'Cape Verde': '',
  'Card number': 'Número de Tarjeta',
  Cardinal: 'Cardinal',
  Carrier: 'Proveedor',
  Case: 'Caso',
  'Case Call Analysis': 'Análisis de llamadas del caso',
  'Case Cdr Statistics': 'Estadísticas de CDR del caso',
  'Case Count Analysis': 'Conteo de llamadas del caso',
  'Case Event By Type': 'Evento por tipo del caso',
  'Case Target Activity': 'Actividad del objetivo del caso',
  'Case Top Associates': 'Personas principales del caso',
  'Case Top Locations': 'Ubicaciones principales del caso',
  'Case call logs report': 'Reporte de registro de llamadas del caso',
  'Case colour changed': 'Cambió el color del caso',
  'Case created successfully!': 'Caso creado con éxito!',
  'Case has not been created': 'El caso no ha sido creado',
  'Case has not been edited': 'El caso no ha sido actualizado',
  'Case has not been renewed': 'El caso no ha sido renovado',
  'Case is expired': 'Caso ha caducado',
  'Case name changed': 'Cambió el nombre del caso',
  'Case renewed successfully!': 'Caso renovado con éxito!',
  'Case targets': 'Objetivos del caso',
  'Case updated successfully!': '¡Caso actualizado con éxito!',
  Cases: 'Casos',
  'Cases and Targets': 'Casos y Objetivos',
  'Cases and targets': 'Casos y objetivos',
  'Cases duration': '',
  Catcher: 'Receptor',
  Category: 'categoría',
  'Cayman Islands': 'Islas Caimán',
  'Cdr Export': 'Exportar CDR',
  'Cdr Statistics': 'Estadísticas de CDR',
  'Cdr Type': 'Tipo de CDR',
  Cell: 'Celda',
  'Cell ECI': 'ECI de celda',
  'Cell ENBID': 'ENBID de celda',
  'Cell Id': 'ID de celda',
  'Cell ID': 'ID de celda',
  'Cell LCID': 'LCID de celda',
  'Cell id': '',
  'Central African Republic': 'República Centroafricana',
  'Cell tower': 'Torre cercana',
  Certainty: 'Certeza',
  Chad: 'Chad',
  Change: 'Cambio',
  'Change OTP method': 'Cambiar método OTP',
  'Change Password': 'Cambiar la contraseña',
  'Change language': 'Cambiar idioma',
  'Change password': 'Cambiar la contraseña',
  'Change your password': 'Cambia tu contraseña',
  'Changes saved successfully!': '¡Los cambios se guardaron exitosamente!',
  Characterized: 'Caracterizado',
  'Chat Sentiment': 'Sentimiento del chat',
  'Chat details': 'Información del Chat',
  'Chat summary': 'Resumen de chat',
  Chats: 'Conversaciones',
  'Check for spelling errors': 'Verificar errores ortográficos',
  'Check-ins': 'Lugares',
  'Checkin details': 'Info. de Checkins',
  'Checking Data Availability...':
    'Comprobando la disponibilidad de los datos ...',
  Chile: 'Chile',
  China: 'República Popular China',
  Choose: 'Seleccionar',
  'Choose Duration': 'Seleccionar Duración',
  'Choose Frequency': 'Seleccionar Frecuencia',
  'Choose age range': 'Seleccione rango de edad',
  'Choose an interval': 'Seleccione un intervalo',
  'Choose duration and frequency': 'Seleccionar duración y frecuencia',
  'Choose file to upload': 'Seleccione un archivo para subir',
  'Choose image to upload': 'Seleccione cargar imagen',
  'Choose media for upload': 'Seleccione los medios a subir',
  'Choose profiles or seeds to build your target':
    'Elija perfiles o semillas para construir su objetivo',
  'City name': 'Ciudad',
  Clear: 'Eliminar',
  'Clear All': 'Limpiar todo',
  'Clear all': 'Limpiar todo',
  'Clear all filters': 'Limpiar filtros',
  'Clear filters': 'Filtros claros',
  'Clear map': 'Limpiar Mapa',
  'Clear selection': 'Selección clara',
  'Click for more options': 'Haga clic para más opciones',
  'Click on the button below to renew for #{days} days':
    'Haga clic en el botón de abajo para renovar por #{days} días',
  'Click on button to highlight day and night patterns':
    'Haga clic en el botón para resaltar los patrones de día y noche',
  'Click on the button to minimise the search process and continue with your tasks':
    'Haga click en el botón para minimizar el proceso de búsqueda y continuar con sus tareas',
  'Click to see details': 'Haga clic para ver los detalles',
  Close: 'Cerrar',
  'Close all': 'Cierra todo',
  'Close session details': 'Cerrar los detalles de la sesión',
  'Closing this process before the Virtual ID logs in successfully will likely cause issues in operating it.':
    '',
  Collapse: 'Colapsar',
  'Collected Profile URL': 'URL de perfil recopilado',
  'Collecting and analysing intelligence for':
    'Recolectando y analizando inteligencia para',
  Colombia: 'Colombia',
  Colony: 'Colonia',
  Color: 'Color',
  'Column settings': 'Ajustes de columna',
  Command: 'Comando',
  'Comment on posts': 'Comentar en publicaciones',
  'Commented on a photo of the target': 'Comentó una foto del objetivo.',
  'Commented on a post of the target': 'Comentó una publicación del objetivo.',
  Commenters: 'Comentaristas',
  'Common Associates': 'Personas en común',
  'Common Groups': 'Grupos comunes',
  'Common Ips': 'IPs en común',
  'Common Locations': 'Ubicaciones en común',
  'Common education places': 'Centros educativos en común',
  'Common faces on photos': 'Rostros comunes en fotos',
  'Common locations': 'Ubicaciones comunes',
  'Common relations': 'Relaciones comunes',
  'Common relations on social networks': 'Relaciones comunes en redes sociales',
  'Common working places': 'Lugares de trabajo en común',
  'Communication Channel': 'Canal de comunicación',
  Communities: '',
  'Community Details': 'Community Details',
  Comoros: 'Comoras',
  Companies: 'Empresas',
  Company: 'Empresa',
  'Company Employees': 'Empleados de la Empresa',
  'Company Logo': 'Logo de la Empresa',
  'Company Logo Url': 'URL del logotipo de la empresa',
  'Company employee': 'Empleado/a de la empresa',
  'Company location': 'Ubicación de la compañía',
  Completed: 'Completado',
  'Completed at': 'Completado el',
  'Completed successfully': 'Completado con exito',
  Confidence: 'Confianza',
  Confidential: 'Confidencial',
  'Confidential content': 'Contenido confidencial',
  'Configure keywords list': 'Configurar lista de palabras clave',
  'Configuring Gmail': 'Configurando Gmail',
  'Configuring acc.': 'Configurando cuentas',
  Confirm: 'Confirmar',
  'Confirm New Password': 'Confirmar Nueva Contraseña',
  'Confirm new password': 'Confirmar nueva contraseña',
  Confirmation: 'Confirmación',
  'Confirmation required': 'Confirmación requerida',
  'Confirmed Infection': 'Infección Confirmada',
  'Confirmed infection': 'Infección Confirmada',
  'Congo-Brazzaville': 'República del Congo',
  'Connected numbers by device for': 'Números conectados por dispositivo',
  'Connected numbers by location for': 'Números conectados por ubicación',
  'Connected with MSISDN': 'Conectada/o con MSISDN',
  'Connected with this location': 'En conexión con esta ubicación',
  Connections: 'Conexiones',
  'Connections Details': 'Detalles de las Conexiones',
  Consec: 'Consec',
  'Contact Customer Success': 'Contacte a Éxito del Cliente',
  'Contact Us': 'Contáctenos',
  'Contact’s active groups': 'Grupos activos de contactos',
  Contains: 'Contiene',
  Content: 'Contenido',
  Continue: 'Continuar',
  'Conversation Type': 'Tipo de conversación',
  'Cook Islands': 'Islas Cook',
  Coordinates: 'Coordinar',
  Copied: 'Copiado',
  'Copied!': '¡Copiado!',
  Copy: 'Copiar',
  'Copy URL': 'Copiar URL',
  'Copy all': 'Copiar todo',
  'Copy link': 'Copiar link',
  'Copy location': 'Copiar ubicación',
  'Copy name': 'Copiar nombres',
  'Copy password': 'Copiar contraseña',
  'Copy to Clipboard': 'Copiar al portapapeles',
  'Copy value': 'Copiar',
  Core: 'Core',
  Correlator: 'Correlator',
  'Costa Rica': 'Costa Rica',
  'Could not determine the target’s status':
    'No se pudo obtener el estado del objetivo',
  'Could not find a person with exact input':
    'No se pudo encontrar a la persona con la entrada exacta',
  'Could not gather data for this report':
    'No se pudo obtener información para este reporte',
  'Could not gather data for this target':
    'No se pudieron recopilar datos para este objetivo',
  'Could not reset password': 'No se pudo restablecer la contraseña',
  'Could not save!': '¡No se pudo guardar!',
  'Could not update target profile, user id already exists!':
    'No se pudo actualizar el perfil de destino, ¡la identificación de usuario ya existe!',
  'Could not verify that you are human':
    'No pudimos verificar que es un humano',
  'Could not verify that you are human, please contact your administrator':
    'No se pudo verificar que eres humano, comunícate con tu administrador',
  Count: 'Conteo',
  'Count analysis': 'Análisis de recuento',
  Countries: 'Países',
  Country: 'País',
  'Country N/A': 'Pais N/A',
  'Country and region': 'País y región',
  Courses: 'Cursos',
  'Covid vaccination status': 'Estado de Vacunación COVID',
  'Covid vaccine': 'Vacunación COVID',
  Create: 'Crear',
  'Create Place': 'Crear Lugar',
  'Create Target': 'Crear objetivo',
  'Create User': 'Crear Usuario',
  'Create a Geofencing Task (1 week / 15 minutes)':
    'Crear una geo-cerca (1 semana / 15 minutos)',
  'Create a Geofencing Task (6 hours / 15 minutes)':
    'Crear una geo-cerca (6 horas / 15 minutos)',
  'Create a Schedule Task (1 week / 15 minutes)':
    'Crear una tarea programada (1 semana / 15 minutos)',
  'Create a Schedule Task (6 hours / 15 minutes)':
    'Crear una tarea programada (6 horas / 15 minutos)',
  'Create a case': 'Crear un caso',
  "Create a case with the target's alias":
    'Crear un caso con el alias del objetivo',
  'Create a new target': 'Crea un nuevo objetivo',
  'Create a new target and Enhance profile':
    'Crear un nuevo objetivo y Mejorar perfil',
  'Create a new target and Request call log':
    'Crear un nuevo objetivo y solicitar registro de llamadas',
  'Create a new target and find possible associates, visited places, connections on social media, groups and more.':
    'Crear un nuevo objetivo y encontrar posibles contactos, lugares visitados, conexiones en redes sociales, grupos y más.',
  'Create a target': 'Crea un objetivo',
  'Create a temporary user': 'Crear un usuario temporal',
  'Create case': 'Crear caso',
  'Create case investigation': 'Crear caso de la investigación',
  'Create group': 'Crea un grupo',
  'Create new case': 'Crear nuevo caso',
  'Create new case or search for existing one':
    'Cree un nuevo caso o busque uno existente',
  'Create new keywords list': 'Crear nueva lista de palabras clave',
  'Create new list': 'Crear nueva lista',
  'Create new target': 'Crear nuevo objetivo',
  'Create new target or search for existing one':
    'Cree un nuevo objetivo o busque uno existente',
  'Create or add to target': 'Crear o agregar al objetivo',
  'Create post': 'Crear publicación',
  'Create post was successfully created':
    'Crear publicación fue creada con éxito',
  'Create target': 'Crear objetivo',
  Created: 'Creado por',
  'Created On': 'Creado en',
  'Created at': 'Creado el',
  Creating: 'Creando',
  'Credit cards': 'Tarjetas de crédito',
  'Credit change for request_cdr for IMEI':
    'Cargo de crédito por solicitud de CDR del IMEI',
  'Credit usage': 'Uso de créditos',
  Credits: 'Créditos',
  'Credits Usage Statistics': 'Estadísticas de Uso de Créditos',
  'Credits are expired': 'Los créditos están vencidos',
  'Credits will be deducted only if accurate information is found.':
    'Los créditos serán deducidos sólo en caso de encontrar información correcta.',
  Critical: 'Crítico',
  Croatia: 'Croacia',
  'Cross Intel Search': 'Búsqueda cruzada de Intel',
  Crypto: 'Crypto',
  Cuba: 'Cuba',
  Cumulative: 'Acumulativas',
  Current: 'Actual',
  'Current And Previous Workplaces (Target)':
    'Lugar de trabajo actual y anterior (Objetivo)',
  'Current Call': 'Llamada actual',
  'Current Password': 'Contraseña actual',
  'Current call': 'Llamada actual',
  'Current city': 'Ciudad actual',
  'Current password': 'Contraseña actual',
  'Current target has no MSISDN attached!':
    '¡El objetivo actual no tiene ningún MSISDN adjunto!',
  'Custom report': 'Reporte personalizado',
  Cv: 'CV',
  Cve: 'CV',
  Cyprus: 'Chipre',
  Czechia: 'Chequia',
  "Côte d'Ivoire": 'Costa de Marfil',
  'Côte dIvoire': '',
  DATA: 'Datos',
  DEACTIVATED: 'DESACTIVADO',
  DEBUG: 'Depurar',
  'Daily activity': 'Actividad diaria',
  'Daily credits assigned': 'Créditos diarios asignados',
  'Daily credits available': 'Créditos diarios disponibles',
  'Daily quota': 'Cuota diaria',
  'Daily quota assigned': 'Cuota diaria asignada',
  Dark: 'Oscuro',
  'Dark Web': 'Dark Web',
  Darkweb: 'Darkweb',
  Dashboard: 'Tablero',
  Data: 'Datos',
  'Data collected at': 'Datos recopilados en',
  'Data saved successfully!': '¡Datos guardados exitosamente!',
  'Data transferred': 'Datos transferidos',
  Date: 'Fecha',
  'Date & time': 'Día y fecha',
  'Date Of Birth': 'Fecha de nacimiento',
  'Date Of Query': 'Fecha de consulta',
  'Date Period': 'Fecha',
  'Date added': 'Información agregada',
  'Date and time': 'Fecha y hora:',
  'Date created': 'Fecha de creación',
  'Date of birth': 'Fecha de nacimiento',
  'Date of creation': 'Fecha de creación',
  'Date of query': 'Fecha de consulta',
  Dates: 'Fecha',
  'Dates of birth': 'Fecha de nacimiento',
  'Day first': '',
  'Day of month': 'Día del mes',
  'Day of week': 'Día de la semana',
  'De Anon': 'De anonimizar',
  Deactivate: 'Desactivar',
  'Deactivate 2-step verification': 'Desactivar la verificación en dos pasos',
  'Deactivate OTP': 'Desactivar OTP',
  Dear: 'Hola',
  December: 'diciembre',
  Deep: 'Extenso',
  'Deep Webint': 'WebInt profundo',
  'Deep Webint query created successfully!':
    '¡Consulta de WebInt profundo creada con éxito!',
  'Deep search will add second degree relations, likers, commenters, reactors and more':
    'La búsqueda extensa agregará relaciones de segundo grado, personas que han dado me gusta, comentado, reaccionado y más.',
  'Define quota': 'Definir cuota',
  Degrees: 'Grados',
  Delete: 'Borrar',
  'Delete Group': 'Eliminar Grupo',
  'Delete Target': 'Borrar Objetivo',
  'Delete case': 'Borrar caso',
  'Delete file': 'Elimine archivo',
  'Delete photo': 'Borrar foto',
  'Delete target': 'Borrar objetivo',
  'Deleted!': '¡Eliminado',
  'Democratic Republic of the Congo': 'República Democrática del Congo',
  Denmark: 'Dinamarca',
  Deposit: 'Depósito',
  Descending: 'Descendiendo',
  Description: 'Descripción',
  Destination: 'Destino',
  Details: 'Detalles',
  'Details for': 'Detalles',
  'Details panel': 'Panel de información',
  Device: 'Dispositivo',
  'Device ID': 'ID de dispositivo',
  'Device usage': 'Uso del Dispositivo',
  'Device coordinates': 'Coordenadas del dispositivo',
  Devices: '',
  Direction: 'Dirección',
  Disable: 'Desactivar',
  'Disable drawing': 'Deshabilitar trazo',
  'Disable email notifications':
    'Deshabilitar notificaciones de correo electrónico',
  'Disable sms notifications': 'Desactivar notificaciones sms',
  'Disable target heatmap': 'Deshabilitar mapa de calor del objetivo',
  'Disable target history timeline':
    'Deshabilitar la línea de tiempo del historial del objetivo',
  'Disable traffic layer': 'Deshabilitar capa de tráfico',
  Disabled: 'Discapacitada',
  'Disabled Feature': 'Funcionalidad Desactivada',
  Discard: 'Descartar',
  Disclaimer: 'Responsabilidad',
  'Disclaimer content': 'Contenido de exclusión de responsabilidad',
  Discover: 'Descubrir',
  'Discover more seeds': 'Descubrirá más semillas',
  'Discover persons': 'Descubrir personas',
  'Discovered persons': 'Personas descubiertas',
  Discovery: 'Descubrimiento',
  Discovery2: 'Descubrimiento2',
  'Discovery will be updated on 12:00': '',
  'Dislike posts': 'Dar "No me gusta',
  Distance: 'Distancia',
  'Distance from target': 'Distancia del objetivo',
  'Distribution of keywords on platforms':
    'Distribución de palabras clave en las plataformas',
  'Distribution of topics': 'Distribución de temas',
  Djibouti: 'Yibuti',
  'Do you want to extract details about the Call Peer':
    'Desea extraer información sobre el participante de la llamada',
  'Do you want to extract the Peer Caller ID?':
    '¿Desea extraer el identificador de llamadas?',
  'Do you want to extract the Peer Caller ID? This action will deduct 4 credits.':
    '¿Desea extraer el identificador de llamadas? Esta acción deducirá 4 créditos.',
  'Do you want to get cell level location?':
    '¿Desea obtener ubicación a nivel torre?',
  'Do you want to perform an Active Locate query? This action might leave anonymous traces on target’s phone.':
    '¿Desea realizar una consulta de localización activa? Esta acción puede dejar rastros anónimos en el teléfono del objetivo.',
  'Do you wish to perform an intelligence query or view the existing intel?':
    '¿Desea realizar una consulta de inteligencia o ver alguna consulta de inteligencia existente?',
  'Do you wish to perform an intelligence query?':
    '¿Desea realizar una consulta de inteligencia?',
  'Do you wish to reload the chart? Any data created from analysing nodes will be removed':
    '¿Desea recargar el gráfico? Se eliminarán todos los datos creados a partir de los nodos de análisis.',
  Domain: 'Dominio',
  Dominica: '',
  'Dominican Republic': 'República Dominicana',
  Done: 'Listo',
  'Double click to expand/collapse node':
    'Haga doble clic para expandir / contraer el nodo',
  Download: 'Descargar',
  'Download CSV': 'Descargar CSV',
  'Download file': 'Descargar archivo',
  'Download report': 'Descargar informe',
  Downloading: 'Descargando',
  'Drag and Drop file here or click to browse':
    'Arrastre y suelte aquí los archivos o haga clic para navegar',
  'Drag and drop to add an MSISDN to the investigation':
    'Arrastre y suelte para agregar un MSISDN a la investigación',
  'Drag and drop to load a case to the investigation':
    'Arrastre y suelte para agregar un caso a la investigación',
  'Drag and drop to load a target to the investigation':
    'Arrastre y suelte para agregar un objetivo a la investigación',
  'Drag and drop to upload file for Text analysis':
    'Arrastre y suelte archivo para  Análisis de Texto',
  'Drag file here': 'Arrastre aquí el archivo',
  'Drag image here': 'Arrastre una imagen hasta aquí',
  'Drag media here': 'Arrastre aquí el contenido multimedia',
  'Drag the map to precise your location':
    'Arrastre el mapa para precisar su ubicación',
  'Drag to link with another node': 'Arrastre para vincular con otro nodo',
  Draw: 'Trazar',
  'Draw area': 'Dibujar área',
  'Draw circle': 'Trazar círculo',
  'Draw marker': 'Dibujar marcador',
  'Drugs and illegal substances': 'Drogas o sustancias ilegales',
  Duplicated: 'Duplicados',
  Duration: 'Duración',
  'Duration (s)': 'Duración',
  'During days': 'Durante días',
  'During nights': 'Durante noches',
  'During the importing process, please do not log out, close this tab or transition to another website':
    'Durante el proceso de importación, por favor, no cierre sesión, cierre esta pestaña o cambie a otro sitio web',
  "E.g., 'I work with drug cases in Panama' or 'I work with kidnapping investigations in Mexico'.":
    "E.g., 'Trabajo con casos sobre drogas en Panama' or 'Trabajo en investigaciones de sucestro en Mexico'.",
  ERROR: 'ERROR',
  'Earliest activity': 'Actividad más antigua',
  'East Timor': '',
  Ecuador: 'Ecuador',
  Edit: 'Editar',
  'Edit Group': 'Editar Grupo',
  'Edit Target': 'Editar objetivo',
  'Edit a target': 'Editar un objetivo',
  'Edit family member': 'Editar miembro de la familia',
  'Edit name': 'Editar nombre',
  'Edit places': 'Editar lugares',
  Education: 'Educación',
  'Education (Target)': 'Educación (Objetivo)',
  Educations: 'Educación',
  Egypt: 'Egipto',
  'Eigen centrality': 'Eigen centrality',
  'El Salvador': 'República de El Salvador',
  Email: 'Correo electrónico',
  'Email address': 'Correo electrónico',
  'Email sent.': 'Email enviado.',
  'Email to support': 'Enviar Email a Soporte',
  'Email(s)': 'Email(s)',
  Emails: 'Correos electrónicos',
  Emergency: 'Emergencia',
  'Employment and education': 'Trabajo y educacion',
  'Empty space': '',
  'Enable Call log analysis': 'Habilitar Análisis de registro de llamadas',
  'Enable OTP': 'Habilitar OTP',
  'Enable email notifications':
    'Habilitar notificaciones de correo electrónico',
  'Enable geofencing': 'Habilitar geocerca',
  'Enable layer': 'Habilitar capa',
  'Enable sms notifications': 'Habilitar notificaciones sms',
  'Enable support requests': 'Habilitar solicitudes de soporte',
  'Enable target heatmap': 'Habilitar mapa de calor del objetivo',
  'Enable target history timeline':
    'Habilitar la línea de tiempo del historial del objetivo',
  'Enable traffic layer': 'Habilitar capa de tráfico',
  'End Date': 'Fecha de terminación',
  'End date': 'Fecha de terminación',
  Ending: 'Final',
  English: 'Inglés',
  'Enhance Profile': 'Mejorar Perfil',
  'Enhance Profile query created successfully!':
    '¡Consulta de Mejora de Perfil creada satisfactoriamente!',
  'Enhance profile': 'Mejorar perfil',
  'Enhance profile in order to automatically create a target, discover more intelligence information and retrieve important insights':
    'Mejore el perfil para crear automáticamente un objetivo, descubrir más información de inteligencia y recuperar datos importantes.',
  'Enhance profile in order to discover more intelligence information and retrieve important insights':
    'Mejore el perfil para descubrir más información de inteligencia y recuperar datos importantes.',
  'Enrich Profile': 'Enriqueciendo perfil',
  'Enrich profile': 'Enriquecer perfil',
  'Enrich target with Somedus data':
    'Enriqueciendo perfil con datos de Somedus',
  'Enter Ad ID(s)': 'Ingrese Ad ID(s)',
  'Enter IMEI': 'Ingrese IMEI',
  'Enter MSISDN': 'Ingrese MSISDN',
  'Enter OTP': 'Ingrese OTP',
  'Enter Password': 'Ingrese Contraseña',
  'Enter URL': 'Ingrese URL',
  'Enter a address': 'Ingrese una dirección',
  'Enter a location': 'Ingresar ubicación',
  'Enter a MSISDN or IMSI': 'Ingrese un MSISDN o IMSI',
  'Enter a number': 'Ingrese un número',
  'Enter a short description of your investigation':
    'Ingrese una breve descripción de su investigación',
  'Enter a valid IMEI': 'Ingrese un IMEI válido',
  'Enter a valid IMSI': 'Ingrese un IMSI válido',
  'Enter a valid TMSI': 'Ingrese un TMSI válido',
  'Enter a valid number': 'Ingrese un número válido',
  'Enter a valid phone': 'Ingrese un teléfono válido',
  'Enter a valid username': 'Ingrese un nombre de usuario válido',
  'Enter a valid value': 'Introduzca un valor valido',
  'Enter a value': 'Introduzca un valor',
  'Enter alias': 'Ingrese un alias',
  'Enter coordinates: lat, lon': 'Ingrese las coordenadas: lat, lon',
  'Enter emails': 'Ingrese correos electrónicos',
  'Enter investigation name': 'Ingrese el nombre de la investigación',
  'Enter notes': 'Ingresar notas',
  'Enter password': 'Introducir la contraseña',
  'Enter phone': 'Ingrese el teléfono',
  'Enter phone numbers': 'Ingrese números de teléfono',
  'Enter report title': 'Ingrese el Título del Reporte',
  'Enter the OTP code from your device':
    'Ingrese el código OTP de su dispositivo',
  'Enter the code received via SMS': 'Ingrese el código recibído vía SMS',
  'Enter the code received via email':
    'Ingrese el código recibído vía correo electrónico',
  'Enter the complete phone number including country code. Example: #{example}':
    'Introduzca el número de teléfono completo, incluido el prefijo del país. Ejemplo: #{example}',
  'Enter the keywords': 'Ingrese palabras clave',
  'Enter the text': 'Ingrese el texto',
  'Enter valid coordinates': 'Ingrese coordenadas válidas',
  'Enter value': 'Ingresar información',
  Entities: 'Entidades',
  Entity: 'Entidad',
  'Entity Type': 'Tipo de Entidad',
  'Equatorial Guinea': 'Guinea Ecuatorial',
  Equipment: 'Equipo',
  Eritrea: 'Eritrea',
  'Error occured while attempting to reserve':
    'Ocurrió un error al intentar reservar',
  'Error while uploading file': 'Error al subir el archivo',
  Errors: 'Errores',
  Estonia: 'Estonia',
  Ethiopia: 'Etiopía',
  Event: 'Evento',
  'Event viewer': 'Visor de eventos',
  'Events Type': 'Tipo de Evento',
  'Events by type': 'Eventos por tipo',
  'Events found': 'Eventos encontrados',
  'Every MSISDN should start with + .': 'Cada MSISDN debe empezar con + .',
  'Existing target': 'Objetivo existente',
  Exchange: 'Exchange',
  Exit: 'Salir',
  'Exit full screen': 'Salir de pantalla completa',
  'Exit without log in': '',
  Expand: 'Expandir',
  'Expiration date': 'Fecha de Expiración',
  Expired: 'Caducado',
  'Expired Avatar': 'Avatar Expirado',
  'Expired cases will be renewed': 'Los casos caducados se renovarán',
  'Expired targets will be renewed and added to case':
    'Los objetivos que expiraron serán renovados y agregados al caso',
  'Expires On': 'Expira el',
  'Expires on': 'Caduca el',
  'Expiring date': 'Fecha de expiración',
  Export: 'Exportar',
  'Export CSV': 'Exportar CSV',
  'Export PDF': 'Exportar PDF',
  'Export as CSV': 'Exportar como CSV',
  'Export call log': 'Exportar registro de llamadas',
  'Export call log report': 'Exportar reporte de registro de llamadas',
  'Export current investigation view':
    'Exportar la vista actual de la investigación',
  'Export detailed geolocation report':
    'Exportar reporte detallado de geolocalización',
  'Export graph': 'Exportar gráfica',
  'Export selected': 'Exportar seleccionado',
  'Export table': 'Exportar tabla',
  'Export to CSV': 'Exportar a CSV',
  'Export to pdf': 'Exportar a PDF',
  Exporting: 'Exportador',
  'Exporting CSV': 'Exportar CSV',
  'Exporting call log': 'Exportar registro de llamadas',
  'Exporting file': 'Exportando archivo',
  'Exporting table in CSV': 'Exportar tabla en CSV',
  'Extracted entity from file': 'Entidad extraída del archivo',
  'Extracted event from file.': 'Evento extraído desde archivo.',
  'ExtractusD Response': 'Respuesta de Extractus',
  'ExtractusD request': 'Solicitud ExtractusD',
  'ExtractusD request by user': 'Solicitud de ExtractusD por el usuario',
  'ExtractusD requested by user': 'Solicitado ExtractusD por la usuaria',
  'Eyes Color': 'Color de ojos',
  FACES: 'CARAS',
  FEED: 'ACTIVIDAD',
  'Face & name': 'Rostro y nombre',
  Facebook: 'Facebook',
  'Facebook Friend': 'Amigo/Amiga de Facebook',
  'Facebook Group': 'Grupo de Facebook',
  'Facebook Significant Other': 'Otro significativo de Facebook',
  'Facebook account confirmed. Uploading photos into account':
    'Cuenta de Facebook confirmada. Cargando fotos en la cuenta',
  'Facebook family': 'Familia de Facebook',
  'Facebook friends': 'Amigo/Amiga de Facebook',
  'Facebook group member': 'Miembro del grupo de Facebook',
  'Facebook groups': 'Grupos de facebook',
  'Facebook id': 'Facebook id',
  'Facial Hair': 'Cabello facial',
  Failed: 'Falló',
  'Failed request': 'Solicitud fallida',
  'Failed to find intel suggestions!':
    '¡Error al encontrar sugerencias de inteligencia!',
  'Failed to retrieve': 'La solicitud falló',
  'Failed!': '¡Falló!',
  'Falkland Islands': 'Islas Malvinas',
  Family: 'Familia',
  'Family Members': 'Miembros de la Familia',
  'Family member on Facebook': 'Miembro de la familia en Facebook',
  'Family relations': 'Relaciones familiares',
  'Fan of': 'Fan de',
  Fans: 'Fans',
  'Faroe Islands': 'Islas Feroe',
  'Fathers name': 'Apellido Paterno',
  'Favorite count': 'Conteo favorito ',
  'Favorite results': 'Resultados favoritos',
  Feature: 'Funcionalidad',
  Features: 'Funcionalidad',
  February: 'febrero',
  'Federated States of Micronesia': '',
  Feed: 'Actividad',
  Feedback: 'Reacción',
  'Feedback is required': 'Sus comentarios son requeridos',
  Female: 'Mujer',
  'Fenced Area': 'Area Delimitada',
  'Fetch call logs': 'Obtener registros de llamadas',
  'Fetch instant messaging profiles':
    'Obtener perfiles de mensajería instantánea',
  'Fetch social profiles': 'Obtener perfiles sociales',
  'Fetching data': 'Recuperando datos',
  'Fetching facebook friends': 'Obteniendo amigos de Facebook',
  'Field is required': 'El campo es requerido',
  Fiji: 'Fiyi',
  File: 'Archivo',
  'File error': 'Error de archivo',
  'File name': 'Nombre del archivo',
  'File name must match #{type} number':
    'El nombre del archivo debe ser igual al número #{type}',
  'File size': ' Tamaño del archivo',
  'File upload failed. Please make sure that your file is in Zip format, and contains a properly structured JSON file':
    'La carga del archivo falló. Por favor, asegúrese de que el archivo esté en formato ZIP y contenga un archivo JSON debidamente estructurado',
  'File uploaded successfully!': '¡El archivo ha sido subido correctamente!',
  'Filling OTP': 'Completando OTP',
  'Filling personal info': 'Completando información personal',
  'Filling photos': 'Completando fotos',
  Filter: 'Filtrar',
  'Filter by device': 'Filtrar por dispositivo',
  'Filter by source type': 'Filtrar por tipo de fuente',
  'Filter panel': 'Panel de filtro',
  'Filter target': '',
  'Filter targets': 'Filtrar objetivos',
  Filters: 'Filtros',
  'Financial crime': 'Delitos Financieros',
  'Find commons': 'Encontrar similares',
  'Find possible associates, common visited places, connections on social media, groups and more. Credits will be deducted only if accurate information is found.':
    'Encontrar posibles contactos, lugares visitados, conexiones en redes sociales, grupos y más. Los créditos serán deducidos sólo en caso de encontrar información.',
  'Find possible associates, visited places, connections on social media, groups and more.':
    'Encontrar posibles asociados, lugares visitados, conexiones en redes sociales, grupos y más',
  'Find profile': '',
  'Find profiles': 'Buscar perfiles',
  'New Search': 'Nueva búsqueda',
  'Find profiles & locate phone': 'Buscar perfiles y ubicar teléfono',
  'Find profiles by': 'Buscar perfiles por',
  'Find profiles by face and name': 'Buscar perfiles por rostro y nombre',
  'Finish adding targets': 'Terminar de agregar objetivos',
  'Finish editing': 'Finalizar edición',
  Finland: 'Finlandia',
  'First message': 'Primer mensaje',
  'First month is free': 'El primer mes es gratis',
  'First page': 'Primera página',
  Fixed: 'Fijo',
  'Fixed credits assigned': 'Créditos fijos asignados',
  'Fixed credits available': 'Créditos fijos disponibles',
  'Fixed quota': 'Cuota fija',
  Fleet: 'Flotilla',
  Follow: 'Seguir',
  'Follow is already disabled.': 'Seguir ya está deshabilitado.',
  'Follow is already enabled.': 'Seguir ya está habilitado.',
  'Follow started for': 'Seguimiento iniciado para',
  'Follow stopped for': 'Seguimiento detenido para',
  'Follow with Geofence': 'Seguir con geocerca',
  Follower: 'Seguidora',
  'Follower on Instagram': 'Seguidor/Seguidora en Instagram',
  'Follower on Twitter': 'Seguidor/Seguidora en Twitter',
  Followers: 'Seguidores',
  Following: 'Siguiendo',
  'Following MSISDN - Platform combination is already selected':
    'La combinación de MSISDN a seguir y plataforma asociada ya está seleccionada',
  'Following on Instagram': 'Siguiendo en Instagram',
  'Following on Twitter': 'Siguiendo en Twitter',
  'Following pages': 'Siguiendo páginas',
  Followings: 'Seguidos',
  'For authorized use only': 'Para uso autorizado únicamente.',
  'For example': 'Por ejemplo',
  'For generating OTP codes, please download the Authenticator App':
    'Para generar códigos OTP, por favor, descargue la app Authenticator',
  Foreign: 'Extranjero',
  Found: 'Encontrado',
  'Found for': 'Encontrado para',
  France: 'Francia',
  Fraud: 'Fraude',
  'Free text': 'Texto libre',
  French: '',
  Frequency: 'Frecuencia',
  'Frequently communicated with #{receiverMsisdn}':
    'Mantuvo comunicación constante con #{receiverMsisdn}',
  'Frequently had consecutive communications with #{receiverMsisdn}':
    'Tuvo constante comunicación con #{receiverMsisdn}',
  'Frequently had consecutive communications with #{receiverMsisdn} within 1-hour time intervals,':
    'Tuvo constante comunicación con #{receiverMsisdn} dentro de intervalos de tiempo de 1 hora',
  Friday: 'Viernes',
  Friend: 'Amiga',
  'Friend relations': 'Relaciones de amigos',
  Friends: 'Amigos',
  From: 'Desde',
  'From - To': 'De - A',
  'From Date': 'A partir de la fecha',
  'Full Name': 'Nombre Completo',
  'Full Post': 'Publicación completa',
  'Full name': 'Nombre completo',
  'Full report': 'Reporte completo',
  'Full screen': 'Pantalla completa',
  'GEO query': 'Consulta GEO',
  GO: 'IR',
  Gabon: 'Gabón',
  Gambia: 'Gambia',
  'Gather more intel from MSISDN, email & social profiles':
    'Obtenga más información sobre MSISDN, correo electrónico y redes sociales.',
  'Gathering activity feed details':
    'Obteniendo detalles del historial de actividad',
  'Gathering activity patterns': 'Obteniendo patrones de actividad',
  'Gathering call analysis': 'Obteniendo análisis de llamadas',
  'Gathering call log common associates statistics':
    'Obteniendo estadísticas de contactos coincidentes en registros de llamada',
  'Gathering call log entry':
    'Recopilación de la entrada del registro de llamadas',
  'Gathering call log statistics':
    'Obteniendo estadísticas de reportes de llamada',
  'Gathering call log top associates statistics':
    'Obteniendo estadísticas de los principales asociados del registro de llamadas',
  'Gathering call logs report for':
    'Obteniendo reportes de registros de llamada para',
  'Gathering common locations': 'Obteniendo ubicaciones en común',
  'Gathering count analysis': 'Obteniendo count analysis',
  'Gathering data for target details':
    'Obteniendo datos para los detalles del perfil',
  'Gathering details for targets': 'Obteniendo información para los objetivos',
  'Gathering family relation': 'Obteniendo relación familiar',
  'Gathering images': 'Obteniendo imágenes',
  'Gathering locations': 'Obteniendo de ubicaciones',
  'Gathering social network details':
    'Obteniendo información de redes sociales',
  'Gathering social network top associates':
    'Obteniendo los principales asociados de la red social',
  'Gathering social word details': 'Obteniendo detalles de palabras sociales',
  'Gathering target summary report for':
    'Obteniendo el reporte del resumen de objetivo para',
  'Gathering top locations': 'Obteniendo ubicaciones principales',
  Gender: 'Género',
  Generate: 'Generar',
  'Generate pdf': 'Generar PDF',
  'Generating call logs report data':
    'Generando información del reporte de registros de llamada',
  'Generating map': 'Generando mapa',
  'Generating virtual ID': 'Generando ID virtuales',
  'Geo AoI': 'Geo AoI',
  'Geo Country': 'Geo País',
  'Geo Follow': 'Geo Seguir',
  'Geo Political Entities': 'Entidades Geopolíticas',
  'Geo Query': 'Geo Consulta',
  Geofencing: 'GeoCerca',
  'Geofencing canceled!': '¡Geocerca cancelada!',
  'Geoloc Knowledge Base': 'Fuente de Conocimiento Geoloc',
  Geolocate: 'Geolocalizar',
  'Geolocate phone': 'Geolocalizar teléfono',
  Geolocation: 'Geolocalización',
  'Geolocation details': 'Detalles de geolocalización',
  'Geolocation query successfully submitted':
    'Consulta de geolocalización enviada correctamente',
  'Geolocation scheduler will start after radical speech detection!':
    '¡La tarea de geolocalización comenzará después de la detección radical del habla!',
  Geolocations: 'Geolocalizaciones',
  'Geographic resolution': 'Resolución geográfica',
  Georgia: 'Georgia',
  Germany: 'Alemania',
  'Get Cell Level Location': 'Obtener ubicación a nivel torre',
  'Get Facebook Posts': 'Obtener publicaciones de Facebook',
  'Get Facebook Relations': 'Obtener relaciones en Facebook',
  'Get Photo Info': 'Obtener información de la foto',
  'Get activity alerts': 'Obtener alertas de actividad',
  'Get alerts for new posts by the target on social media':
    'Obtener alertas de nuevas publicaciones del objetivo en redes sociales',
  'Get current location of this MSISDN':
    'Obtener ubicación actual de este MSISDN',
  'Get historical data': 'Obtener historial',
  'Get historical locations': 'Obtener historial de ubicaciones',
  'Get information': 'Obtener información',
  'Get information & geolocate': 'Objetner información y geolocalizar',
  'Get sim': 'Obtener sim',
  'Get the CallerID': 'Obtener el número de la llamada',
  'Get the CallerID (4 credits)':
    'Obtener el número de la llamada (4 créditos)',
  'Get the Neighbour Cells': 'Buscar Antenas Cercanas',
  'Get the Neighbour Cells (1 credit)': 'Buscar Antenas Cercanas (1 crédito)',
  'Getting OTP': 'Obteniendo OTP',
  'Getting cookies': 'Obteniendo cookies',
  Ghana: 'Ghana',
  'GiO - GeoLocation Services - For exclusive usage by government law enforcement agency: Government of the State of Nuevo Leon - Backed up by EUC - 2017':
    'GiO - Servicio de Geolocalización - Para uso exclusivo de agencias policiacas de gobierno: Gobierno del Estado de Nuevo León - Respaldado por EUC - 2017',
  Gibraltar: 'Gibraltar',
  Go: 'Ir',
  'Go to call logs page': 'Ir a la página de registros de llamada',
  'Go to target page': 'Ir a la página de destino',
  'Got it': 'Entendido',
  Graph: 'Gráfica',
  'Graph settings': 'Configuración de gráfica',
  Greece: 'Grecia',
  Greenland: 'Groenlandia',
  Grenada: '',
  Group: 'Grupo',
  'Group By': 'Agrupar Por',
  'Group ID': 'ID de grupo',
  'Group Member on Facebook': 'Miembro del grupo en Facebook',
  'Group Name': 'Nombre del grupo',
  'Group created successfully!': '¡Grupo creado con éxito!',
  'Group details': 'Info. de Grupos',
  'Group edited successfully!': '¡Grupo editado con éxito!',
  'Group has been successfully deleted': 'El grupo ha sido eliminado con éxito',
  'Group has not been created': 'El grupo no ha sido creado',
  'Group has not been deleted': 'El grupo no ha sido eliminado',
  'Group has not been edited': 'El grupo no ha sido editado.',
  'Group members': 'Miembro del grupo',
  'Group messages': 'Mensajes a grupos',
  'Group results': 'Agrupar resultados',
  Groups: 'Grupos',
  Guatemala: 'Guatemala',
  Guernsey: 'Guernsey',
  Guinea: 'Guinea',
  'Guinea-Bissau': 'Guinea-Bisáu',
  Guyana: 'Guyana',
  'Hair Color': 'Color de cabello',
  Haiti: 'Haití',
  'Has Expired Avatar': 'El Avatar ha expirado',
  Hashtag: 'Hashtag',
  'Hashtag added successfully!': '¡Hashtag añadido con éxito!',
  'Hashtag has not been added': 'Hashtag no ha sido añadido',
  Hashtags: 'Hashtags',
  Hearts: 'Corazones',
  Heatmap: 'Mapa de calor',
  'Heatmap was activated': 'Mapa de calor activado',
  Height: 'Altura',
  'Hide archived': 'Ocultar archivados',
  'Hide descriptions': 'Ocultar descripciones',
  'Hide nearby Telegram users': 'Ocultar usuarios de Telegram cercanos',
  'Hide options': 'Ocultar opciones',
  'Hide query history': 'Ocultar historial de consultas',
  'Hide statistics': 'Ocultar estadísticas',
  Hierarchy: 'Jerárquico',
  'High activity': 'Alto actividad',
  'Highlight nodes based on number and type of direct connections':
    'Resaltar los nodos basados en el número y tipo de conexiones directas',
  'Highlight nodes that act as "bridges" between the connected network':
    'Resaltar nodos que actúen como "conexiones" entre la red',
  History: 'Historial',
  Home: 'Casa',
  'Home Location': 'Ubicación de Domicilio',
  'Home last sale date': 'Ultima fecha de venta de la casa',
  Hometown: 'Ciudad natal',
  Hometownn: '',
  Homicide: 'Homicidio',
  Honduras: 'Honduras',
  Hour: 'Hora',
  'Hour of day': 'Hora del día',
  'House number': 'Número de Casa ',
  Hungary: 'Hungría',
  ID: 'ID',
  IFE: 'IFE',
  IMEI: 'IMEI',
  'IMEI(s)': 'IMEI(s)',
  IMSI: 'IMSI',
  'IMSI(s)': 'IMSI(s)',
  IMSIs: 'IMSI',
  INFO: 'INFO',
  IP: 'IP',
  'IP found': 'IP encontrada',
  'IP Location': 'Ubicación de IP',
  'IP address': 'Dirección IP',
  'IP addresses': 'Dirección IP',
  'IP/Carrier': 'IP/Proveedor',
  'Find the area of the IP address': 'Encuentre el área de la dirección IP',
  'Get location of the IP': 'Obtener ubicación de la IP',
  'This method requires calling the device via instant messaging app, and as a result the person might become suspicious.':
    'Este método requiere llamar al dispositivo a través de la app de mensajería instantánea, y como resultado puede levantar sospechas. ¿Está seguro de qué desea continuar?',
  'Are you sure you want to continue?': 'Estas segura que quieres continuar?',
  Request: 'Solicitar',
  IPs: 'IPs',
  Iceland: 'Islandia',
  Id: 'Id',
  'Identification card number': 'Número de Tarjeta de Identificación ',
  'Identifying top profiles': 'Identificar perfiles principales',
  Idle: 'Inactivo',
  'If the status is N/A, yet you will have the option to enable the BOT to continue attempts.':
    'Si el estado es N/A, aún tendrá la opción de habilitar el BOT para continuar intentando.',
  'If you dont associated the correct MSISDN with platform account, the request could take up to 144 hours.':
    'Si no asocia el MSISDN correcto con la cuenta de la plataforma, la solicitud podría tomar hasta 144 horas.',
  'If you edit or delete the MSISDN the Call Log for this target will be removed':
    'Si edita o elimina el MSISDN, se eliminará el registro de llamadas para este objetivo.',
  'If you lost your device please reset the 2-Step Verification by pressing the reset button. An email will be sent to guide you to add a new device.':
    'Si perdió su dispositivo, reinicie la verificación en dos pasos presionando el botón de reinicio. Se enviará un correo electrónico para guiarlo a agregar un nuevo dispositivo.',
  Image: 'Imagen',
  'Image Analysis': 'Análisis de Imagen',
  'Image attached': 'Imágen adjunta',
  Images: '',
  Impersonation: 'Suplantación',
  Import: 'Importación',
  'Import Historical Locations': 'Importe de historial de ubicaciones',
  'Import Virtual ID by credentials': 'Importe Avatares usando credenciales',
  'Import files': 'Importar archivos',
  Imported: 'Importado',
  imported: 'importado',
  'Imported date': 'Imported date',
  'Imported successfully': 'Importado correctamente',
  Importing: 'Importando',
  'Importing data': 'Importando datos',
  'Importing historical data': 'Importación de datos históricos',
  'Imsi query': 'Consulta usando IMSI',
  'In SMS': 'En SMS',
  'In call': 'En llamada',
  'In order to strengthen account security, Two Factor Authentication (2FA) is mandatory for all users.':
    'Para fortalecer la seguridad de la cuenta, la autenticación de dos factores (2FA) es obligatoria para todos los usuarios.',
  'In progress': 'En progreso',
  Incoming: 'entrante',
  'Incoming SMS': 'SMS entrantes',
  'Incoming and Outgoing': 'Entrantes y Salientes',
  'Incoming call': 'Llamada entrantes',
  'Incoming sms': 'SMS entrantes',
  India: 'India',
  Individual: 'Individuales',
  Indonesia: 'Indonesia',
  Indonesian: '',
  Industries: 'Industrias',
  Industry: 'Industria',
  'Inform Security Personel': 'Informar al Personal de Seguridad',
  'Information available': 'información disponible',
  'Infrastructure and buildings': 'Infraestructura y Construcción',
  'Initialising search for': 'Iniciando búsqueda por',
  'Insert IMSI': 'Insertar IMSI',
  'Insert MSISDN': 'Insertar MSISDN',
  'Insert a single or multiple MSISDNs or IMSIs separated by comma':
    'Inserte uno o varios MSISDN o IMSI separados por comas',
  'Insert phones': 'Ingresar números de teléfono',
  Inserted: 'Insertados',
  'Inside of area': 'Dentro del área',
  'Inside the circle': 'Dentro del círculo',
  'Insights about most common and top associates':
    'Estadísticas sobre contactos principales y más comunes',
  Instagram: 'Instagram',
  'Instagram handle': 'Instagram',
  'Instant messaging': 'Mensajería instantánea',
  'Related instant messaging apps':
    'Apps de Mensajería Instantánea Relacionadas',
  Institution: 'Institución',
  'Intel Query': 'Intel Consulta',
  'Intel Search Results': 'Resultados de la Búsqueda de Inteligencia',
  'Intel search': 'Búsqueda de inteligencia',
  'Intellectus Knowledge Base': 'Fuente de Conocimiento Intellectus',
  Intelligence: 'Inteligencia',
  'Intelligence Query': 'Consulta de Inteligencia',
  'Intelligence is not available yet': 'La inteligencia aún no está disponible',
  'Intelligence query': 'Consulta de inteligencia',
  'Intelligence search': 'Búsqueda de inteligencia',
  'Intelligence suggestions': 'Sugerencias de inteligencia',
  'Interacted with': 'Interactuó con',
  'Interactions/ Relations': 'Interacciones/ Relaciones',
  'Interested in': 'Interesado en',
  Invalid: 'Inválido',
  'Invalid IMEI provided': 'IMEI inválido',
  'Invalid MSISDN': 'MSISDN no válido',
  'Invalid OTP code provided': 'Codigo OTP inválido',
  'Invalid URL. Social network not supported':
    'URL invalida. Red social no soportada',
  'Invalid credentials provided': 'Credenciales inválidas',
  'Invalid email address': 'Dirección de correo electrónico invalida',
  'Invalid file format provided.':
    'Formato de archivo proporcionado no válido.',
  'Invalid location': 'Ubicación inválida',
  'Invalid relationship type': 'Tipo de relación no válido',
  'Invalid type - allowed only png, jpg, jpeg':
    'Tipo de archivo inválido - sólo se permite png, jpg, jpeg',
  'Invalid values. Please fill in all fields.':
    'Valores no válidos. Por favor, complete todos los campos.',
  'Invalid values. Please try again.':
    'Valores inválidos. Por favor, intente de nuevo.',
  'Invalid values. Please try again. Username must contain at least one letter.':
    'Valores inválidos. Inténtalo de nuevo. El nombre de usuario debe contener al menos una letra.',
  Investigate: 'Investigar',
  'Investigate lead': 'Pista de investigación',
  Investigation: 'Investigación',
  'Investigation filters': 'Filtros de Investigación',
  Investigations: 'Investigaciones',
  Iran: 'Irán',
  Iraq: 'Irak',
  Ireland: 'Irlanda',
  'Is dead': 'Fallecido',
  'Is followed by': 'Es seguido por',
  'Is following': 'Está siguiendo',
  'Is/was working as': 'Está / estaba trabajando como',
  'Isle of Man': 'Isla de Man',
  Israel: 'Israel',
  Italy: 'Italia',
  'Item matched': 'Elemento coincidente',
  Items: 'Artículos',
  'Items per page': 'Elementos por página',
  Jamaica: '',
  January: 'enero',
  Japan: 'Japón',
  Jersey: 'Jersey',
  Job: 'Trabajo',
  'Job(s)': 'Trabajos',
  Jobs: 'Trabajos',
  'Join group': 'Unirse a grupo',
  'Join random or specific communities on the selected social network.':
    'Unirse a comunidades específicas o aleatorias en la red social seleccionada.',
  'Joined At': 'Se unió el',
  'Joined group': 'Unió a un grupo',
  'Joining groups': 'Uniéndose a grupos',
  Jordan: 'Jordania',
  July: 'julio',
  June: 'junio',
  Kazakhstan: 'Kazajistán',
  'Keep in mind that you can make changes later if needed.':
    'Tome en cuenta que pude realizar cambios más adelante en caso de requerirlo.',
  Kenya: 'Kenia',
  Keyword: 'Palabra Clave',
  'Keyword lists': 'Listas de palabras clave',
  Keywords: 'Palabras Clave',
  'Keywords list': 'Lista de palabras clave',
  'Keywords monitored': 'Palabras clave monitoreadas',
  Kidnapping: 'Secuestro',
  Kiribati: '',
  'Knowledge Base': 'Fuente de Conocimiento',
  'Knowledge base': 'Fuente de conocimiento',
  Kosovo: '',
  Kuwait: 'Kuwait',
  Kyrgyzstan: 'Kirguistán',
  LABELS: 'ETIQUETAS',
  LAC: 'LAC',
  'LAC found': 'LAC encontrada',
  'Location Area Code': 'Código de Localización de Área',
  LANGUAGE: 'IDIOMA',
  LOCATION: 'Localización',
  LOCATIONS: 'LUGARES',
  'LOG IN': 'INICIAR SESIÓN',
  Labels: 'Etiquetas',
  Landline: 'Línea Fija',
  'Landline(s)': 'Línea(s) Fija(s)',
  Landlines: 'Líneas fijas',
  Language: 'Idioma',
  Languages: 'Idiomas',
  Laos: 'Laos',
  'Last 24 hours': 'Últimas 24 horas',
  'Last 30 days': 'Últimos 30 días',
  'Last 7 days': 'Últimos 7 días',
  'Last 90 days': 'Últimos 90 días',
  'Last Activity': 'Útima vez visto',
  'Last Hour': 'Ultima hora',
  'Last Modified': 'Última modificación',
  'Last Seen': 'Visto por última vez',
  'Last activity': 'Última actividad',
  'Last call log location': 'Ubicación del registro de la última llamada',
  'Last hour': 'Última hora',
  'Last known activity': 'Última actividad conocida',
  'Last known location': 'Última ubicación conocida',
  'Last message': 'Último mensaje',
  'Last located': 'Ultima vez ubicado',
  'Last modifications': 'Últimas modificaciones',
  'Last modified': 'Última modificación',
  'Last name': 'Apellido',
  'Last page': 'Última página',
  'Last query': 'Última consulta',
  'Last seen': 'Visto por última vez',
  'Last seen online': 'Visto en línea por última vez',
  'Last week': 'Semana pasada',
  Lat: 'Latitud',
  'Lat / Lon': 'Lat / Lon',
  'Lat/Lon': 'Lat/Lon',
  Latest: 'Último',
  'Latest Location': 'Última Ubicación',
  'Latest activity': 'Actividad más reciente',
  'Latest search': 'Última búsqueda',
  'Latest search date': 'Última fecha de búsqueda',
  'Latest Search Date': 'Última fecha de Búsqueda',
  'Latest status': 'Último estado',
  'Latest query': 'Último consulta',
  Latitude: 'Latitud',
  Latvia: 'Letonia',
  Law: 'Ley',
  Layout: 'Diseño',
  Lebanon: 'Líbano',
  Ledger: 'Gestión de la base de datos',
  Lens: 'Lente',
  Lesotho: 'Lesoto',
  Level: 'Nivel',
  List: 'Lista',
  'List of searches': 'Lista de búsquedas',
  'List of searches for': 'Lista de búsquedas para',
  Liberia: 'Liberia',
  Libya: 'Libia',
  'License Plate': 'Placa',
  'License Plate(s)': 'Matrículas',
  'License Plates': 'Placas Vehiculares',
  Liechtenstein: '',
  'Like group posts': 'Dar “Me gusta” a publicaciones de grupos',
  'Like posts': 'Dar “Me gusta”',
  Liked: 'Le gusta',
  Likers: 'Me gusta',
  'Liking posts': 'Dándo Me Gusta a publicaciones',
  'Lines parsed': 'Líneas analizadas',
  Link: 'Enlace',
  'Link Analysis': 'Análisis de enlaces',
  'Link analysis': 'Análisis de enlaces',
  'Link analysis v2': 'Análisis de enlaces v2',
  Linkedin: 'Linkedin',
  'Linkedin Employee': 'Empleado/a de Linkedin',
  Links: 'Enlaces',
  'List View': 'Vista de la lista',
  'List name': 'Nombre de la lista',
  'List of all cases': 'Lista de todos los casos',
  'List of all imported call detail records':
    'Lista de todos los registros de detalle de llamada importados',
  'List of all targets': 'Lista de todos los objetivos',
  'List of imeis': 'Lista de IMEI',
  'List of imsis': 'Lista de IMSI',
  'List of msisdns': 'Lista de MSISDN',
  'List of periods': 'Lista de periodos',
  Lithuania: 'Lituania',
  'Live Session': 'Sesión en vivo',
  'Live Session has been successfully obtained':
    'La sesión en vivo se ha obtenido con éxito',
  'Live data': 'Datos en tiempo real',
  'Live monitoring': 'El monitoreo en tiempo real',
  'Live monitoring started at': 'El monitoreo en tiempo real empezó el',
  'Live session request created successfully!':
    '¡Solicitud de sesión en vivo creada con éxito!',
  'Live tasks': 'Tareas activas',
  'Load a case': 'Cargar caso',
  'Load a target': 'Cargar objetivo',
  'Load selected data': 'Cargar información seleccionada',
  Loading: 'Cargando',
  'Loading call log data': 'Cargando datos del registro de llamadas',
  'Loading data': 'Cargando información',
  'Loading target details': 'Cargando detalles del objetivo',
  'Loading the file could not be completed.':
    'La carga del archivo no pudo ser completada',
  Locate: 'Localizar',
  'Locate a single MSISDN or IMSI': 'Localice un único MSISDN o IMSI',
  'Locate all': 'Ubicar todo',
  'Locate now': 'Ubicar',
  'Locate phone': 'Ubicar teléfono',
  Located: 'Localizados',
  'Located at': 'Ubicado en',
  Locating: 'Localizando',
  Location: 'Localización',
  'Location copied!': '¡Ubicación copiada!',
  'Location Heatmap': 'Mapa de calor de ubicación',
  'Location History Found': 'Historial de Ubicación Encontrado',
  'Location History Not Found': 'Historial de Ubicación No Encontrado',
  'Location History Request': 'Solicitud de Historial de Ubicación',
  'Location Info': 'Información de ubicación',
  'Locate Now': 'Ubicar ahora',
  'Location Probalility': 'Probabilidad de ubicación',
  'Location Sources': 'Fuentes de Ubicación',
  'Location Timeline': 'Cronología de ubicación',
  'Location belongs to the destination': 'La ubicación se refiere al destino',
  'Location belongs to the source': 'La ubicación se refiere a la fuente',
  'Location found': 'Ubicación encontrada',
  'Location history': 'Historial de ubicaciones',
  'Location log': 'Registro de ubicación',
  'Location probability': 'Probabilidad de ubicación',
  'Location sources': 'Fuente de ubicaciones',
  Locations: 'Lugares',
  'Locations only': 'Sólo ubicaciones',
  Lock: 'Bloquear',
  'Lock Temporary User': 'Bloquear usuario temporal',
  'Lock user': 'Bloquear usuario',
  Log: 'Registro',
  'Log on': 'Conexión',
  'Log on task': 'Registrar una tarea',
  'Logged in': 'Sesión iniciada',
  'Logging in': 'Inicio de sesión',
  Login: 'Iniciar sesión',
  'Login Now': 'Inicie Sesión Ahora',
  'Login status': 'Estado de sesión',
  Logon: 'Conexión',
  Logout: 'Cerrar sesión',
  Lon: 'Longitud',
  Longest: 'Más larga',
  'Longest call': 'Llamada más larga',
  Longitude: 'Longitud',
  'Look for a country, city or address': 'Busque un país, ciudad o dirección',
  'Low activity': 'Bajo actividad',
  Luxembourg: 'Luxemburgo',
  MAPS: 'MAPAS',
  MCC: 'MCC',
  METADATA: 'METADATOS',
  MNC: 'MNC',
  MNOs: 'Operador movil',
  'MOBILE NETWORK': 'Red móvil',
  MSISDN: 'MSISDN',
  'MSISDN is connected with this instant messaging profile':
    'El MSISDN está conectado con este perfil de mensajería instantánea',
  'MSISDN is not linked with a WhatsApp account': '',
  'MSISDN is not linked with a whatsApp account':
    'MSISDN no está vinculado con una cuenta de whatsApp',
  'MSISDN(s)': 'MSISDN(s)',
  MSISDNs: 'Números de teléfono',
  Macedonia: 'República de Macedonia',
  Madagascar: 'Madagascar',
  Major: 'Mayor',
  'Make sure that the file is in Zip format and contains a JSON file':
    'Asegúrese de que el archivo esté en formato zip y contiene un archivo JSON',
  Malawi: 'Malaui',
  Malaysia: 'Malasia',
  Maldives: 'Maldivas',
  Male: 'Hombre',
  Mali: 'Malí',
  Malta: 'Malta',
  Manage: 'Administrar',
  'Management for a new case is free of charge for #{days} days':
    'La gestión de un caso nuevo es gratuita durante #{days} días.',
  'Management for a new target is free of charge for #{days} days':
    'La gestión de un nuevo objetivo es gratuita durante #{days} días.',
  Manager: 'Administrar',
  Map: 'Mapa',
  'Map View': 'Vista del mapa',
  'Map circle': 'Círculo de mapa',
  'Map default countries': 'Mapa de países predeterminados',
  Maps: 'Mapas',
  March: 'marzo',
  'Marital Status': 'Estado civil',
  'Mark all as read': 'Marcar todo como leído',
  'Marshall Islands': 'Islas Marshall',
  Mauritania: 'Mauritania',
  Mauritius: 'Mauricio',
  Maximize: 'Maximizar',
  'Maximize map': 'Ampliar el mapa',
  Maximum: 'Máxima',
  'Maximum allowed size:': 'Tamaño máximo permitido:',
  'Maximum amount of robot queries is':
    'La cuota máxima de consultas del robot es de',
  'Maximum credits assigned': 'Créditos máximos asignados',
  'Maximum credits available': 'Créditos máximos disponibles',
  'Maximum image size is 2MB.': 'El tamaño máximo de la imagen es de 2MB.',
  'Maximum image size is 2MB. Please choose another image.':
    'El tamaño máximo de la imagen es de 2MB. Por favor, seleccione otra imagen.',
  'Maximum number of targets has been reached':
    'Ha alcanzado el número máximo de objetivos',
  'Maximum quota': 'Cuota máxima',
  'Maximum quota assigned': 'Cuota máxima asignada',
  'Max limit reached': 'Límite máximo alcanzado',
  May: 'mayo',
  Media: 'Media',
  'Medical condition': 'Condición Médica',
  Medium: 'Medio',
  'Meeting Points': 'Puntos de encuentro',
  'Meeting points': 'Puntos de encuentro',
  Members: 'Miembros',
  Mentions: 'Menciones',
  Message: 'Mensaje',
  'Message to': 'Envío mensaje a',
  Messages: 'Mensajes',
  Method: 'Método',
  Mexico: 'México',
  'Middle name': 'Segundo nombre',
  Minimize: 'Minimizar',
  'Minimize map': 'Minimizar mapa',
  Minor: 'Menor',
  Miscellaneous: 'Varios',
  'Miscellaneous ': 'Varios',
  'Missing CDRs for this target': 'Faltan CDRs para este objetivo',
  'Missing file to upload': 'Falta archivo para cargar',
  'Mission is empty. Click the': 'La Misión está vacía. Haga clic',
  'Mobile Data': 'Datos Móviles',
  'Mobile Network': 'Red móvil',
  'Mobile network': 'Red móvil',
  'Mobile Operator': 'Operador Móvil',
  Model: 'Modelo',
  Moldova: 'Moldavia',
  Monaco: 'Mónaco',
  Monday: 'Lunes',
  Money: 'Dinero',
  Mongolia: 'Mongolia',
  'Monitor changes': 'Monitorear cambios',
  Monitoring: 'Vigilancia',
  'Monitoring is': 'El monitoreo es',
  Montenegro: 'Montenegro',
  'Month / Year first': '',
  'Month of year': 'Mes del año',
  'Monthly credits assigned': 'Créditos mensuales asignados',
  'Monthly credits available': 'Créditos mensuales disponibles',
  'Monthly quota': 'Cuota mensual',
  'Monthly quota assigned': 'Cuota mensual asignada',
  Montserrat: 'Montserrat',
  More: 'Más',
  'More filters': 'Más filtros',
  'More actions for': 'Más acciones para',
  Morocco: 'Marruecos',
  'Most Active': '',
  'Most Common Relations': 'Relaciones en Común',
  'Most active period': 'Período más activo',
  'Most common associates': 'Asociados más comunes',
  'Most common faces': 'Caras más comunes',
  'Most inactive period': 'Periodo menos activo',
  'Most recent': 'Más reciente',
  'Most relevant': 'Lo más relevante',
  'Mostly active': 'Más activo',
  'Mostly inactive': 'Más inactivo',
  'Mothers name': 'Apellido Materno',
  Mozambique: 'Mozambique',
  Multiple: 'Múltiple',
  'Multiple cases': 'Múltiples casos',
  'Multiple profile searches': 'Búsqueda de múltiples perfiles',
  'Must be at least 1 character long and maximum characters allowed are 255, special charactes and preceding or following spaces are not allowed':
    'La longitud deberá ser de al menos 1 caractere y un máximo de 255, no están permitidos caracteres especiales ni seguido o precedido por espacios',
  'Must be at least 10 characters long and special characters are not allowed':
    'Debe contener al menos 10 caracteres de largo y no se permiten caracteres especiales',
  'Must be at least 3 characters long and maximum characters allowed are 15, special charactes are not allowed':
    'Debe contener un mínimo de 3 caracteres de largo y un máximo de 15, los caracteres especiales no están permitidos',
  'Must be at least 3 characters long and maximum characters allowed are 20, special charactes and preceding or following spaces are not allowed':
    'La longitud deberá ser de al menos 3 caracteres y un máximo de 20, no están permitidos caracteres especiales ni seguido o precedido por espacios',
  'Must be at least 3 characters long, special charactes and preceding or following spaces are not allowed':
    'La longitud deberá ser de al menos 3 carácteres, no están permitidos caracteres especiales ni seguido o precedido por espacios.',
  Muted: 'Silenciados',
  'Mutual Facebook Friends': 'Amigos Comunes en Facebook',
  'My details': 'Mi información',
  'My places': 'Mis lugares',
  'My target': 'Mi objetivo',
  Myanmar: 'Myanmar',
  NIK: 'NIK',
  'N/A': 'N/A',
  Name: 'Nombre',
  'Name Middle Surname': 'Nombre Segundo Apellido',
  'Name Surname': 'Nombre Apellido',
  'Name cannot be just whitespaces':
    'El nombre no puede ser solo espacios en blanco',
  'Name is required': 'Se requiere el nombre',
  'Name must contain at least 3 characters':
    'El nombre debe contener al menos 3 caracteres',
  'Name of the area': 'Nombre del Área',
  'Name(s)': 'Nombre(s)',
  Names: 'Nombres',
  Namibia: 'Namibia',
  Nationalities: 'Nacionalidades',
  Nauru: '',
  'Nearest Neighbors': 'Vecinos cercanos',
  Negative: 'Negativo',
  'Neighbour cell towers with params': 'Torres cercanas con parámetros',
  Nepal: 'Nepal',
  Neqative: 'Neqativo',
  Network: 'Red',
  'Network(s)': 'Red(es)',
  'Network N/A': 'Red N/A',
  'Never located': 'Nunca ubicado',
  Neuter: 'Neutro',
  Neutral: 'Neutral',
  'New Credits Usage Statistics Dashboard':
    'Nuevo Tablero de Estadísticas de Uso de Créditos',
  'New Discovery': 'Nuevo Descubrimiento',
  'New MSISDN': 'Nuevo MSISDN',
  'New Password': 'Nueva Contraseña',
  'New Query': 'Nueva Consulta',
  'New Zealand': 'Nueva Zelanda',
  'New call log': 'Nuevo registro de llamadas',
  'New case': 'Nuevo caso',
  'New entity': 'Nueva entidad',
  'New intel query': 'Nueva consulta de inteligencia',
  'New investigation': 'Nueva investigación',
  'New note text': 'Nueva nota de texto',
  'New password': 'Nueva contraseña',
  'New post': 'Nueva publicación',
  'New query': 'Nueva consulta',
  'New results available': 'Nuevos resultados disponibles',
  'New target': 'Nuevo objetivo',
  'New user #{username} created successfully!':
    '¡Nuevo usuario #{username} creado con éxito!',
  'New virtual ID': 'Nuevo ID virtual',
  'New virtual ID ': 'Nuevo ID virtuales',
  Next: 'Siguiente',
  'Next page': 'Siguiente página',
  'Next step': 'Próximo paso',
  Nicaragua: 'Nicaragua',
  Niger: 'Níger',
  Nigeria: 'Nigeria',
  Niue: 'Niue',
  No: 'No',
  'No data available for heatmap': 'No hay datos para el mapa de calor',
  'No Data': 'Sin datos',
  'No Data Available': 'Datos no disponibles',
  'No Location found for trilateration query!':
    'No se encontró ubicación para la consulta de triangulación!',
  'No Results': 'No hay resultados',
  'No records found': 'No se encontraron registros',
  'No activity': 'Ninguna actividad',
  'No activity log for this avatar':
    'No hay registro de actividades para este avatar',
  'No archived recommandations available':
    'No hay recomendaciones archivadas disponibles',
  'No area is defined on the map.': 'No hay área definida en el mapa.',
  'No available browsers. Please retry':
    'No hay navegadores disponibles. Por favor, intente de nuevo',
  'No available data': 'Sin datos disponibles',
  'No available data for this section yet':
    ' Aún no hay información disponible para esta sección',
  'No call log found for this target. Please use the menu to request or upload a call log.':
    'No se encontraron registros de llamadas para este objetivo. Utilice el menú para solicitar o cargar un registro de llamadas.',
  'No cases defined': 'No hay casos definidos',
  'No conversations': 'Sin conversaciones',
  'No credits': 'Sin créditos',
  'No credits assigned': 'Sin créditos asignados',
  'No crypto transactions': 'Sin transacciones crypto',
  'No data': 'Sin datos',
  'No data available': 'Datos no disponibles',
  'No data yet. Import your files or run a Somedus query for Telegram to see results.':
    'Aún no hay información. Importe un archivo o ejecute una consulta Somedus para ver resultados de Telegram',
  'No data. Make sure you have seeds and click "Enhance profile"':
    'Sin información. Asegurate de tener semillas y haz clic "Mejorar perfil"',
  'No date available': 'Fecha no disponible',
  'No date provided': 'Sin fecha',
  'No entries': 'No hay resultados',
  'No groups': 'No hay grupos',
  'No image available': 'Imagen no disponible',
  'No items added': 'No se agregaron elementos',
  'No keyword lists': 'Sin listas de palabras clave',
  'No keyword lists created': 'No se crearon listas de palabras clave',
  'No keywords': 'Sin palabras clave',
  'No lists selected': 'No hay listas seleccionadas',
  'No location': 'Sin ubicación',
  'No location data': 'Sin datos ubicación',
  'No location found': 'No se encontró ubicación',
  'No location found for Telegram users!':
    '¡No se encontró ninguna ubicación para los usuarios de Telegram!',
  'No locations found for the target <br> Add seeds to retrieve more information':
    'No se encontraron ubicaciones para este objetivo <br> Agregue semillas para obtener más información',
  'No locations found for the target <br> Click "#{Request call log}" to retrieve locations':
    'No se encontraron ubicaciones para este objetivo <br> Haga clic en “#{Request call log}” para obtener ubicaciones',
  'No locations found for the target <br> Click "Locate" or "#{Request call log}" to retrieve locations':
    'No se encontraron ubicaciones para este objetivo <br> Haga clic en "Localizar" o en “#{Request call log}” para obtener ubicaciones',
  'No locations were found for': 'No se encontraron ubicaciones para',
  'No matches found': 'No se encontraron resultados',
  'No more than 1024 characters long': 'No más de 1024 caracteres de largo',
  'No more than 255 characters long': 'No más de 255 caracteres de largo',
  'No new suggestions available': 'No hay nuevas sugerencias disponibles',
  'No phone links defined': 'No hay enlaces telefónicos definidos',
  'No places': 'Sin lugares',
  'No platforms were found. Please select the platform from the list bellow.':
    'No se encontraron plataformas. Por favor, seleccione la plataforma de la lista a continuación.',
  'No queries': 'Sin consultas',
  'No recommandations available': 'No hay recomendaciones disponibles',
  'No results found': 'No se han encontrado resultados',
  'No seeds were found. The ExtractusD will fetch information related to Target MSISDN(s)':
    'No se encontraron semillas. ExtractusD buscará la información relacionada al (los) MSISDN del objetivo',
  'No selected list': 'No hay lista seleccionada',
  'No social data for current targets!':
    'No hay datos disponibles para los objetivos actuales',
  'No statistics available for the selected period.':
    'No hay estadísticas disponibles para el período seleccionado.',
  'No suggestions available': 'No hay sugerencias disponibles',
  'No targets': 'Sin objetivos',
  'No targets added.': 'No se agregaron objetivos.',
  'No targets defined': 'No hay objetivos definidos',
  'No targets selected!': 'Ningun objetivo ha sido seleccionado',
  'No transactions found': 'No se encontraron transacciones',
  'No transactions were made in the selected dates':
    'No se realizaron transacciones en las fechas seleccionadas',
  'No valid MSISDN': 'MSISDN no válido',
  'No virtual ID match your criteria':
    'Ningún ID virtuales coincidió con sus criterios',
  'No, I want to continue': 'No, deseo continuar',
  'No, remove address from account': 'No, remover dirección de la cuenta',
  'No, thank you': 'No, gracias',
  'No. of calls': 'llamadas',
  'No. of wallets': 'No. de billeteras',
  Nodes: 'Nodos',
  Non: 'Ninguno',
  'Non Geo Political Entities': 'Entidades no Geopolíticas',
  'Non expiring': 'Sin expiración',
  None: 'Ninguno',
  'North Korea': 'Corea del Norte',
  Norway: 'Noruega',
  'Not Located': 'No Localizados',
  'Not active': 'No activo',
  'Not available at the moment': 'Por el momento, no disponible',
  'Not enough credits': 'Sin créditos suficientes',
  'Not enough credits for this request':
    'No cuenta con los créditos suficientes para esta solicitud',
  'Not enough geolocation data or call logs data to predict locations. Would you like to create a schedule?':
    'No hay suficientes datos de geolocalización o datos de registros de llamadas para predecir ubicaciones. ¿Le gustaría crear un horario?',
  'Not enough information': 'No hay suficiente información',
  'Not now': 'Ahora no',
  'Not available': 'No disponible',
  Note: 'Nota',
  'Note changed': 'Cambiaron las notas',
  'Note created successfully!': '¡Nota creada con éxito!',
  'Note has not been created': 'La nota no ha sido creada',
  'Note was added': 'Nota agregada',
  'Note: Please leave input box empty to remove current hashtag':
    'Nota: Por favor, deje el cuadro de entrada vacío para eliminar el hashtag actual',
  'Note: Time periods are not available for Upload call logs ':
    'Nota: Los periodos de tiempo no están disponible para la carga de Registros de Llamada',
  Notes: 'Notas',
  Notifications: 'Notificaciones',
  'Notify me on Updates': 'Notificarme sobre Actualizaciones',
  'Notify me when there is a': 'Notificarme sobre',
  November: 'noviembre',
  'Number most contacted': 'Número más contactado',
  'Number most received': 'Número más recibido',
  'Number of sessions': 'Número de sesiones',
  Numerical: 'Números',
  OFF: 'Apagado',
  OFFLINE: 'FUERA DE LÍNEA',
  OK: 'OK',
  ON: 'Encendido',
  ONLINE: 'EN LÍNEA',
  OPTIONS: 'OPCIONES',
  OSINT: 'OSINT',
  'OSINT completed successfully': 'OSINT completado con éxito',
  OTP: 'OTP',
  'OTP - One Time Password': 'OTP - Contraseña de una sola vez',
  'One Time Password': 'Contraseña de una sola vez',
  'OTP Channel': 'Canal de OTP',
  'OTP code': 'Código OTP',
  'OTP configuration completed': 'Configuración de OTP completada',
  'OTP required': 'Se requiere OTP',
  'OVERVIEW ': 'RESUMEN',
  Object: 'objeto',
  October: 'octubre',
  Okay: 'Okey',
  Oman: 'Omán',
  'Once deleted the data cannot be restored':
    'Una vez eliminados, los datos no se pueden restaurar',
  'Once deleted, the cases cannot be restored.':
    'Una vez eliminados, los casos no se pueden restaurar.',
  'Once removed the data cannot be restored':
    'Una vez removido, la información no puede ser restaurada',
  'Once the infrastructure is up, it will take our BOTs up to':
    'Una vez que la infraestructura esté funcionando, a nuestros BOTs les tomará hasta',
  One: 'Una',
  'One successful Advanced OSINT': 'Una exitosa OSINT Avanzado',
  'One successful Intel Search': 'Una exitosa Búsqueda de inteligencia',
  'One successful call log request for #{type} #{value} for the period of #{duration} months':
    'Solicitud de CDR satisfactoria para el #{type} #{value} para el periodo de #{duration} meses',
  'One successful import cdr for #{type} #{value}':
    'Una exitosa Carga de CDR #{type} por #{type} #{value}',
  Online: 'En línea',
  'Only lowercase letters, numbers, dot (.) and underscore (_).':
    'Solo letras minúsculas, números, punto (.) Y guión bajo (_).',
  'Only xlsx or csv files are supported':
    'Sólo están soportados archivos xlsx o csv',
  'Open URL': 'Abrir URL',
  'Open Web': 'Web Abierta',
  'Open in a new tab': 'Abrir en una pestaña nueva',
  'Open in new page': 'Abrir en una nueva página',
  'Open in website': 'Abrir en sitio web',
  'Open remote view': 'Abrir Vista Remota',
  'Open target profile': 'Abrir perfil de destino',
  'Opening browser': 'Abriendo navegador',
  Operator: 'Operador',
  'Opt-out': 'Desuscribirse',
  Options: 'Opciones',
  Ordinal: 'Ordinal',
  Org: 'Org',
  Organic: 'Orgánico',
  Organization: 'Organización',
  Organizations: 'Organizaciones',
  Origin: 'Origen',
  'Origin Position': 'Posición de origen',
  'Origin position': 'Posición de origen',
  Other: 'Otro',
  'Other info': 'Otra información',
  'Other recovery accounts': 'Otras cuentas de recuperación',
  'Other relations': 'Otras relaciones',
  'Other social profiles': 'Otros perfiles sociales',
  'Other sources': 'Otras fuentes',
  Others: 'Otros',
  'Otherwise, your progress will not be saved':
    'De lo contrario su progreso no será guardado',
  'Otp code obtained. Confirming facebook account registration':
    'Código Otp obtenido. Confirmando el registro de la cuenta de Facebook',
  'Out SMS': 'SMS de salida',
  'Out call': 'Llamada de salida',
  Outgoing: 'saliente',
  'Outgoing SMS': 'SMS salientes',
  'Outgoing call': 'Llamada salientes',
  'Outgoing sms': 'SMS salientes',
  'Outside of area': 'Fuera del área',
  'Outside the circle': 'Fuera del círculo',
  Overall: 'en total',
  'Overall records': 'Registros generales',
  Overview: 'Visión de conjunto',
  Own: 'Propio',
  Owner: 'Propietario',
  'Owner id': 'Id Propietario',
  'PAGES WITH MATCHING IMAGES': 'PÁGINAS CON COINCIDENCIAS DE IMAGENES',
  'PARTIALLY MATCHING IMAGES': 'COINCIDENCIA PARCIAL DE IMÁGENES',
  PASSWORD: 'CONTRASEÑA',
  'PDF layout': 'Diseño del PDF',
  'PDF settings updated successfully!':
    '¡Configuración de PDF actualizada con éxito!',
  'POI type': 'Tipo de POI',
  'Page numbering': 'Numeración de página',
  Pakistan: 'Pakistán',
  Palau: '',
  Palestine: 'Palestina',
  Panama: 'Panamá',
  'Papua New Guinea': 'Papua-Nueva Guinea',
  Paraguay: 'Paraguay',
  'Parsing error lines': 'Analizando líneas de error',
  'Parsing errors': 'Análisis de errores',
  Password: 'Contraseña',
  'Password does not contain any digits.': 'La contraseña no contiene dígitos.',
  'Password does not contain lower case letters.':
    'La contraseña no contiene letras minúsculas.',
  'Password does not contain upper case letters.':
    'La contraseña no contiene letras mayúsculas.',
  'Password expired': 'La contraseña expiró',
  'Password must be at least 8 characters.':
    'La contraseña debe tener al menos 8 caracteres.',
  'Password provided was already used':
    'La contraseña proporcionada ya está en uso',
  'Password strength': 'Seguridad de la contraseña',
  "Passwords don't match.": 'Las contraseñas no coinciden.',
  'Passwords not provided': 'Contraseña no proporcionada',
  Past: 'Pasada',
  'Past tasks': 'Tareas pasadas',
  'Paste here the url for the platform': 'Pegue aquí la url de la plataforma',
  'Paste image URL': 'Copiar URL de imagen',
  'Paste profile URL': 'Pegar la URL del perfil',
  Peer: 'Llamado(a)',
  'Peer interactions': 'Interacciones',
  'Peer(s) locations': 'Ubicaciones de contactos',
  Pending: 'Pendiente',
  'Pending Request': 'Solicitud pendiente',
  'Pending call log': 'Solicitud de registro de llamadas',
  'Pending call log request!': '¡Solicitud de registro de llamadas pendiente!',
  'Pending request': 'Solicitud pendiente.',
  'Pending request. Click here to cancel':
    'Solicitud pendiente. Haga clic aquí para cancelar.',
  'Pending... Click to cancel': 'Pendiente ... Haga clic para cancelar',
  People: 'Personas',
  'People nearby': 'Gente cercana',
  Percent: 'Porcentaje',
  'Perform Location History': 'Realizar historial de ubicaciones',
  'Perform a follow query for the selected rows':
    'Realizar una consulta de seguimiento para las filas seleccionadas',
  'Perform a location history query for the selected rows':
    'Realizar una consulta del historial de ubicaciones para las filas seleccionadas',
  'Perform a proximity query for the selected row':
    'Realizar una consulta de proximidad para la fila seleccionada',
  'Perform any of the OSINT Stage to retrieve important intelligence information':
    'Realice alguna de las consultas OSINT para obtener información importante de inteligencia',
  Period: 'Periodo',
  'Periods analyzed': 'Periodos analizados',
  'Permanently delete case': 'Eliminar permanentemente el caso',
  'Permanently delete investigation': 'Eliminar investigación permanentemente',
  'Permanently delete target': 'Eliminar permanentemente el objetivo',
  'Permanently remove target from case':
    'Remover el objetivo del caso permanentemente',
  'Permission denied': 'Permiso denegado',
  Person: 'Persona',
  'Persons tagged': 'personas etiquetadas',
  Peru: 'Perú',
  Philippines: 'Filipinas',
  Phone: 'Teléfono',
  'Phone Info': 'Información del teléfono',
  'Phone Model': 'Modelo de teléfono',
  'Phone Number': 'Número telefónico',
  'Phone Numbers': 'Números de teléfono',
  'Phone brand': 'Marca del teléfono',
  'Phone links': 'Enlaces telefónicos',
  'Phone model': 'Modelo de teléfono',
  'Phone models': 'Modelos de teléfono',
  'Phone models / IMEIs': 'Modelo de teléfono / IMEI',
  'Phone number': 'Número de teléfono',
  'Phone numbers': 'Números de teléfono',
  'Phone(s)': 'Telefono(s)',
  Photo: 'Foto',
  'Photo update time': 'Fecha de actualización de foto',
  Photos: 'Fotos',
  'Photos uploaded into facebook account. Filling account details':
    'Fotografías cargdas a la cuenta de Facebook. Completando los detalles de la cuenta',
  Picture: 'Foto',
  'Pin this search': 'Fijar esta búsqueda',
  'Pitcairn Islands': '',
  Place: 'Sitio',
  'Place Of Birth': 'Lugar de nacimiento',
  'Place created successfully!': 'Lugar creado con éxito!',
  'Place has been successfully deleted': 'El lugar ha sido eliminado con éxito',
  'Place has not been created': 'El lugar no ha sido creado.',
  'Place has not been deleted': 'El lugar no ha sido eliminado',
  'Place name': 'Lugar',
  'Place of birth': 'Lugar de nacimiento',
  'Place of interest': 'Lugar de interés',
  Places: 'Lugares',
  'Places of interest': 'Lugares de interés',
  Platform: 'Plataforma',
  'Platform settings': 'Configuración de la plataforma',
  'Please Enter Password': 'Por favor, ingrese contraseña',
  'Please Enter Username': 'Por favor, introduzca el nombre de usuario',
  'Please add an email address to the user. A new password will be sent to that email':
    'Agregue una dirección de correo electrónico al usuario. Se enviará una nueva contraseña a ese correo electrónico',
  'Please add at least two fields to create a note.':
    'Agregue al menos dos campos para crear una nota.',
  'Please add email to enable email notifications':
    'Por favor, agregue un correo electrónico para habilitar las notificaciones de correo electrónico',
  'Please add phone or email to enable OTP':
    'Por favor, agregue el teléfono o correo electrónico para habilitar OTP',
  'Please add phone to enable sms notifications':
    'Por favor, agregue un teléfono para habilitar las notificaciones de sms',
  'Please choose another daterange.':
    'Por favor, seleccione otro rango de fechas.',
  'Please complete all fields!': 'Por favor, ¡complete todos los campos',
  'Please contact your administrator': 'Por favor contacte a su administrador',
  'Please enable location access':
    'Por favor, habilite el acceso a la ubicación',
  'Please enter 3 or more characters': 'Ingrese 3 o más caracteres',
  'Please enter a valid TRG email!':
    'Por favor ingrese un correo electrónico TRG válido!',
  'Please enter the OTP code to login.':
    'Por favor, ingrese su OTP para iniciar sesión',
  'Please enter the URL for the specific community you want to be joined':
    'Por favor, ingrese la URL de la comunidad en específico a la cual desea unirse',
  'Please enter the URL for the specific community you want to be joined. &#10;One URL per line.':
    'Por favor, ingrese la URL de la comunidad en específico a la cual desea unirse. &#10;Una URL por línea.',
  'Please enter the same new password again':
    'Ingrese la misma contraseña nueva nuevamente',
  'Please fill in all fields': 'Por favor, complete todos los campos',
  'Please fill in both fields for the date':
    'Por favor, complete ambos campos para la fecha',
  'Please fill out this field': 'Por favor rellene este campo',
  'Please how do you want to create the Virtual ID':
    'Por favor como quieres crear el Avatar',
  'Please insert more than one phones to perform a proximity query':
    'Por favor agregue más de un número para poder realizar la consulta de proximidad',
  'Please provide a short description of your work.':
    'Por favor, describa brevemente su trabajo.',
  'Please provide proper platform link!':
    'Proporcione el enlace de plataforma adecuado!',
  'Please provide your email address':
    'Por favor proporcione su dirección de correo electrónico',
  'Please review the local file and upload again.':
    'Por favor, revise el archivo local e intente de nuevo',
  'Please select a 3 day range maximum.':
    'Seleccione un rango máximo de 3 días.',
  'Please select a Social Profile!': '¡Por favor, seleccione un perfil social!',
  'Please select a date!': '¡Por favor seleccione una fecha!',
  'Please select a query': 'Por favor, seleccione una consulta',
  'Please select all required values': '',
  'Please select at least 1 entity to create a new investigation':
    'Por favor, seleccione al menos 1 entidad para crear una nueva investigación',
  'Please select at least 2 targets for call log analysis':
    'Por favor, seleccione al menos 2 objetivos para analizar el registro de llamadas',
  'Please select at least one profile to begin monitoring':
    'Por favor, seleccione al menos un perfil para empezar a monitorear',
  'Please select the time period': 'Por favor, seleccione el periodo',
  'Please select the type of OTP you want to use.':
    'Por favor, seleccione el tipo de OTP que desea usar.',
  'Please select values for all columns':
    'Por favor, seleccione los valores para todas las columnas',
  'Please take this survey to rate your experience with':
    'Por favor, conteste esta encuesta para calificar su experiencia con',
  'Please use the filters below to define your objective. Keep in mind that you can make changes later if needed.':
    'Por favor, use los filtros a continuación para definir el objetivo.',
  'Please use upper case letters, lower case letters and numbers':
    'Utilice letras mayúsculas, minúsculas y números',
  'Please write the URL bellow and press enter if is valid':
    'Por favor, agregue la URL a continuación y presione entrar si es válida',
  'Please write the URL bellow and press enter if valid':
    'Por favor, agregue la URL a continuación y presione entrar si es válida',
  Poland: 'Polonia',
  Popularity: 'Popularidad',
  Portugal: 'Portugal',
  Position: 'Puesto de trabajo',
  Positive: 'Positivo',
  'Possible location for': 'Posible ubicación para',
  'Possibly owns this social profile': 'Posiblemente posee este perfil social',
  Post: 'Publicación',
  'Post Information': 'Información de la Publicación',
  'Post is created': 'Publicación creada',
  'Post is disliked': 'Se ha dado No Me gusta a publicaciones',
  'Post is liked': 'Se ha dado Me gusta a publicaciones',
  'Post is shared': 'Publicación compartida',
  'Post preview': 'Prevista de publicación',
  'Post was added': 'Publicación agregada',
  'Postal code': 'Código postal',
  'Posted on': 'Publicado en',
  Posts: 'Publicaciones',
  'Potential Infection': 'Infección Potencial',
  'Potential infection': 'Infección Potencial',
  'Power users': '',
  Powerusers: 'Powerusers',
  'Pre registration warmup for #{socialType} started':
    'Se ha empezado el preregistro para #{socialType}',
  'Pre-registering': 'Pre-registrando',
  'Predicted Home': 'Predicción de Ubicación de Domicilio',
  'Predicted Home Location with probability of': '',
  'Predicted Location': 'Ubicación prevista',
  'Predicted Locations': 'Ubicaciones previstas',
  'Predicted Workplace': 'Predicción de Ubicación de Trabajo',
  'Predicted Workplace Location with probability of': '',
  'Predicted location': 'Ubicación prevista',
  'Predicted locations': 'Predicción',
  'Preferred channel': 'Canal preferido',
  Pregnant: 'Embarazo',
  'Preparing PDF preview': 'Preparando vista previa del PDF',
  Present: 'Presente',
  Presets: 'Plantillas',
  Previous: 'Anterior',
  'Previous page': 'Página anterior',
  Previously: 'Previamente',
  'Private Profile': 'Perfil privado',
  'Private message to': 'Mensaje privado a',
  'Private messages': 'Mensajes privados',
  'Private profile': 'Perfil privado',
  Probability: 'Probabilidad',
  Proceed: 'Proceder',
  'Processing the data': 'Procesando los datos',
  'Processing the data, please wait': 'Procesando los datos, por favor espere',
  Product: 'Producto',
  Profile: 'Perfil',
  'Profile Search': 'Búsqueda de perfil',
  'Profile Search Results': 'Resultados de Búsqueda de Perfil',
  'Profile URL': 'URL del Perfil',
  'Profile URLs': 'URL de perfil',
  'Profile is associated with this photo': 'Perfil asociado con esta foto',
  'Profile is associated with this post':
    'El perfil está asociado con esta publicación',
  'Profile photos': 'Fotos de perfil',
  'Profile picture': 'Foto de perfil',
  'Profile search history': 'Historial de búsqueda de perfil',
  'Profile url for Facebook, Twitter, Instagram, Linkedin, Tiktok':
    'URL de perfil para Facebook, Twitter, Instagram, Linkedin, Tiktok',
  'Profile urls': '',
  Profiler: 'Perfilador',
  'Profiler report': 'Informe del generador de perfiles',
  Profileurls: 'URLs de redes sociales',
  Profiling: '',
  'Profiling will be updated next month': '',
  Program: 'Programa',
  'Property last sale date(s)': 'Última venta de la propiedad',
  'Property type': 'Tipo de propiedad',
  'Property type(s)': 'Tipo de propiedad(es)',
  Provider: 'Proveedor',
  Proximity: 'Proximidad',
  'Proximity Analysis': 'Análisis de proximidad',
  Publish: 'Publicar',
  'Purchase and import call log data for': 'Compra e importación de CDR para',
  'Purchase call log': 'Comprar registro de llamada',
  'Purchase call logs failed!': '¡Fallo la Compra de Registro de Llamadas!',
  'Purchase call logs initiated!': '¡Compra de Registro de Llamadas iniciada!',
  'QR code': 'Código QR',
  Qatar: 'Qatar',
  Quantity: 'Cantidad',
  Queries: 'Consultas',
  Query: 'Consulta',
  'Query Date': 'Fecha de consulta',
  'Query Id': 'ID de consulta',
  'Query MSISDN': 'Consultar MSISDN',
  'Query and schedule': 'Consulta y horario',
  'Query canceled': 'Consulta cancelada',
  'Query created successfully!': '¡Consulta creada con éxito!',
  'Query date': 'Fecha de consulta',
  'Query has not been canceled': 'La consulta no ha sido cancelada.',
  'Query has not been created': 'No se ha creado la consulta.',
  'Query hashtag': 'Consultar hashtag',
  'Query number': 'Numero de consulta',
  'Query pending': 'Consulta Pendiente',
  'Query selected': 'Consulta seleccionada',
  'Query successfully resubmitted!': '¡Consulta reenviada exitosamente!',
  'Query successfully submitted': 'Consulta enviada satisfactoriamente',
  'Quick filters': 'Filtros rápidos',
  Quota: 'Cuota',
  'Quota assigned': 'Cuota asignada',
  'Quota unused': 'Cuota no utilizada',
  Quotes: 'Notas',
  RFC: 'RFC',
  Race: 'Raza',
  'Radical monitoring': 'Monitoreo radical',
  'Radical monitoring matched a keyword for':
    'El monitoreo radical coincidió con una palabra clave para',
  'Radical monitoring service is down':
    'El servicio de monitoreo radical está caído',
  'Radical speech monitoring': 'Monitoreo radical del habla',
  Radio: 'Conexión',
  'Radio Id': '',
  'Radio Type': 'Tipo de radio',
  Radius: 'Radio',
  Random: 'Aleatorio',
  'Random actions': 'Acciones aleatorias',
  'Raw data': 'Datos en crudo',
  Reactions: 'Reacciones',
  'Reactions count': 'Las reacciones cuentan',
  Reactivate: 'Reactivar',
  Reason: 'Razón',
  'Received Group Messages': '',
  'Received Private Messages': '',
  'Received group messages': 'Mensajes grupales recibidos',
  'Received private messages': 'Mensajes privados recibidos',
  'Receiver number': 'Recipiente',
  'Recent searches': 'Búsquedas recientes',
  Recipient: 'Recipiente',
  'Recovery Accounts': 'Cuentas de Recuperación',
  'Recovery accounts': 'Recuperando cuentas',
  'Recovery data': 'Recuperación de cuenta',
  'Recovery email': 'Correo de recuperación',
  'Recovery phone': 'Teléfono de recuperación',
  Redraw: 'Volver a dibujar',
  'Redraw area': 'Redibujar área',
  Refresh: 'Refrescar',
  Region: 'Región',
  'registered on': 'registrado el',
  'Registering FB acc.': 'Registrando cta. FB',
  'Registration date': 'Fecha de Registro',
  'Related numbers': 'Números relacionados',
  'Related people': 'Personas relacionadas',
  'Related posts': 'Publicaciones relacionadas',
  Relation: 'Relación',
  Relations: 'Relaciones',
  Releasing: 'Liberando',
  Reload: 'Recargar',
  'Reload chart with latest target data':
    'Recargar gráfico con los últimos datos de destino',
  'Remaining quota': '',
  'Remaining usage': 'Remaining usage',
  'Remote view': 'Vista remota',
  Remove: 'Eliminar',
  'Remove Profile': 'Quitar perfil',
  'Remove filters': 'Limpiar Filtros',
  'Remove filters and restore queries':
    'Eliminar filtros y restaurar consultas',
  'Remove from case': 'Eliminar del caso',
  'Remove from history': 'Eliminar del historial',
  'Remove from whitelist': 'Eliminar de la lista blanca',
  'Remove layer': 'Eliminar capa',
  'Remove node': 'Remover nodo',
  'Remove target': 'Eliminar objetivo',
  'Remove Target?': '¿Remover Objetivo?',
  'Remove target from case': 'Eliminar objetivo del Caso',
  'Removes all filters, keeps new nodes from analyse':
    'Elimina todos los filtros, evita que se analicen nuevos nodos',
  'Removes everything (including nodes from analyse) and reloads all target data':
    'Elimina todo (incluidos los nodos del análisis) y vuelve a cargar todos los datos de destino',
  Renew: 'Renovar',
  'Renew target and Request call log':
    'Renovar destino y solicitar registro de llamadas',
  'Renew with the credits': 'Renovar con los créditos',
  Repeat: 'Repetir',
  'Repeat interval - hours': 'Repetir intervalo - horas',
  'Request Call Log': 'Solicitud de registro de llamadas',
  'Request Call Log by selecting one of the following options':
    'Solicite un Registro de Llamadas seleccionando una de las siguientes opciones',
  'Request Cancelled': 'Solicitud cancelada',
  'Request call log': 'Solicitar registro de llamadas',
  'Request call log limit reached. Please contact support.':
    'Límite de solicitud de registro de llamadas alcanzado. Por favor, contacte a soporte',
  'Request expired for': 'La solicitud expiró para',
  'Request from Support': 'Solicitud de Soporte',
  'Request has been sent to support': 'La solicitud ha sido enviada a soporte',
  'Request has not been sent': 'La solicitud no ha sido enviada',
  'Request or Upload Call Log by selecting one of the following options':
    'Solicite o Cargue un Registro de Llamadas seleccionando una de las siguientes opciones',
  'Request sent successfully!': '¡Solicitud enviada con éxito!',
  'Requesting call logs has been disabled':
    'La solicitud de registros de llamada ha sido deshabilitada',
  Required: 'Requerido',
  'Reserve browser': '',
  'Reserve browser failed. Please retry':
    'Falló la reservación de navegador. Por favor, intente de nuevo',
  Reserved: 'Reservado',
  Reserving: 'Reservando',
  Reset: 'Reiniciar',
  'Reset Password': 'Reiniciar contraseña',
  'Resize map': 'Cambiar el tamaño del mapa',
  'Rest of the week': 'El resto de la semana',
  Restore: 'Restaurar',
  Resubmit: 'Volver a enviar',
  'Resubmit Cancelled': 'Reenvío cancelado',
  'Resubmit query': 'Reenviar consulta',
  'Result type': 'Tipo de Resultado',
  Results: 'Resultados',
  'Results Found': 'Resultados Encontrados',
  'Results for': 'Resultados para',
  'Results found': 'Resultados encontrados',
  'Results of upload': 'Resultados de la carga',
  'Retweeted count': 'Conteo retwitteado',
  Roaming: 'Itinerancia',
  'Roaming Provider': 'Proveedor de itinerancia',
  Robbery: 'Robo',
  'Robot has not been canceled': 'El robot no ha sido cancelado.',
  Roles: 'Roles',
  Romania: 'Rumanía',
  'Rows per page': 'Filas por página',
  'Rows per page:': 'Filas por página:',
  Run: 'Ejecutar',
  'Run method was successfully started':
    'El método de ejecución se inició con éxito',
  Running: 'En ejecución',
  Russia: 'Rusia',
  Rwanda: 'Ruanda',
  'SAFE SEARCH': 'BÚSQUEDA SEGURA',
  'SIMILAR IMAGES': 'IMÁGENES SIMILARES',
  SMS: 'SMS',
  'SMS & calls': 'SMS y llamadas',
  'SMS activity': 'actividad de SMS',
  'SMS in': 'SMS Entrada',
  'SMS out': 'SMS Salida',
  STATISTICS: 'ESTADÍSTICAS',
  'Safety Cameras': 'Cámaras de seguridad',
  'Sahrawi Arab Democratic Republic': 'República Árabe Saharaui Democrática',
  'Saint Helena, Ascension and Tristan da Cunha': '',
  'Saint Kitts and Nevis': '',
  'Saint Lucia': 'Santa Lucía',
  'Saint Vincent and the Grenadines': '',
  'Same location': 'Misma ubicación',
  Samoa: 'Samoa',
  'San Marino': '',
  Satellite: 'Satélite',
  Saturday: 'Sábado',
  'Saudi Arabia': 'Arabia Saudita',
  Save: 'Guardar',
  'save as case': 'guardar como caso',
  'Save and Locate': 'Guardar y localizar',
  'Save area': 'Guardar área',
  'Save area of Interest': 'Guardar Área de Interés',
  'Save as a list from': 'Guardar como una lista de',
  'Save changes': 'Guardar cambios',
  'Save data to target': 'Guardar datos para objetivo',
  'Save result': 'Guardar resultado',
  'Saved!': '¡Guardado!',
  'Saving data': 'Guardar datos',
  'Scan QR with your device to generate OTP codes':
    'Escanee QR con su dispositivo para generar códigos OTP',
  'Scanned and added to feed': 'Escaneado y agregado a la alimentación',
  Scars: 'Cicatrices',
  Schedule: 'Programación',
  'Schedule has been created': 'El horario ha sido creado',
  Scheduled: 'Programación',
  Scheduler: 'Programación',
  'Scrapping photos': 'Desechando fotos',
  'Screen name': 'Nombre de pantalla',
  Search: 'Buscar',
  Searches: 'Búsquedas',
  'Search & geolocate phone': '',
  'Search & locate phone': 'Buscar y ubicar teléfono',
  'Search Google Maps': 'Buscar en Google Maps',
  'Search MSISDNs': 'Buscar MSISDN',
  'Search again': 'Busca de nuevo',
  'Search by': 'Búsqueda por',
  'Search by face and name': 'Buscar por rostro y nombre',
  'Search by keyword': 'Buscar por keyword',
  'Search by name': 'Buscar por nombre',
  'Search by phone number': 'Buscar por número',
  'Search entities': 'Buscar entidades',
  'Search graph': 'Buscar en gráfico',
  'Search history': 'Historial de búsqueda',
  'Search in results': '',
  'Search keyword': 'Buscar keyword',
  'Search name, MSISDNs or cases': 'Buscar nombre, MSISDN o casos',
  'Search query': 'Término de búsqueda',
  'Search tips': 'Consejos de búsqueda',
  'Search type': 'Tipo de búsqueda',
  'Search virtual ID ': 'Buscar ID virtuales',
  'Searched sources': 'Fuentes buscadas',
  'Searched sources for': 'Búsqueda de fuentes para',
  'Searching closed databases': 'Buscando bases de datos cerradas',
  'Searching dark web': 'Buscando en la Dark Web',
  'Searching instant message profiles':
    'Buscando perfiles de mensajería instantánea',
  'Searching open databases': 'Buscando bases de datos abiertas',
  'Searching open web': 'Buscando en la red abierta',
  'Searching social media': 'Buscar redes sociales',
  'Searching social profiles': 'Buscando perfiles sociales',
  See: 'Mostrar',
  'See a demo investigation ': 'Vea un ejemplo de investigación ',
  'See all photos': 'Ver todas las fotos',
  'See less': 'Ver menos',
  'See less results': 'Ver menos resultados',
  'See more': 'Ver más',
  'See more results': 'Ver más resultados',
  'See search history': 'Ver historial de búsqueda',
  'Seed copied': 'Semilla copiada',
  Select: 'Seleccionar',
  'Select Dates': 'Seleccionar fechas',
  'Select File': 'Seleccione archivo',
  'Select MSISDN': 'Seleccione MSISDN',
  'Select Social Profile': 'seleccione un perfil social',
  'Select a range of max 3 days': 'Seleccione un rango de 3 días como máximo',
  'Select a target to update': 'Seleccione un objetivo para actualizar',
  'Select a value': 'Seleccione un valor',
  'Select a virtual ID or proceed to the next step to create a new one':
    'Seleccione un virtual ID o preceda al siguiente paso para crear uno nuevo',
  'Select accounts': 'Seleccionar cuentas',
  'Select action': 'Seleccione la acción',
  'Select age': 'Seleccione edad',
  'Select all': 'Seleccionar todo',
  'Select all available': 'Seleccione todos los disponibles',
  'Select analysis': 'Seleccionar análisis',
  'Select area': 'Seleccionar area',
  'Select at least one period file':
    'Seleccione al menos un periodo del archivo',
  'Select atleast one IMEI/Phone model':
    'Seleccione un IMEI/Modelo de teléfono',
  'Select case': 'Seleccione un caso',
  'Select case targets': 'Seleccionar objetivos del caso',
  'Select country': 'Seleccionar país',
  'Select date': 'Seleccione fecha',
  'Select date range': 'Seleccione el rango de fechas',
  'Select dates': 'Seleccionar fechas',
  'Select days': 'Seleccionar días',
  'Select entity': 'Seleccionar entidad',
  'Select file': 'Seleccione archivo',
  'Select frequency': 'Seleccionar la frecuencia',
  'Select from Existing Targets': 'Seleccionar de objetivos existentes',
  'Select from pre-registered Virtual ID or create a new one':
    'Seleccione entre una identificación virtual prerregistrada o cree una nueva',
  'Select gender': 'Seleccione género',
  'Select layout': 'Seleccionar formato',
  'Select list': 'Seleccionar lista',
  'Select location': 'Seleccionar ubicación',
  'Select more targets': 'Selecciona más objetivos',
  'Select node to view details': 'Seleccione el nodo para ver los detalles',
  'Select one of multiple msisdns': 'Seleccione uno o múltiples MSISDN',
  'Select one or multiple imsis': 'Seleccione uno o múltiples IMSI',
  'Select one or multiple models': 'Seleccione uno o múltiples modelos',
  'Select one or multiple msisdns': 'Seleccione uno o múltiples MSISDN',
  'Select one or multiple periods': 'Seleccione uno o varios periodos',
  'Select period': 'Seleccionar periodo',
  'Select platform': 'Seleccionar plataforma',
  'Select region': 'Seleccionar región',
  'Select report type': 'Seleccione tipo de reporte',
  'Select social accounts': 'Seleccionar cuentas en redes sociales',
  'Select social network': 'Seleccionar redes sociales',
  'Select target': 'Seleccione un objetivo',
  'Select targets': 'Seleccionar objetivos',
  'Select targets from case': 'Seleccione objetivos de un caso',
  'Select the start and end interval for tracking the Ad IDs':
    'Seleccione el intervalo de inicio y fin a rastrear Ad IDs',
  'Select timeline analysis': 'Seleccionar análisis de fechas',
  'Select timezone': 'Seleccionar zona horaria',
  'Select type': 'Seleccionar tipo',
  'Select user': 'Seleccionar usuario',
  'Select/unselect location!': '¡Seleccionar/deseleccionar ubicación!',
  Selected: 'Seleccionado',
  'Selected date must be in the past!':
    '¡La fecha seleccionada debe estar en el pasado!',
  'Selected queue': 'Cuota seleccionada',
  Send: 'Enviar',
  'Send OTP': 'Enviar OTP',
  'Send SMS': 'Enviar SMS',
  'Send friend request': 'Enviar solicitud de amistad',
  'Send friend requests': 'Enviar solicitudes de amistad',
  'Send friend requests to group members':
    'Enviar solicitud de amistad a miembros de grupo',
  'Send us your feedback!': '¡Enviar sus comentarios!',
  Senegal: 'Senegal',
  'Sent Group Messages': '',
  'Sent Private Messages': '',
  'Sent group messages': 'Mensajes grupales enviados',
  'Sent private messages': 'Mensajes privados enviados',
  'Sent to groups': 'Enviado a grupos',
  'Sent to persons': 'Enviado a personas',
  Sentiment: 'Sentimento',
  'Sentiment analysis': 'Análisis de sentimiento',
  Sector: 'Sector',
  September: 'septiembre',
  Sequencial: 'Secuencial',
  Sequential: 'Secuencial',
  Serbia: 'Serbia',
  Session: 'Sesión',
  'Session Timeout': 'Hora de término de la sesión',
  'Session end': 'Fin de Sesión',
  'Session has expired!': '¡La sesión ha expirado!',
  'Session length': 'Duración de Sesión',
  'Session start': 'Inicio de Sesión',
  'Session started on the': 'La sesión comenzó el',
  'Session will expire in': 'La sesión caducará en',
  'Set Alerts': 'Definir Alertas',
  'Set notification for social activity':
    'Definir notificación de actividad en redes sociales',
  'Set notifications for social activity':
    'Set notifications for social activity',
  'Set social activity alerts':
    'Definir alertas de actividad en redes sociales',
  Settings: 'Ajustes',
  Severity: 'Severidad',
  Seychelles: 'Seychelles',
  'Share in common': 'Comparten en común',
  'Share post': 'Compartir publicación',
  Shared: 'Compartido',
  Shortest: 'Más corta',
  'Shortest call': 'Llamada más corta',
  'Show #{count} more': 'Mostrar #{count} más',
  'Show Release Notes': 'Mostrar Información Adiciconal',
  'Show all': 'Mostrar todos',
  'Show archived': 'Mostrar archivados',
  'Show batch query': 'Mostrar consulta por lotes',
  'Show call log details': 'Ver detalle de registro de llamadas',
  'Show call log map': 'Mostrar mapa de registro de llamadas',
  'Show common IDs': 'Mostrar ID en común',
  'Show degrees': 'Mostrar grados',
  'Show direct interactions between selected MSISDNs':
    'Mostrar interacciones directas entre MSISDN seleccionados',
  'Show existing intel': 'Mostrar información existente',
  'Show heatmap': 'Mostrar mapa de calor',
  'Show history': 'Mostrar historia',
  'Show international interactions': 'Mostrar interacciones internacionales',
  'Show less': 'Muestra menos',
  'Show less cases': 'Mostrar menos casos',
  'Show more': 'Mostrar más',
  'Show more cases': 'Mostrar más casos',
  'Show nearby Telegram users': 'Mostrar usuarios de Telegram cercanos',
  'Show nodes that came in contact with the target by distance of':
    'Mostrar nodos que entraron en contacto con el objetivo por distancia de',
  'Show nodes that interacted with the target by':
    'Mostrar nodos que interactuaron con el objetivo por',
  'Show on map': 'Mostrar en el mapa',
  'Show only articles with': 'Mostrar sólo artículos con',
  'Show only posts with': 'Mostras sólo publicaciones con',
  'Show only profiles with': 'Mostrar sólo perfiles con',
  'Show only starred': 'Mostrar sólo seleccionado',
  'Show predicted': 'Mostrar predicción',
  'Show query details': 'Mostrar detalles de la consulta',
  'Show query history': 'Mostrar historial de consultas',
  'Show search': 'Mostrar búsqueda',
  'Show statistics': 'Mostrar estadísticas',
  'Show top associates': 'Mostrar asociados principales',
  'Show unique IDs': 'Mostrar ID únicos',
  'Show updates': 'Mostrar actualizaciones',
  'Sierra Leone': 'Sierra Leona',
  'Sign In': 'Iniciar Sesión',
  'Sign out': 'Cerrar sesión',
  Sim: 'Sim',
  'Simple OSINT': 'OSINT Simple',
  'Simple Query': 'Consulta Simple',
  'Simple details': 'Detalles simples',
  Singapore: 'Singapur',
  'Site Name': 'Nombre del Sitio',
  'Site name': 'Nombre del sitio',
  'Site properties': 'Propiedades del sitio',
  Skills: 'Habilidades',
  'Skin tone': 'Tono de Piel',
  Skip: 'Saltar',
  'Skip filtering': 'Saltar filtrado',
  Skype: 'Skype',
  'Sleeping time': 'Horario de dormir',
  Slovakia: 'Eslovaquia',
  Slovenia: 'Eslovenia',
  Sms: 'Sms',
  Social: 'Social',
  'Social Activity': 'Actividad en Redes Sociales',
  'Social Insurance': 'Seguridad Social',
  'Social Media': 'Medios de comunicación social',
  'Social Media Profiles': 'Perfiles de redes sociales',
  'Social Profile': 'Perfil Social',
  'Social URL': 'URL social',
  'Social URLs': 'URLs de redes sociales',
  'Social Urls': 'URL sociales',
  'Social accounts': 'Cuentas sociales',
  'Social activity': 'Actividad en Redes Sociales',
  'Social groups': 'Grupos sociales',
  'Social insurance number': 'Número de seguro social',
  'Social network': 'Red social',
  'Social network top associates': 'Principales asociados de redes sociales',
  'Social networks': 'Redes sociales',
  'Social profile': 'Perfiles sociales',
  'Social profile details': 'Info. de perfiles sociales',
  'Social profiles': 'Perfiles sociales',
  'Social relation': 'Relación social',
  'Social word cloud': 'Nube de palabras sociales',
  'Software Information': 'Información del programa',
  'Solomon Islands': 'Islas Salomón',
  Somalia: 'Somalia',
  Somedus: 'Somedus',
  'Somedus Request Submitted': 'Somedus solicitud enviada',
  'Somedus retrived information': 'Somedus obtuvo información',
  'Somedus will be updated on 12:00': '',
  'Something went wrong': 'Algo salió mal',
  'Something went wrong please try again later!':
    'Algo salió mal, por favor, intente de nuevo más tarde!',
  'Something went wrong with File Text Analysis':
    'Algo salió mal con el Análisis de Texto del Archivo',
  'Something went wrong with the photo. Please try again.':
    'Algo salió mal con la foto. Por favor, intente de nuevo.',
  'Something went wrong, please try again.':
    'algo salió mal, por favor, intente de nuevo',
  'Something went wrong.': 'Algo salió mal.',
  'Something went wrong. Please try OTP reset again.':
    'Algo salió mal. Intente restablecer OTP nuevamente.',
  'Something went wrong. Please try again.':
    'Algo salió mal. Por favor, intente de nuevo.',
  Sort: 'Ordenar',
  'Sort by': 'Ordenar por',
  Source: 'Fuente',
  Sources: 'Fuentes',
  'South Africa': 'Sudáfrica',
  'South Georgia and the South Sandwich Islands':
    'Islas Georgias del Sur y Sandwich del Sur',
  'South Korea': 'Corea del Sur',
  'South Sudan': 'Sudán del Sur',
  Spain: 'España',
  Spanish: 'Español',
  'Special characters and trailing spaces are not allowed':
    'No se permiten caracteres especiales ni espacios finales',
  'Special characters are not allowed':
    'Los caracteres especiales no están permitidos',
  Specific: 'Específico',
  'Specific actions': 'Acciones específicas',
  'Split View': 'Vista dividida',
  'Sri Lanka': 'Sri Lanka',
  Standard: 'Estándar',
  'Starred only': 'Solo favoritas',
  Start: 'Comienzo',
  'Start Date': 'Fecha de inicio',
  'Start Investigation': 'Empezar investigación',
  'Start Scheduler': 'Comienzo Programación',
  'Start date': 'Fecha de inicio',
  'Start monitoring': 'Empezar a monitorear',
  'Start now': 'Empezar ahora',
  'Start scheduler': 'Comienzo programación',
  'Start typing job description':
    'Empiece a escribir la descripción del trabajo',
  'Start typing phone, name, CURP, url, email or username or upload photo':
    'Comience a escribir teléfono, nombre, CURP, url, correo electrónico o nombre de usuario o cargue una foto',
  'Start typing phone, name, url, email or username or upload photo':
    'Comience a escribir teléfono, nombre, url, correo electrónico o nombre de usuario o cargue una foto',
  'Start typing your keyword': 'Escriba su keyword',
  'Start your investigation': 'Empiece su investigación',
  'Started at': 'Empezó el',
  Starting: 'Empezando el',
  Statistics: 'Estadísticas',
  Status: 'Estado',
  'Status ': 'Estado',
  'Status of last 5 searches': 'Estado de las últimas 5 búsquedas',
  Step: 'Paso',
  Stop: 'Detener',
  'Stop Geofencing Task': 'Detener la tarea de geo-cerca',
  'Stop following': 'Dejar de seguir',
  'Stop method was successfully stopped':
    'El método de detención se detuvo con éxito',
  'Stop monitoring': 'Parar de monitorear',
  Stopped: 'Detenido',
  Structural: 'Estructural',
  Studied: 'Estudió',
  Submit: 'Enviar',
  Subscription: 'Suscripción',
  'Succesfull ExtractusD Operation': 'Operación de ExtractusD satisfactoria',
  'Success!': '¡Éxito!',
  Successfully: '',
  'Successfully inserted': 'Insertado satisfactoriamente',
  Sudan: 'Sudán',
  Summary: 'Resumen',
  'Summary report': 'Informe resumido',
  Sunday: 'Domingo',
  'Support request': 'Solicitud de soporte',
  'Supported file types': 'Tipos de archivos soportados',
  Suriname: 'Surinam',
  Suspicious: 'Sospechoso',
  'Suspicious Words': 'Palabras sospechosas',
  Swaziland: 'Suazilandia',
  Sweden: 'Suecia',
  'Switch to dark theme': 'Cambiar a modo oscuro',
  'Switch to detail view': 'Cambiar a vista detallada',
  'Switch to light theme': 'Cambiar a modo claro',
  'Switch to standard view': 'Cambiar a vista estándar',
  Switzerland: 'Suiza',
  Syria: 'Siria',
  'São Tomé and Príncipe': '',
  TMSI: 'TMSI',
  Tactical: 'Táctico',
  'Tagged In Same Photo': 'Etiquetados en las mismas fotos',
  'Tagged by others': 'Etiquetado por otros',
  'Tagged in': 'Etiquetado en',
  'Tagged in photos of': 'Etiquetado en fotos de',
  'Tagged in posts of': 'Etiquetado en publicaciones de',
  'Tagged photos owners': 'Personas etiquetadas en fotos',
  'Tagged posts owners': 'Personas etiquetadas en post',
  Taiwan: 'Taiwán',
  Tajikistan: 'Tayikistán',
  Tanzania: 'Tanzania',
  Target: 'Objetivo',
  'Target Activity': 'Actividad del objetivo',
  'Target Details': 'Detalles del objetivo',
  'Target Edit': 'Editar objetivo',
  'Target Enriched successfully': 'Perfil Enriquecido satisfactoriamente',
  'Target Expired': 'El objetivo caducado',
  'Target Infected': 'Objetivo Infectado',
  'Target LastSeen': 'Ultima vez visto de objetivo',
  'Target Profile': 'Perfil objetivo',
  'Target called': 'El Objetivo fue llamado',
  'Target cannot be renewed. Not enough Credits':
    'El objetivo no puede ser renovado.  No cuenta con los créditos suficientes',
  'Target case': 'Caso objetivo',
  'Target created successfully!': '¡Objetivo creado con éxito!',
  'Target details': 'Detalles del objetivo',
  'Target edited successfully!': '¡Objetivo editado con éxito!',
  'Target export call log report': 'Exportar reporte de llamadas del objetivo',
  'Target has been successfully deleted':
    'El objetivo ha sido eliminado con éxito',
  'Target has not been created': 'El objetivo no ha sido creado.',
  'Target has not been deleted': 'El objetivo no ha sido eliminado',
  'Target has not been edited': 'El objetivo no ha sido editado.',
  'Target has not been renewed': 'El objetivo no se ha renovado',
  'Target has not been renewed, Not enough credits':
    'El objetivo no se ha renovado, Sin créditos suficientes',
  'Target has not been update': 'El objetivo no ha sido actualizado',
  'Target is Expired': 'El objetivo expiró',
  'Target is already assigned to the case!':
    '¡Target ya está asignado al caso!',
  'Target is assigned to this case': 'El objetivo se asigna a este caso',
  'Target is connected to the network': 'El Objetivo está conectado a la red',
  'Target is currently on a call': 'El objetivo está en una llamada',
  'Target is expired': 'El objetivo ha caducado',
  'Target locations': 'Ubicaciones del objetivo',
  'Target messaged': 'OEl Objetivo envió un mensaje a',
  'Target number': 'Número objetivo',
  'Target peer interactions': 'Interacciones',
  'Target received a call from': 'El Objetivo recibió una llamada de',
  'Target received a message from': 'El Objetivo recibió un mensaje de',
  'Target renewed successfully!': 'Objetivo renovado con éxito!',
  'Target summary': 'Resumen de objetivos',
  'Target summary report': 'Reporte de resumen de objetivo',
  'Target updated successfully!': '¡Objetivo actualizado con éxito!',
  'Target {target} has a new post':
    'El objetivo {target} tiene una nueva publicación',
  'Target {target} has a new post with keyword(s)':
    'El objetivo {target} tiene una nueva publicación con las palabras clave',
  Targets: 'Objetivos',
  'Targets added successfully!': '¡Objetivos agregados con éxito!',
  'Targets are now here': '',
  'Targets assigned': 'Objetivos asignados',
  'Targets renewed successfully!': '¡Objetivo renovado satisfactoriamente!',
  'Targets unassigned': 'Objetivos no asignados',
  'Target’s subscription is active': 'La suscripción del objetivo está activa',
  'Target’s subscription is active but currently not connected to the network':
    'La suscripción del objetivo está activa pero actualmente no está conectado a la red',
  'Target’s subscription is blocked':
    'La suscripción del objetivo está bloqueada',
  'Target’s subscription is not activated':
    'La suscripción del objetivo no está activa',
  'Task ID': 'ID de tarea',
  'Task Id': 'ID de tarea',
  'Task for': 'Tarea para',
  'Task has been successfully canceled': 'Tarea cancelada con éxito.',
  'Task has not been canceled': 'La tarea no ha sido cancelada',
  Tasks: 'Tareas',
  Telegram: 'Telegram',
  'Telegram nearby': 'Telegram cercanos',
  'Telegram users cannot be located now!':
    '¡Las usuarios de telegram no pueden ser localizadas ahora!',
  'Telegram users located successfully!':
    '¡Usuarios de Telegram localizados con éxito!',
  Telnos: 'Números de teléfono',
  'Temporary password for #{username}':
    'Contraseña temporal para el #{username}',
  'Temporary password sent to: #{email}':
    'Contraseña temporal enviada a: #{email}',
  Tenant: 'Inquilino',
  'Tenant balance': 'Balance del tenant',
  Text: 'Texto',
  'Text Analysis': 'Análisis de texto',
  'Text analysis': 'Análisis de texto',
  'Text analysis the file': 'Análisis de Texto del archivo',
  Thailand: 'Tailandia',
  'Thank you for your message. Your customer success representative will contact you shortly':
    'Gracias por su mensaje. Su representante de éxito del cliente le contactará en breve',
  'The Bahamas': 'Las Bahamas',
  'The Cell ID cannot be extracted for':
    'El ID de celda no se puede extraer para',
  'The Netherlands': 'Países Bajos',
  'The OSINT action for': 'La acción OSINT para',
  'The files once deleted cannot be restored.':
    'Una vez eliminados, los archivos no se pueden restaurar.',
  'The inputted #{clParameterType} is partially invalid. Please update your request with the following #{clParameterType}:':
    'El #{clParameterType} introducido es parcialmente inválido. Por favor, actualice su solicitud con el siguiente #{clParameterType}:',
  'The last x days': 'En los últimos x días',
  'The monitor is collecting data, it will be presented as soon as it is discovered':
    'El monitor está recopilando datos, se presentarán tan pronto como se descubra',
  'The password was changed!': '¡La contraseña fue cambiada!',
  'The password was reset!': '¡Se restableció la contraseña!',
  'The password will be sent to the email address #{email}. Is this address correct?':
    'La contraseña se enviará a la dirección de correo electrónico "#{email}" ¿Esta dirección de correo es correcta?',
  'The quota is changed by #{currentUser}':
    'La cuota ha sido cambiada por #{currentUser}',
  'The quota is changed by #{currentUser} - #{quotaType}':
    'La cuota ha sido cambiada por #{currentUser} - #{quotaType}',
  'The request call log is not currently supported for this country':
    'Por el momento la solicitud de registro de llamadas no está soportada para este país',
  'The requested phone number is not valid with this country code. Please select a different country code from the dropdown menu and try again.':
    'El número de teléfono solicitado no es válido con este código de país. Por favor, seleccione un código de país diferente desde el menú desplegable e intente de nuevo.',
  'The system will generate a new Avatar':
    'El sistema generará un nuevo Avatar',
  'The target changes have been saved and analysis is started. Click':
    'Los cambios al objetivo han sido guardados y inició el análisis. Haga clic',
  'The target changes have been saved. Click':
    'Los cambios al objetivo han sido guardados. Haga clic',
  'The whole Facebook registration flow completed':
    'Se finalizó el flujo de registro completo de Facebook',
  'Then you have the ability to:': 'Entonces tiene la capacidad de:',
  'There are no MSISDNs added. Please add a':
    'No se han agregado MSISDN. Por favor, agregue un',
  'There are no avatars created. Please add a new avatar':
    'No se han creado Avatares. Por favor, agregue un nuevo avatar.',
  'There are no cases created. Please add a':
    'No hay casos creados. Por favor, añada un',
  'There are no investigations added. Please create a ':
    'No se ha agregado ninguna investigación. Por favor, agregue una ',
  'There are no investigations added. Please create a new investigation': '',
  'There are no ip created': 'No hay IP creadas',
  'There are no suspicious words': 'No hay palabras sospechosas',
  'There are no targets created. Please add a':
    'No hay objetivos creados. Por favor, añada un',
  'There are no virtual IDs assigned to your user':
    'No tiene ningún IDs virtuales  asignado a su usuario',
  'This Virtual ID will expire on': 'Esta ID virtual expira el',
  'This action might leave anonymous traces on target’s phone.':
    'Esta acción puede dejar rastros anónimos en el teléfono del objetivo.',
  'This action will create a new target and request call log for':
    'Esta acción creará un nuevo objetivo y solicitará el registro de llamadas para',
  'This action will create the Locate query that is associated with':
    'Esta acción creará la consulta de localización asociada con',
  'This action will deduct 2 credits.': 'Esta accíon le deducirá 2 créditos.',
  'This action will deduct 3 credits.': 'Esta accíon le deducirá 3 créditos.',
  'This action will deduct 4 credits. This may take up to 30 min.':
    'Esta acción deducirá 4 créditos de su cuenta. Puede durar hasta 30 minutos.',
  'This action will enable Call log analysis':
    'Esta acción permitirá el análisis del registro de llamadas',
  'This action will fetch all the Basic OSINT that are associated with':
    'Esta acción buscará todos los OSINT básicos asociados con',
  'This action will fetch call log information for':
    'Esta acción recolectará la información del registro de llamada para',
  'This action will locate the': 'Esta acción localizará el',
  'This action will purchase and import call log for':
    'Esta acción comprará e importará el Registro de Llamadas para',
  'This action will purchase the call logs for':
    'Esta acción comprará el registro de llamada para',
  'This action will request Call Log for':
    'Esta acción solicitará el registro de llamadas para',
  'This action will show the accurate position of the Telegram nearby people':
    'Esta acción mostrará la posición exacta de las personas con Telegram cercanas',
  'This associate frequently interacts with the target.':
    'Este contacto interactúa frecuentemente con el objetivo.',
  'This associate possibly met with the target.':
    'Este contacto posiblemente se reunió con el objetivo.',
  'This case has no targets': 'Este caso no tiene objetivos',
  'This case needs at least 2 targets to find common information':
    '¡Este caso necesita al menos 2 objetivos para encontrar información común!',
  'This is a 24hrs temporary user': 'Este es un usuario temporal las 24 horas.',
  'This monitor did not collect any data':
    'Este monitor no recopiló ningún dato',
  'This option will be available for': 'Esta opción estará disponible para',
  'This post contains': 'Esta publicación contiene',
  'This process might take approximately':
    'Este proceso puede durar aproximadamente',
  Thursday: 'Jueves',
  Tiktok: 'Tiktok',
  Time: 'Hora',
  'Time Zone': 'Zona horaria',
  'Time left to expiration:': 'Fecha de Expiración:',
  'Time of collection': 'Hora de recolección',
  'Time of the query': 'Hora de la consulta',
  'Time remaining': 'Tiempo restante',
  'Time stamp': 'Registro',
  'Timebar type': 'Tipo de barra de tiempo',
  Timegraph: 'Linea de tiempo',
  timeline: 'Rango de fechas',
  Timeline: 'Cronograma',
  'Timeline pattern': 'Patrón de actividad',
  Title: 'Título',
  'Title content': 'Título del contenido',
  'Title exists. Please use another title':
    'Este Título ya existe. Por favor, utilice otro título',
  'Title is required': 'El título es requerido',
  To: 'A',
  'To achieve this we are asking you to participate in the user feedback generation program where every now-and-then we would ask you simple questions about how you use Intellectus.':
    'Para lograr esto, le pedimos que participe en el programa de comentarios y sugerencias de usuarios, en el que de vez en cuando le haremos preguntas sencillas sobre cómo utiliza Intellectus.',
  'To import, please select one or multiple days':
    'Para importar, seleccione uno o varios días',
  'To search for a phone number, enter the complete number, including country code. Example: #{example}':
    'Para buscar un número de teléfono, introduzca el número completo, incluido el prefijo del país. Ejemplo: #{example}',
  'To start a monitor': 'Para iniciar un monitor',
  Today: 'Hoy',
  'Toggle map': 'Mapa de Palanca',
  'Toggle Mission Overview': 'Seleccionar Resumen de Misión',
  'Toggle photo': 'Alternar foto',
  Togo: 'Togo',
  Tokelau: 'Tokelau',
  Tonga: 'Tonga',
  'Tool to analyze different types of intelligence':
    'Herramienta de análisis de diferentes tipos de inteligencia',
  'Top 5 contacts': 'Top 5 contactos',
  'Top Associates': 'Asociados principales',
  'Top Commenteers In Facebook Photos':
    'Principales Comentaristas de Fotos en Facebook',
  'Top Commenteers In Facebook Posts':
    'Top comentadores de publicaciones en Facebook',
  'Top Device Attributes': 'Atributos de dispositivos principales',
  'Top Facebook Connections': 'Principales Conexiones de Facebook',
  'Top Facebook Photo Shares': 'Top comentadores de fotos en Facebook',
  'Top Facebook Post Shares': 'Top compartidores de publicaciones en Facebook',
  'Top Keywords': 'Palabras clave principales',
  'Top Likers In Facebook Photos': 'Principales Likers de Fotos en Facebook',
  'Top Likers In Facebook Posts':
    'Principales Likers de Publicaciones en Facebook',
  'Top Locations': 'Ubicaciones principales',
  'Top Relations': 'Relaciones Principales',
  'Top associates': 'Asociados principales',
  'Top connection': 'Conexión importante',
  'Top locations': 'Ubicaciones principales',
  'Top profiles': 'Perfiles principales',
  'Top relationships': 'Principales relaciones',
  'Top target locations': 'Ubicaciones principales del objetivo',
  Topic: 'Tema',
  Topics: 'Temas',
  Total: 'Total',
  'Total Parsed': 'Total analizado',
  'Total points': 'Puntos totales',
  'Total calls': 'Llamadas totales',
  'Total credits': 'Créditos totales',
  'Total credits deducted': 'Total de créditos descontados',
  'Total credits reserved': 'Créditos totales reservados',
  'Total distance': 'Distancia total',
  'Total duration': 'Duración total',
  'Total monitor time': 'Tiempo total del monitor',
  'Total number of matches': 'Número total de coincidentes',
  'Total number of sessions': 'Número total de sesiones',
  'Total queries': 'Total de consultas',
  'Total successful queries': 'Total de consultas exitosas',
  'Total transactions': 'Total de transacciones',
  Trace: 'Trazar',
  'Track period': 'Periodo de seguimiento',
  'Transaction date': 'Fecha de transacción',
  Transactions: 'Transacciones',
  'Transactions summary': 'Resumen de transacciones',
  'Trilateration query completed!': 'Consulta de triangulación completada',
  'Trinidad and Tobago': 'Trinidad y Tobago',
  'True Caller': 'True-caller',
  Truecaller: 'Truecaller',
  'Truecaller profile location': 'Ubicación de Perfil de Truecaller',
  'Try another search': 'Prueba otra búsqueda',
  'Try different or less filters': 'Intente diferente o menos filtros',
  'Try fewer keywords': 'Pruebe con menos palabras clave',
  Tuesday: 'Martes',
  Tunisia: 'Túnez',
  Turkey: 'Turquía',
  Turkmenistan: 'Turkmenistán',
  'Turks and Caicos Islands': '',
  'Turn Off': 'Desactivar',
  'Turn On': 'Activar',
  Tuvalu: 'Tuvalu',
  Tweak: 'Tweak',
  Twitter: 'Twitter',
  'Twitter hashtag': 'Twitter hashtag',
  Type: 'Tipo',
  'Type a name': 'Escriba un nombre',
  'Type any term you would like to add':
    'Escriba cualquier término que le gustaría agregar',
  'Type of data': 'Tipo de datos',
  'Type keyword': 'Escribir palabra clave',
  'Type keywords you wish to follow':
    'Ingrese las palabras clave que desea seguir',
  'Type value here': 'Escriba el valor aquí',
  UNKNOWN: 'DESCONOCIDO',
  URL: 'URL',
  'USER EQUIPMENT': 'Equipo del Usuario',
  USERNAME: 'NOMBRE DE USUARIO',
  Uganda: 'Uganda',
  Ukraine: 'Ucrania',
  Unauthorized: 'No autorizado',
  Unavailable: 'No disponible',
  'Uncover usernames, open web mentions, email and social media accounts':
    'Descubrir nombres de usuario, menciones en open web, email y cuentas de redes sociales',
  'Uncover usernames, open web mentions, email and social media accounts that are associated with':
    'Descubra nombres de usuario, menciones en la red abierta, cuentas de correo y redes sociales que están relacionadas con',
  Unfollow: 'Dejar de seguir',
  Unique: 'Únicos',
  'Unique entries': 'Entradas únicas',
  'United Arab Emirates': 'Emiratos Árabes Unidos',
  'United Kingdom': 'Reino Unido',
  'United States': 'Estados Unidos',
  'United States of America': 'Estados Unidos de América',
  Unknown: 'Desconocida',
  'Unknown type': 'Tipo desconocido',
  'Unlike group posts': 'Quitar “Me gusta” a publicaciones de grupos',
  'Unlike posts': 'Quitar “Me gusta”',
  Unlimited: 'Ilimitado',
  Unlock: 'Desbloquear',
  Unmapped: 'No mapeado',
  'Unmerge results': 'Desagrupar resultados',
  'Unpin this search': 'Dejar de fijar búsqueda',
  Unreachable: 'No encontrado',
  'Unselect all': 'Deselecciona todo',
  Unspecified: 'Desconocida',
  'Unveil movement patterns and understand your target’s habits':
    "Revelar patrones de movimiento y entender hábitos de su objetivo':entender los hábitos de su objetivo",
  'Unveil movement patterns and understand your target’s habits and associates':
    'Revelar patrones de movimiento y entender hábitos y contactos de su objetivo',
  Update: 'Actualizar',
  'Update existing target': 'Actualizar destino existente',
  'Update link analysis': 'Actualizar análisis de enlaces',
  'Update target': 'Actualizar objetivo',
  'Update the graph': 'Actualizar el gráfico',
  'Upload Call Log': 'Subir registro de llamadas',
  'Upload Call Log by selecting one of the following options':
    'Cargue un Registro de Llamadas seleccionando una de las siguientes opciones',
  'Upload another file': 'Subir otro archivo',
  'Upload call log': 'Subir registro de llamadas',
  'Upload call log for: #{value}': 'Cargar registro de llamadas para: #{value}',
  'Upload existing call': 'Subir un registro de llamadas existente',
  'Upload existing call log': 'Suba un registro de llamadas existente',
  'Upload image': 'Cargar imagen',
  'Upload photo': 'Subir foto',
  'Uploaded on': 'Cargado el',
  Url: 'Url',
  Usage: '',
  'Use at least 8 characters. Don’t use a password from another site, or something too obvious':
    'Utilice al menos 8 caracteres. No use una contraseña de otro sitio o algo demasiado obvio',
  'Use comma to separate the MSISDNs.': 'Use coma para separar las MSISDN.',
  'Use comma or enter to add MSISDNs or IMSIs':
    'Agregue una coma o presione enter para agregar MSISDNs o IMSIs',
  'Use the search filters': 'Utilice los filtros de búsqueda',
  'Use this option to search for a pre-existing Virtual ID suitable to your mission, or create a new Virtual ID':
    'Use esta opción si tiene la información de credenciales',
  'Used by': 'Usado por',
  User: 'Usuario',
  'User Equipment': 'Dispositivo del usuario',
  'User ID': 'ID de usuario',
  'User Management': 'Administrar Usuarios',
  'User created with': 'Usuario creado con',
  'User edited successfully!': '¡Usuario editado con éxito!',
  'User equipment': 'Equipo de usuario',
  'User has been successfully deleted':
    'El usuario ha sido eliminado con éxito',
  'User has no wallets': 'El usuario no cuenta con billeteras',
  'User has not been created': 'El usuario no ha sido creado',
  'User has not been edited': 'Usuario no ha sido editado',
  'User locked successfully!': 'Usuario bloqueado con éxito!',
  'User management': 'Administración de usuario',
  'User manual': 'Manual de usuario',
  'User not found': 'Usuario no encontrado',
  'User options': 'Opciones de usuario',
  'User settings': 'Menú de Usuario',
  'User unlocked successfully!': 'Usuario desbloqueado con éxito!',
  'User:': 'El Usuario:',
  Username: 'Nombre de usuario',
  'Username already exists. Please try again.':
    'El nombre de usuario ya existe. Por favor, intente de nuevo.',
  'Username must contain at least one letter':
    'El nombre de usuario debe contener al menos una letra',
  'Username(s)': 'Nombre de usuario(s)',
  Usernames: 'Nombres de usuario',
  Users: 'Usuarios',
  'Valid email entered': 'Dirección de email válida',
  'Valid phone number entered.': 'El número telefónico ingresado es válido.',
  Value: 'Valor',
  'Vehicle ID': 'ID de Vehículo',
  'Vehicle ID (VIN)': 'ID de vehículo',
  'Vehicle make': 'Marca de Vehículo',
  'Vehicle model': 'Modelo de vehículo',
  'Vehicle models': 'Modelos de vehículos',
  'Vehicle year': 'Año de vehículo',
  Vendor: 'Fabricante',
  Verified: 'Verificado',
  Video: 'Video',
  Videos: 'Videos',
  View: 'Ver',
  'View All History': 'Ver todo el historial',
  'View Call Log analysis': 'Ver análisis de registro de llamadas',
  'View Cases': 'Ver Casos',
  'View Dashboard': 'Ver Tablero',
  'View Log': 'Ver historial',
  'View Targets': 'Ver Objetivos',
  'view all': 'ver todo',
  'View all commenters': 'Ver todos los comentarista',
  'View all likers': 'Ver todos los me gusta',
  'View all queries': 'Ver todas las consultas',
  'View call log': 'Ver registro de llamadas',
  'View call log analysis': 'Ver el análisis de Registro de Llamadas',
  'View details': 'Ver detalles',
  'View full profile': 'Ver todo el perfil',
  'View geolocation details': 'Ver detalles de geolocalización',
  'View in Link Analysis': 'Ver en análisis de enlaces',
  'View in fullscreen': 'Ver en pantalla completa',
  'View in link analysis': 'Ver en análisis de enlaces',
  'View item': 'Ver ítem',
  'View less': 'Ver menos',
  'View map': 'Ver el mapa',
  'View more': 'Ver más',
  'View note details': 'Ver detalle de las notas',
  'View on map': 'Ver en Mapa',
  'View in Google maps': 'Ver en Google Maps',
  'View original post': 'Ver publicación original',
  'View photo': 'Ver foto',
  'View place': 'Ver lugar',
  'View post': 'Ver publicación',
  'View post details': 'Ver detalles de la publicación',
  'View profile': 'Ver perfil',
  'View results': 'Ver resultados',
  'View session details': 'Ver detalles de la sesión',
  'View Settings': 'Ver Ajustes',
  'View short profile': 'Ver perfil corto',
  'View target': 'Ver objetivo',
  'View the list': 'Ver la lista',
  'View the results': 'Ver los resultados',
  'View the target': 'Ver objetivo',
  Violence: 'Violencia',
  'Virtual ID': 'ID Virtuales',
  'Virtual ID  management': 'Administración de ID virtuales',
  'Virtual ID #{fullName} expired on #{formatedDate}. Click on the button below to renew for #{numberOfDays} days.':
    'La ID Virtual #{fullName} expiró el #{formatedDate}. Haga clic en el botón a continuación para renovarla por #{numberOfDays} días.',
  'Virtual ID could not be renewed. Please try again.':
    'La ID Virtual no pudo ser renovada. Por favor, intente de nuevo.',
  'Virtual ID creation wizard': 'Configurador de creación de virtual ID',
  'Virtual ID information': 'Información del virtual ID',
  'Virtual ID management': 'Administración de ID Virtuales',
  'Virtual ID renewed successfully.': 'ID Virtual renovada satisfactoriamente.',
  'Virtual ID selection': 'Selección de virtual ID',
  'Virtual ID was created': 'El ID virtuales fue creado',
  'Virtual ID was updated': 'La ID Virtual ha sido actualizada',
  'Virtual IDs': 'IDs Virtuales',
  'Virtual IDs have expired': 'Las ID Virtuales han expirado',
  'Virtual IDs will expire in 24 hours':
    'Algunas ID Virtuales expirarán en 24 horas',
  'Virtual id': 'ID Virtual',
  'Visit Target': 'Visite objetivo',
  Voice: 'Voz',
  Volunteering: 'Voluntariado',
  'Volunteering actions': 'acciones de voluntarios',
  'Voter ID': 'ID de votante',
  'Voter Id': 'ID de Votación',
  'WEB ENTITIES': 'ENTIDADES WEB',
  WEBINT: 'WEBINT',
  'Waiting for CSV to be downloaded': 'Esperando la descarga del archivo CSV',
  'Wall posts': 'Publicaciones en muro',
  'Wallet addresses': 'Direcciones de billeteras',
  Wallets: 'Billeteras',
  'Wanted for': 'Buscado por',
  Warning: '',
  'We are always looking for the ways to improve our product for it to be even more useful in your daily work.':
    'Siempre estamos buscando la manera de mejorar nuestro producto para que sea aún más útil en su trabajo diario.',
  'We couldn’t find any results for':
    'No pudimos encontrar ningún resultado para',
  'We found': 'Encontramos',
  "We found an anomaly! This person used the target's mobile device.":
    '¡Encontramos una anomalía! Esta persona uso el dispositivo móvil del objetivo',
  'We found available historical data':
    'Se encontraron datos históricos disponibles',
  'We found available historical data for':
    'Se encontraron datos históricos disponibles para',
  'We found that': 'Encontramos eso',
  'We have successfully merged all the collected data to a':
    'Hemos fusionado con éxito todos los datos recolectados con',
  'We now support search by CURP ID': 'Ahora soportamos la búsqueda por CURP',
  'We sent a verification code to the email address:':
    'Enviamos el código de verificación al correo electrónico:',
  'We sent a verification code to the phone number:':
    'Enviamos el código de verificación al número telefónico:',
  'We were not able to bring company employees at the moment.':
    'No pudimos traer empleados de la empresa en este momento.',
  'We were not able to bring mutual facebook friends at the moment.':
    'No pudimos traer amigos mutuos de Facebook en este momento.',
  'We will notify you once the enrichment is completed':
    'Le notificaremos una vez que el proceso haya sido completado',
  Webint: 'Webint',
  'Webint features are disabled. Please contact your customer success representative.':
    'La funcionalidad Webint está desactivada. Por favor, contacte a su representante de éxito del cliente.',
  'Webint search history': 'Historial de búsqueda Webint',
  Wednesday: 'Miércoles',
  Week: 'Semana',
  Weekdays: 'Entre semana',
  Weekend: 'Fin de semana',
  Weekends: 'Fines de semana',
  'Weekly activity': 'Actividad semanal',
  Weight: 'Peso',
  'What is the objective of your investigation?':
    '¿Cuál es el objetivo de su investigación?',
  'What type of investigation are you working on?':
    '¿En qué tipo de investigación está trabajando?',
  Whatsapp: 'Whatsapp',
  When: 'cuándo',
  'When using the search filters, the results will be more accurate':
    'Al utilizar los filtros de búsqueda, los resultados serán más precisos',
  Where: 'dónde',
  Whitelist: 'Lista blanca',
  'Whitelist Number': 'Número de lista blanca',
  'Whitelist Numbers': 'Números de la lista blanca',
  Who: 'quién',
  Withdrawal: 'Retiro',
  'Word Cloud': 'Nube de palabras',
  Wordcloud: 'Nube de Palabras',
  Work: 'Trabajo',
  'Work Location': 'Ubicación de Trabajo',
  'Work Place': 'Lugar de Trabajo',
  'Work Place(s)': 'Lugar(es) de trabajo',
  'Work of art': 'Obra de arte',
  'Work place': 'Lugar de Trabajo',
  'Work relations': 'Relaciones laborales',
  'Working time': 'Horario de trabajo',
  Workplace: 'Lugar de trabajo',
  Workplaces: 'Lugares de trabajo',
  'Would you like to a create a schedule': 'Le gustaría crear un horario',
  'Would you like to help us become even better?':
    '¿Le gustaría ayudarnos a mejorar?',
  'Would you like to import more data': 'Le gustaría importar más datos',
  'Would you like to obtain AGPS/GPS location for':
    '¿Le gustaría obtener la ubicación AGPS/GPS para',
  'Would you like to obtain more accurate location for':
    '¿Desea obtener una ubicación más precisa para',
  'Would you like to save the changes you made in this investigation?':
    '¿Desea guardar los cambios realizados a esta investigación?',
  'Would you like to save the latest changes you made in link analysis?':
    '¿Le gustaría guardar los últimos cambios que realizó en el análisis de enlaces?',
  'Would you like to see target profile':
    'Le gustaría ver el perfil de destino',
  'Wrong Credentials': 'Credenciales incorrectas',
  'Wrong password': 'Contraseña Incorrecta',
  'Wrong template.': 'Plantilla incorrecta.',
  'X hours and Y minutes.': 'X horas y Y minutos',
  'X minutes': 'X minutos',
  'Y hours': 'Y horas',
  Yes: 'Sí',
  'Yes, I want to cancel': 'Sí, quiero salir',
  'Yes, I want to participate': 'Sí, quiero participar',
  Yesterday: 'Ayer',
  'You are investigating': 'Está investigando el registro de llamadas de',
  ' call logs.': '.',
  'From the available CDR files, select the data you would like to load into the Explorer':
    'De los archivos CDR disponibles, seleccione los datos que desea cargar en el navegador',
  'You can select individual targets by selecting the custom button.':
    'Puede seleccionar objetivos individuales al seleccionar el botón personalizado.',
  'You can select multiple nodes by holding down Ctrl key and clicking on a node.':
    'Puede seleccionar varios nodos manteniendo presionada la tecla Ctrl y haciendo clic en un nodo.',
  'You can turn off': 'Puedes apagar',
  'You can use the Apps button to navigate to the Cases & Targets page. By clicking done, you will permanently hide this message':
    'Puede utilizar el icono de aplicaciones para acceder “a” la página de Casos y Objetivos. Haciendo click “aquí” puede ocultar “este” mensaje de forma permanente.',
  'You do not have access rights or the url is invalid':
    'No cuenta con los permisos necesarios o la URL es inválida',
  "You don't have any monitoring created": 'No ha creado ninguna supervisión',
  'You dont have permissions to use this feature':
    'No cuenta con los permisos para utilizar esta funcionalidad',
  'You have': 'Cuenta con',
  'You have entered some invalid ad ids.':
    'Ha introducido algunos AD-ID’s inválidos',
  'You have more than 1024 characters': 'Tiene más de 1024 caracteres',
  'You have more than 255 characters': 'Tiene más de 255 caracteres',
  'You have reached': 'Ha alcanzado',
  'You have reached the limit of cases used in the system. To add a new case, please go to the list of cases to delete one or contact your local administrator.':
    '',
  'You have reached the limit of targets used in the system. To add a new target, please go to the list of targets to delete one or contact your local administrator.':
    '',
  'You have reached the limit of the Cases used in the system.To add a new one, please go to the list of Cases and delete one.':
    'Ha alcanzado el límite de Casos en el sistema. Para agregar uno nuevo, por favor, vaya a la lista de casos y elimine uno.',
  'You have reached the limit of the Targets used in the system.To add a new one, please go to the list of targets and delete one.':
    'Ha alcanzado el límite de Objetivos en el sistema. Para agregar uno nuevo, por favor, vaya a la lista de objetivos y elimine uno.',
  'You have reached the limit of the cases used in the system. Contact your local administrator':
    '',
  'You have reached the limit of the targets and cases used in the system. Contact your local administrator':
    '',
  'You have reached the limit of the targets used in the system. Contact your local administrator.':
    '',
  'You have to save the investigation before uploading a file':
    'Tiene que guardar la investigación antes de cargar un archivo',
  'You ran out of available cases. Contact your local administrator.':
    'You ran out of available cases. Contact your local administrator.',
  'You ran out of available cases. To add a new case, please go to the': '',
  'You ran out of available targets and cases. Contact your local administrator':
    '',
  'You ran out of available targets and cases. Contact your local administrator.':
    'You ran out of available targets and cases. Contact your local administrator.',
  'You ran out of available targets. Contact your local administrator.':
    'You ran out of available targets. Contact your local administrator.',
  'You ran out of available targets. To add a new target, please go to the': '',
  'You selected to change OTP method from application to the following email address':
    'Ha seleccionado cambiar su método OTP de la aplicación a la siguiente dirección de correo electrónico',
  'You selected to change OTP method from application to the following phone number':
    'Ha seleccionado cambiar su método OTP de la aplicación al el siguiente número telefónico',
  'You selected to change OTP method from email to application':
    'Ha seleccionado cambiar su método OTP de correo electrónico a la aplicación',
  'You selected to change OTP method from email to the following phone number':
    'Ha seleccionado cambiar su método OTP de correo electrónico a el siguiente número telefónico',
  'You selected to change OTP method from phone number to application':
    'Ha seleccionado cambiar su método OTP de número telefónico a la aplicación',
  'You selected to change OTP method from phone number to the following email address':
    'Ha seleccionado cambiar su método OTP de número telefónico a la siguiente dirección de correo electrónico',
  'You will be redirected to login screen.':
    'Será redirigido a la pantalla de inicio de sesión.',
  'Your alerts have been set successfully':
    'Sus aleras han sido establecidas satisfactoriamente',
  'Your browser does not support video': 'Su navegador no soporta video',
  'Your browser is not capable to view in fullscreen. Please switch to a latest browser.':
    'Su navegador no soporta pantalla completa. Cambie a un navegador más reciente.',
  'Your feedback is valuable!': '¡Su opinión es muy importante!',
  'Your feedback is very valuable and will help us to improve Intellectus. We would appreciate if you stayed!':
    'Su opinión es muy importante para nosotros y nos ayudaría a mejorar Intellectus. Le agradeceríamos si se quedara.',
  'Your opinion is very important': 'Su opinión es muy importante',
  'Your password expired yesterday.': 'Tu contraseña expiró ayer.',
  'Your password expires today.': 'Tu contraseña caduca hoy.',
  'Your password expires tomorrow.': 'Tu contraseña vence mañana.',
  'Your password has expired #{days} ago.':
    'Tu contraseña ha caducado hace #{days} días.',
  'Your password will expire in #{days} days.':
    'Su contraseña caducará en #{days} días.',
  'Your query has been queued': 'Su consulta ha sido puesta en fila',
  'Your request for SOMEDUS on': 'Su solicitud de SOMEDUS para',
  'Your session is about to expire click "Continue" to keep the session active.':
    'Su sesión está a punto de caducar, haga clic en "Continuar" para mantener la sesión activa.',
  '[trilateration_nearby_people]': '',
  "a playback of the target's timeline":
    'una lista de la línea de tiempo del objetivo',
  'account has been submitted.': 'ha sido enviada.',
  active: 'activo',
  'active members': 'miembros activas',
  'active relationships found': 'relaciones activas encontradas',
  'active relationships found out of': 'relaciones activas encontradas para',
  actions: 'acciones',
  add: 'Nuevo',
  addresses: 'direcciones',
  'added to': 'añadido a',
  advanced: 'avanzada',
  advanced_osint: 'OSINT Avanzado',
  all: 'todos',
  and: 'y',
  'and delete one.': '',
  'and ended on': 'y terminó en',
  'and ended on the': 'y terminó el',
  'annual quota': 'annual quota',
  area: 'zona',
  at: 'a',
  'at year #{year}': 'del año #{year}',
  authentication: 'autenticación',
  authored: 'autor',
  'available of your queries quota': 'disponible de su cuota de consultas',
  away: 'de distancia',
  'between 50-100 meters': 'entre 50-100 metros',
  'between hours #{hour.start}:00 and #{hour.end}:00':
    'entre las #{hour.start}:00 y las #{hour.end}:00',
  'between the #{day.start}st and #{day.end}th day of a month':
    'entre el día #{day.start}st y el día #{day.end}th del mes',
  'between the #{day.start}th and #{day.end}th day of a month':
    'entre el día #{day.start}th y el día #{day.end}th del mes',
  'birth location': '',
  call: 'llamada',
  call_info: 'Información de Llamada',
  call_log_purchase: 'Compra de CDR',
  call_log_request_msisdn: 'Solicitud de CDR',
  call_log_upload_imei: 'Carga de CDR exitosa IMEI',
  call_log_upload_msisdn: 'Carga de CDR MSISDN',
  calls: '',
  cancel: 'Cancelar',
  case: 'caso',
  case_management: 'Administración de caso',
  cases: 'casos',
  'cases and targets': 'casos y objetivos',
  cell_id: 'CeldaID',
  changed: 'cambiada',
  'check in': 'lugares en',
  'click to expand.': 'haga clic para ampliar.',
  'closed databases': 'Bases de datos cerradas',
  comments: 'comentarios',
  countries: 'países',
  'create a new target': 'crear un nuevo objetivo',
  'create-user': 'crear usuario',
  created: 'creado',
  'created:': 'creado:',
  credit: 'Créditos',
  credits: 'créditos',
  'credits assigned': 'créditos asignados',
  'credits available': 'créditos disponibles',
  'credits by': 'creditos por',
  'current and previous workplaces (target)':
    'Lugar de trabajo actual y anterior (Objetivo)',
  'daily quota': 'daily quota',
  darkweb: 'Darkweb',
  'data activity': 'actividad de datos',
  'data points': 'puntos de datos',
  'data used at': 'datos utilizados en',
  day: 'día',
  days: 'días',
  'days remaining until expiration': 'días para la fecha de expiración',
  delete: 'eliminar',
  destination: 'destino',
  details: 'detalles',
  disabled: 'discapacitada',
  discovery: 'Descubrimiento',
  duration: 'duración',
  'duration & frequency': 'duración & frecuencia',
  'education (target)': 'educación (objetivo)',
  educations: 'educaciones',
  email: 'correo electrónico',
  emails: 'correos electrónicos',
  emergency: 'emergencia',
  empty: 'espacio',
  enabled: 'habilitada',
  'end of history': 'fin de la historia',
  'enter keywords': 'ingrese las palabras clave',
  entered: 'introducido',
  'entries were imported': 'las entradas fueron importadas',
  errors: 'errores',
  'expires at dd/mm/yyy hh:mm': 'expira el dd/mm/yyyy hh:mm',
  female: 'mujer',
  'filter by location': 'filtrar por ubicación',
  'filter out the selected nodes by pressing the Delete key.':
    'filtrar los nodos seleccionados presionando la tecla Eliminar.',
  filters: 'filtros',
  for: 'para',
  'for at least 2 targets from the case in order to see call log analysis':
    'para al menos 2 objetivos del caso y poder ver el análisis de registro de llamadas.',
  'for the objective': '',
  'for:': 'para:',
  found: 'encontrado',
  frequency: 'frecuencia',
  friday: 'viernes',
  from: 'de',
  'from Platform settings.': 'desde la configuración de la plataforma.',
  'full name': 'nombre completo',
  gender: 'género',
  'geo location': 'ubicación geográfica',
  'geo-fencing': 'geo-cerca',
  geolocation: 'geolocalización',
  group: 'grupo',
  groups: 'grupos',
  has: 'tiene',
  'has been captured on': 'ha sido capturado en',
  'has failed': 'falló',
  'has family relation with': 'tiene relación familiar con',
  'has finished': 'ha terminado',
  'has terminated': 'ha terminado',
  'has used by the same device': 'ha utilizado por el mismo dispositivo',
  here: 'aquí',
  high: 'alto',
  histogram: 'histograma',
  'home location': 'Domicilio',
  home_mcc: 'MCC',
  home_mnc: 'MNC',
  hour: 'hora',
  hours: 'horas',
  'hours to respond with success status.':
    'horas para responder con un estado satisfactorio.',
  idle: 'inactivo',
  image: 'imagen',
  images: 'imágenes',
  imei: 'IMEI',
  imeis: 'imeis',
  import_cdr: 'Importar Registro de llamadas',
  import_historical_locations: 'Importe de historial de ubicaciones',
  imsi: 'IMSI',
  in: 'en',
  'in order to see call log analysis':
    'para poder ver el análisis del registro de llamadas',
  inactive: 'inactivo',
  incoming: 'entrante',
  'incoming & outgoing': 'entrante & saliente',
  info: 'informacion',
  inside: 'dentro',
  "insights about your target's top associates":
    'estadísticas sobre los asociados principales de su objetivo',
  intel: 'intel',
  intel_search: 'Búsqueda de inteligencia',
  'interacted with': 'interactuó con',
  investigation: 'investigación',
  is: 'está',
  'is already included in': 'ya está incluido en',
  'is Offline. Last Known Location found':
    'está desconectada. Última ubicación conocida encontrada',
  'is deactivated': 'está desactivado',
  'is done': 'está hecho',
  'is expired': 'ha caducado',
  'is here': 'está aquí',
  'is in': 'está en',
  'is not a valid': 'no es válida',
  'is not a valid MSISDN .': 'no es un MSISDN válido .',
  'is not a valid MSISDN.': 'no es un MSISDN válido.',
  'is on emergency': 'está en emergencia',
  'is required': 'es requerido',
  item: 'artículo',
  'item selected': 'artículos seleccionados',
  items: 'artículos',
  'items found': 'artículos encontrados',
  'items on': 'artículos en',
  'items selected': 'artículos seleccionados',
  jobs: 'trabajos',
  keyword: 'palabra clave',
  'keyword list': 'lista de palabras clave',
  lac: 'LAC',
  'last 30 days': 'Últimos 30 días',
  'last 60 days': 'Últimos 60 días',
  'last 90 days': 'Últimos 90 días',
  'last seen': 'visto por última vez',
  left: 'dejado',
  less: 'menos',
  'less than 50 meters': 'menos de 50 metros',
  likes: 'gustos',
  links: 'enlaces',
  'list of cases': '',
  'list of targets': '',
  'list selected': 'lista seleccionada',
  'lists selected': 'listas seleccionadas',
  live: 'En vivo',
  'locate all': 'ubicar todo',
  located: 'localizado',
  'located at #({latitude},{longitude})':
    'ubicado en #({latitude},{longitude})',
  location: 'localización',
  'location found': 'Ubicación encontrada',
  locked: 'bloqueada',
  login: 'Inicio de Sesión',
  logon_query_location_offline:
    'Consulta de ubicación por conexión fuera de línea',
  logout: 'Cierre de Sesión',
  low: 'bajo',
  male: 'hombre',
  media: 'media',
  medium: 'medio',
  members: 'miembros',
  mentions: 'Menciones',
  messages: 'mensajes',
  'messages exchanged': 'mensajes intercambiados',
  'message exchanged': 'mensaje intercambiados',
  meters: 'metros',
  'meters or less': 'metros o menos',
  minimize: 'Minimizar',
  minute: 'minuto',
  minutes: 'minutos',
  Mission: 'Misión',
  monday: 'lunes',
  month: '',
  'monthly quota': 'monthly quota',
  months: 'meses',
  more: 'más',
  'move the selected nodes by dragging using the mouse or with the keyboard arrows.':
    'mueva los nodos seleccionados arrastrándolos con el cursor o con las flechas del teclado.',
  msisdn: 'MSISDN',
  name: 'nombre',
  'name middle surname': 'nombre segundo apellido',
  'name surname': 'nombre apellido',
  names: 'nombres',
  'nearby profiles found': 'perfiles cercanos encontrados',
  'nearby towers found': 'torres cercanas encontradas',
  neighbour_cell_towers: 'Torres cercanas',
  new: 'nuevo',
  'new MSISDN': 'nuevo MSISDN',
  'new case': 'nuevo caso',
  'new investigation': 'nueva investigación',
  'new post': 'nueva publicación',
  'new target': 'nuevo objetivo',
  nmr: 'NMR',
  nodes: 'nodos',
  'not located': 'no localizado',
  notifications: 'notificaciones',
  'number could not be located': 'número no pudo ser localizado',
  'number successfully located': 'número localizado con éxito',
  of: 'de',
  'of your queries quota': 'de su cuota de consultas',
  on: 'en',
  'on #{dayName}s': 'los #{dayName}s',
  'on #{monthName}': 'el #{monthName}',
  or: 'o',
  'or ': 'o ',
  'otp-email': 'otp email',
  'otp-phone': 'teléfono otp',
  'out of': 'fuera de',
  outgoing: 'saliente',
  outside: 'fuera',
  'over 100 meters': 'más de 100 metros',
  overall: 'en total',
  'overall activity': 'actividad general',
  'para el objetivo': 'for target',
  'passwords found': 'contraseñas encontradas',
  'people nearby': 'personas cercanas',
  'persons tagged': 'personas etiquetadas',
  phone: 'teléfono',
  phone_model: 'Modelo',
  photo: 'foto',
  'photo details': 'detalles de la foto',
  'photo(s) together': 'foto(s) juntas',
  photos: 'fotos',
  'platform selected': 'plataforma seleccionada',
  'platforms selected': 'plataformas seleccionadas',
  post: 'publicación',
  'post the last': '',
  posts: '',
  'posts the last': '',
  'profile location': 'ubicación de perfil',
  profileUrls: 'URL de perfil',
  profiles: 'Perfiles',
  published: 'publicado',
  queries: 'consultas',
  query: 'consulta',
  query_foreign_location: 'Consulta de Ubicación en Extranjero',
  query_location: 'Consulta de ubicación',
  query_location_offline: 'Consulta de Ubicación Fuera de Línea',
  queue: 'cola',
  quota: 'creditos',
  'quota reset to initial balance': 'Reinicio de cuota a balance inicial',
  'ramining targets': '',
  reactions: 'Reacciones',
  recipient: 'receptor',
  records: 'Registros',
  register: 'Registrar',
  'remaining cases': 'remaining cases',
  'remaining daily quota': '',
  'remaining monthly quota': '',
  'remaining targets': 'remaining targets',
  'remote browser': 'navegador remoto...',
  'removed successfully': 'removido satisfactoriamente',
  renewed: 'renovado',
  'request cdr': 'Solicitud de CDR',
  reset: 'reiniciar',
  'reset-password': 'restablecer la contraseña',
  results: 'resultados',
  'results found': 'resultados encontrados',
  Retrieved: 'Recuperado',
  roles: 'roles',
  saturday: 'sábado',
  saved: 'guardado',
  'scheduled task has finished': 'tarea programada ha terminado',
  'screenHeight must not be less than 480':
    'El tamaño de la pantalla no debe ser menor a 480',
  'screenWidth must not be less than 640':
    'el ancho de la pantalla no debe ser menor a 640',
  'search history': 'buscar historial',
  'search results found for': 'resultados de búsqueda encontrados para',
  second: 'segunda',
  seconds: 'segundos',
  seeds: 'semillas',
  'seeds selected': 'semillas seleccionadas',
  'select all': 'seleccionar todo',
  selected: 'seleccionada',
  sender: 'remitente',
  set: 'conjunto',
  shares: 'compartidos',
  'show less': 'Mostrar menos',
  'Show Mission Overview': 'Mostrar Resumen de Misión',
  'show more': 'mostrar más',
  'single unique profile': 'un sólo perfil único',
  'social platforms': 'plataformas sociales',
  source: 'fuente',
  sources: '',
  space: 'vacio',
  'started at': 'comenzó en',
  'started on': 'comenzó en',
  starting: 'incipiente',
  status: 'Estado',
  sub: 'sub',
  successful: 'exitosa',
  successfully: 'con éxito',
  'sucessfully logged in': 'inició sesión satisfactoriamente',
  'sucessfully logged out': 'cerró sesión satisfactoriamente',
  sunday: 'domingo',
  target: 'objetivo',
  'target photo(s)': 'foto(s) objetivo',
  'target post(s)': 'publicación(s) de objetivo',
  "target's common used routes": 'las rutas frecuentes usadas por el objetivo',
  "target's predicted locations": 'predicción de ubicaciones del objetivo',
  target_management: 'Administración de objetivos',
  targets: 'objetivos',
  'targets found': 'objetivos encontrados',
  telno: 'número de teléfono',
  telnos: 'números de teléfono',
  'text analysis': 'Análisis de texto',
  'the extention supported are:': 'Las extensión soportadas son',
  'the geofenced area': 'del área con geocerca',
  thursday: 'jueves',
  time: 'vez',
  'time(s)': 'veces',
  times: 'veces',
  'times or more': 'veces o más',
  to: 'a',
  'to be monitored, or create a': 'a monitorear, o cree una',
  'to reuse and select': 'para reutilizar, seleccione también las',
  'to the target to be able to use this feature':
    'al objetivo para poder usar esta funcionalidad',
  'to view the target profile': 'para ver el perfil del objetivo',
  trilateration_nearby_people: 'Trilaterización de personas cercanas',
  tuesday: 'martes',
  type: 'tipo',
  'unknown type': 'tipo desconocido',
  unlocked: 'desbloqueada',
  'update-user': 'actualizar usuario',
  'upload existing call log': 'suba un registro de llamadas existente',
  url: 'url',
  user: 'usuario',
  username: 'nombre de usuario',
  usernames: 'nombres de usuario',
  via: 'via',
  videos: 'videos',
  views: 'vistas',
  'voice activity': 'actividad de voz',
  was: 'estaba',
  'was Succesfull': 'fue satisfactoria',
  'was extracted successfully. Information was also added in Target Call Log Analysis':
    'se extrajo correctamente. La información ha sido añadida al Análisis del Registro de Llamadas del Objetivo',
  'was found': 'fue encontrado',
  'was found near': 'fue encontrado cerca',
  'was located during a call': 'se ha encontrado durante una llamada',
  'was located during a call.': 'Se ha encontrado durante una llamada',
  'was successfully delivered': 'fue entregado con éxito',
  'was turned off': 'estaba apagado',
  'was turned on': 'estaba encendido',
  wednesday: 'miércoles',
  week: 'semana',
  weekdays: 'entre semana',
  weekend: 'fines de semana',
  weekends: 'fines de semana',
  'will be updated at 12:00am': 'will be updated at 12:00am',
  'will be updated next month': 'will be updated next month',
  'will expire soon': 'expirará pronto',
  with: 'con',
  'with ID:': 'con ID:',
  'with a duration of': 'con una duración',
  'with msisdn': 'con msisdn',
  'workplace location': 'Trabajo',
  'x out of y': '',
  'you want to be monitored': 'que desea monitorear',
  'your browser': 'su navegador',
  'All of your request for SOMEDUS on': 'Todas sus solicitudes de Somedus para',
  'Once the infrastructure for each request is up, it will take our BOTs up to':
    'Una vez que la infraestructura esté lista, a nuestros BOTS les tomará hasta',
  'You have already requested Somedus for this target':
    'Ya ha solicitado Somedus para este objetivo',
  'Try out the new Discovery!': 'Prueba ya el nuevo Módulo de Descubrimiento!',
  'Display on map': 'Mostrar en mapa',
  'Display only sector': 'Mostrar sólo sector',
  'Latest location for': 'Última ubicación de',
  'Location for': 'Ubicación de',
  '#{numberOf} days ago': 'hace #{numberOf} dias',
  '#{numberOf} hours ago': 'hace #{numberOf} horas',
  '#{numberOf} minutes ago': 'hace #{numberOf} minutos',
  '#{numberOf} seconds ago': 'hace #{numberOf} segundos',
  'a few seconds ago': 'hace unos segundos',
  'display on map': 'mostrar en mapa',
  'No profiles found': 'No se encontraron perfiles',
  QUERIES: 'CONSULTAS',
  'Latest profile search': 'Perfiles en línea',
  Track: 'Rastrear',
  'a minute ago': 'hace un minutos',
  'an hour ago': 'hace una hora',
  'a day ago': 'hace un dia',
  subscription: 'suscripción',
  network: 'red',
  'Meet the new Discovery!': '¡Conoce el nuevo módulo de Descubrimiento!',
  "On our quest to enhance your experience we're updating the discovery module to match your needs and expectations.":
    'En nuestra búsqueda por mejorar tu experiencia, estamos actualizando el módulo de descubrimiento para hacer que tus necesidades cumplan tus expectativas.',
  'Focus on your mission': 'Enfócate en tu misión',
  'Track the movements of several targets simultaneously on a single map.':
    'Sigue el movimiento de múltiples objetivos al mismo tiempo en un mismo mapa.',
  'Explore key moments of your Mission': 'Explora momentos clave en tu misión',
  'With the time bar, you can easily focus on specific times in a mission to reveal important information.':
    'Con la barra de tiempo, puedes enfocarte fácilmente en horarios específicos en tu misión y revelar información importante.',
  'Missions on the Go': 'Misiones móviles',
  'Take your missions with you on any device, mobile or tablet.':
    'Lleva tus misiones contigo en cualquier dispositivo móvil o tablet.',
  'Dive Right In!': '¡Comienza ahora!',
  'Ready to explore? Start adding new and historical MSISDNs to the mission screen.':
    '¿Listo para empezar? Empieza a agregar MSISDN nuevos o históricos a la pantalla de misión.',
  'Find Profile': 'Buscar perfiles',
  Tracking: 'Rastreo',
  'Tracking information': 'Información de Rastreo',
  Every: 'Cada',
  until: 'hasta',
  'You are currently tracking': 'Actualmente está rastreando',
  'based on the below parameters.': 'basado en los siguientes parámetros',
  'Locating for': 'Ubicación para',
  'Locating every': 'Localizando cada',
  'Scheduled Searches': 'Búsquedas programadas',
  'Executed Searches': 'Búsquedas ejecutadas',
  'Locations Found': 'Ubicaciones encontradas',
  'Terminate tracker': 'Finalizar rastreador',
  'Tracking terminated for': 'Finalizar rastreador',
  scheduled: 'programado',
  '#{numberOf} minutes': '#{numberOf} minutos',
  '#{numberOf} hours': '#{numberOf} horas',
  '#{numberOf} days': '#{numberOf} dias',
  'Maximum count of phone numbers inside the mission has been reached.':
    'Ha alcanzado el máximo de números de teléfono dentro de una misión.',
  'On a call with': 'En una llamada con',
  Retrieve: 'Obtener',
  Retrieving: 'Obteniendo',
  'On a call': 'En llamada',
  'Incoming call from': 'Llamada de entrada desde',
  'Outgoing call to': 'Llamada de salid a',
  logon: 'conexión',
  'Something went wrong during termination of tracker for':
    'Algo salió mal al intentar terminar el rastreador para',
  'End when completed': 'Terminar cuando sea completado',
  'Terminate if location found': 'Terminar si la ubicación fue encontrada',
  'Add label': 'Agregar etiqueta',
  'Edit label': 'Editar etiqueta',
  Label: 'Etiqueta',
  'Label already exists': '',
  'Add label to': 'Agregar etiqueta a',
  'Edit label to': 'Editar etiqueta a',
  'Nearby cell towers': 'Antenas celulares cercanas',
  available: 'disponible',
  TERMINATED: 'TERMINADO',
  COMPLETED: 'COMPLETADO',
  'Scheduled Type': 'Tipo de programación',
  'Scheduled searches': 'Búsquedas programadas',
  'Executed searches': 'Búsquedas ejecutadas',
  'Locations found': 'Ubicación encontrada',
  'Terminated by user': 'Terminado por usuario',
  Terminated: 'Terminado',
  'Active Trackers only': 'Sólo localizadores activos',
  Terminate: 'Terminar',
  'Terminate Tracker': 'Terminar localizador',
  'You are about to terminate the tracking of':
    'Está a punto de terminar el localizador para',
  'Click Terminate to continue or Close to go back':
    'Clic en Terminar para continuar o Cerrar para ir atrás',
  'Locate phone & find profiles': 'Ubicar teléfono y encontrar perfiles',
  'Set up tracking': 'Configurar rastreador',
  'Set up scheduled tracking for': 'Establecer rastreador programado para',
  'Set up scheduled tracking': 'Configurar rastreador programado',
  'Specify tracking duration and frequency':
    'Especificar duración y frecuencia del rastreador',
  'Locate for': 'Localizar para',
  'Locate every': 'Localizar cada',
  'Terminate tracking when first location is found':
    'Finalizar el rastreador cuando se encuentre la primera ubicación',
  'Start Tracking': 'Iniciar rastreador',
  'Tracking started for': 'Rastreador inicio para',
  'Please enter 3 or more characters (up to 20 characters in total)':
    'Ingrese 3 o más caracteres (hasta 20 caracteres en total)',
  'Add to mission': 'Agregar a misión',
  'Query info': 'Información de consulta',
  'Query performed by': 'Consulta realizada por',
  'Submitted on': 'Enviada el',
  'Query submitted on': 'Consulta enviada el',
  'Query ID': 'ID de Consulta',
  'Query ID copied!': 'ID de consulta copiado!',
  'No Call logs found': 'No es encontró Registro de Llamadas',
  'Loading Call logs': 'Cargando Registro de Llamadas',
  'Not currently available on mobile - coming soon.':
    'Por el momento no disponible en móvil - Próximamente.',
  'Case is empty. Click the': 'Caso vacío. Haga clic en',
  'Maximum count of phone numbers inside the case has been reached.':
    'Ha alcanzado el número máximo de números de teléfono dentro de un caso.',
  'Focus on your case': 'Enfóquese en su caso',
  'Explore key moments of your Case': 'Explore momentos clave de su caso',
  'With the time bar, you can easily focus on specific times in a case to reveal important information.':
    'Con la barra de tiempo, fácilmente puede enfocarse en momentos específicos de su caso y revelar información importante.',
  'Cases on the Go': 'Casos en Movimiento',
  'Take your cases with you on any device, mobile or tablet.':
    'Tome sus casos con usted en cualquier dispositivo, móvil o tablet.',
  'Ready to explore? Start adding new and historical MSISDNs to the cases screen.':
    '¿Listo para explorar? Empiece a agregar MSISDN nuevos e históricos a su pantalla de caso.',
  'Show Case Overview': 'Mostrar Resumen de Caso',
  'Schedule Completed': 'Programación Completada',
  'Subscription deactivated': 'Suscripción desactivada',
  'Terminated by system': 'Finalizado por el sistema',
  'Unexpected platform error.': 'Error inesperado del sistema.',
  'The registered country of this number is not included in your license.':
    'El país registrado de este número no está incluido en su licencia.',
  'The operator of this number is not included in your license.':
    'El operador de este número no está incluido en su licencia.',
  'This number currently cannot be located.':
    'Por el momento este número no puede ser ubicado.',
  'Your limit of location searches has been reached.':
    'Ha alcanzado su límite de busquedas de ubicación.',
  'You do not have sufficient credits.': 'No cuenta con créditos suficientes.',
  'Number cannot be found.': 'Número no encontrado.',
  'Number found, but location could not be identified.':
    'Número encontrado pero no se pudo determinar su ubicación.',
  'Operator  is not currently covered.': 'Este operador no está soportado.',
  'Cell ID found but coordinates are not available.':
    'ID de Celda encontrado pero sus coordinadas no están disponibles.',
  'This feature is not included in your license.':
    'Esta funcionalidad no está incuida en su licencia.',
  'We have been notified. Please try again later.':
    'Hemos sido notificados. Por favor, intente de nuevo más tarde.',
  'Not possible to locate the number.': 'No es posible ubicar el número.',
  'Wait until your limit is reset and try again.':
    'Espere a que se reinicie su límite en intente de nuevo.',
  'Please contact your administrator.': 'Por favor, contacte su administrador.',
  'Please try again later.': 'Por favor, intente de nuevo.',
  'Location of': 'Ubicación de',
  'Tracking of': 'Localizador para',
  'found (Tracking terminated)': 'encontrada (Rastreador finalizado)',
  'is on a call. Retrieve caller?': 'está en una llamada. ¿Obtener llamante?',
  'Caller successfully retrieved': 'Llamante encontrado con éxito',
  'Sector successfully calculated': 'Sector calculado con éxito',
  'Nearby cell towers successfully retrieved':
    'Torres cercanas encontradas satisfactoriamente',
  'Subscription of': 'Ubicación de',
  'deactivated. (Tracking terminated)': 'encontrada (Rastreador finalizado)',
  completed: 'completado',
  'Not possible to locate this number.': 'No es posible ubicar el número.',
  '': '',
  'has been added.': 'ha sido añadido',
  'has been removed.': 'ha sido removido',
  'Subscription has been DEACTIVATED': 'La Suscripción ha sido DESACTIVADA',
  'view details': 'ver detalles',
  'Add group to Case': 'Agregar grupo al Caso',
  'Create or add to case': 'Crear o agregar al caso',
  'The group has been saved to case "#{caseName}". Click view to go to the case.':
    'El grupo ha sido guardado a un caso "#{caseName}". Haga clic ver para abrir el caso.',
  'Advertisement intelligence': 'Inteligencia por Publicidad',
  'New draft': 'Nuevo borrador',
  'Add new target MSISDN, IMSI from the input below or from your recent searches':
    'Añada un nuevo MSISDN, IMSI a continuación o desde tus búsquedas recientes',
  'MSISDN/IMSI or a Target': 'MSISDN/IMSI o un Objetivo',
  'MSISDN/IMSI': 'MSISDN/IMSI',
  'Case name': 'Nombre del Caso',
  'Enter the case name': 'Introduzca el Nombre del Caso',
  'Case name is required': 'El Nombre del Caso es Obligatorio',
  'Short description': 'Descripción breve',
  'Enter a short description of the case':
    'Introduzca una breve descripción del caso',
  'A short description is required': 'Se requiere una breve descripción',
  Saving: 'Guardar',
  'Please add targets before create new Case':
    'Por favor, añada objetivos antes de crear un nuevo caso',
  'We saved your current case and we are redirecting you to the selected case.':
    'Hemos guardado su caso actual y le estamos redirigiendo al caso seleccionado.',
  'We saved your current case and we are redirecting you to the new draft.':
    'Hemos guardado su caso actual y le estamos redirigiendo al nuevo borrador.',
  'We saved your current case.': 'Hemos guardado su caso actual',
  'Something went wrong during saving case process. Please try again.':
    'Algo salido mal durante el proceso de guardar el caso. Por favor, intente de nuevo.',
  'Select Case': 'Seleccionar caso',
  'Case not found': 'Caso no encontrado',
  'Request type': 'Tipo de solicitud',
  Discrete: 'Discreto',
  Explicit: 'Inmediato',
  'Accept risk and proceed': 'Aceptar riesgo y continuar',
  'Are you sure you would like to proceed?':
    '¿Está seguro que desea continuar?',
  'You have selected #{explicitRequestsCount} #{requestWord} to run explicitly. Explicit Somedus requests may finish faster. However, it increases the risk that the target will become aware of a takeover attempt, which will risk the activity. In case of an unsuccessful explicit attempt, it is possible that the requested accounts will not be available for additional requests.':
    'Ha seleccionado #{explicitRequestsCount} #{requestWord} a ser ejecutadas de inmediato. Solicitudes inmediatas de Somedus pueden finalizar más rápido. Sin embargo, incrementa el riesgo de que el objetivo se de cuenta del intento de toma de cuenta, lo cual pone en riesgo la actividad. En caso de presentar una solicitud inmediata fallida, es posible que las cuentas solicitadas no vuelvan a estar disponibles para solicitudes adicionales',
  request: 'solicitud',
  requests: 'solicitudes',
  'Select an account for which you would like to execute a Somedus request':
    'Seleccionar la cuenta sobre la cual desea ejecutar Somedus',
  "Target's account": 'Cuenta del objetivo',
  'First name': 'Nombre',
  optional: 'opcional',
  'Execution type': 'Tipo de ejecución',
  'Select email': 'Seleccionar email',
  control: 'control',
  Control: 'Control',
  'type first name': 'Primero ingrese Nombre',
  'type last name': 'Primero ingrese Apellido',
  'Your request for Somedus on': 'Su solicitud Somedus de la cuenta',
  'account has been submitted': 'ha sido enviada',
  'to respond with success status':
    'para responder con resultado satisfactorio',
  'associated accounts': 'cuentas asociadas',
  'e.g. Kidnapping': 'ej. Secuestro',
  'Open in Discovery': 'Abrir en Descubrimiento',
  'has been removed from case.': 'ha sido removido del caso.',
  'view group details': 'ver detalles del grupo',
  'Show targets': 'Mostrar objetivos',
  'Plot groups': 'Crear grupos',
  'Select groups': 'Seleccionar grupos',
  'Group name': 'Nombre del Grupo',
  Participants: 'Participantes',
  'MSISDN in multiple groups': 'MSISDN en múltiples grupos',
  'Search participant': 'Buscar participante',
  'Group Analysis': 'Análisis de Grupos',
  'GROUP DETAILS': 'DETALLES DEL GRUPO',
  'No content': 'Sin contenido',
  Chat: 'Chat',
  'Data analysis is underway.': 'El análisis de datos está en proceso.',
  'Thank you for your patience as we process the information.':
    'Gracias por su paciencia mientras procesamos la información.',
  'Top keywords': 'Palabras Clave',
  'Word cloud': 'Nube de Palabras',
  Multimedia: 'Multimedia',
  'Search chat': 'Buscar chat',
  'View group details': 'Ver detalles del grupo',
  'Group Details': 'Detalles del Grupo',
  'Add group to an existing case flow': '',
  'Add to existing case': 'Agregar a un caso existente',
  'view more': 'ver más',
  view: 'vista',
  participants: 'participantes',
  'Link copied': 'Enlace copiado',
  'MSISDN copied': 'MSISDN copiado',
  'User removed': 'Usuario eliminado',
  'MFA Password/key Protected Account':
    'Cuenta protegida por contraseña/clave MFA',
  'A linked email must be provided for this account':
    'Proporcione un correo electrónico vinculado para esta cuenta',
  'A correct fullname must be provided for this account':
    'Proporcione un nombre completo para esta cuenta',
  'The selected account is not valid': 'La cuenta seleccionada no es válida',
  'MSISDN is Deactivated': 'El MSISDN está desactivado',
  'No account found for the requested MSISDN':
    'No se encontró ninguna cuenta para el MSISDN solicitado',
  'Takeover blocked due to spam, retry in 2 days':
    'Toma de control bloqueada debido a spam, intente de nuevo en 2 días',
  'Account does not support SMS 2FA': 'La cuenta no admite 2FA por SMS',
  'No data found for this account': 'No se encontraron datos para esta cuenta',
  'Must perform Control first': 'Debe realizar Toma de Control primero',
  'Provided name was invalid': 'El nombre proporcionado no es válido',
  'Latest location': 'Última ubicación',
  'Area selected': 'Área seleccionada',
  'Please select an area on the map':
    'Por favor, seleccione un área en el mapa',
  'Retrieving the data': 'Recuperando datos',
  'Start analysing': 'Empezar a analizar',
  Searching: 'Buscando',
  'Name of the search': 'Nombre de la búsqueda',
  'Use the map tools to draw an Area of Interest':
    'Utilice las herramientas del mapa para dibujar un Área de Interés',
  'Analyse specific Ad IDs': 'Analizar Ad ID específicos',
  'Enter Ad ID': 'Ingrese Ad ID',
  'Enter specific ad ids': 'Ingrese Ad IDs específicos',
  'Select start and end date': 'Seleccione fecha de inicio y fin',
  'Customized Search': 'Búsqueda personalizada',
  'Select Parameter': 'Seleccionar parámetro',
  Parameter: 'Parámetro',
  'Customise your search in the Area of interest by adding metadata as parameters with their value':
    'Personalice su búsqueda en el Área de interés añadiendo metadatos como parámetros con su respectivo valor',
  'App used': 'App utilizada',
  'Device type': 'Tipo de dispositivo',
  'Device ip': 'IP del dispositivo',
  'Connection type': 'Tipo de conexión',
  'Session created': 'Sesión creada',
  'Session results for "#{sessionName}" are ready':
    'Los resultados de la sesión "#{sessionName}" están listos',
  'Type the value': 'Tipo de valor',
  'Get deeper insights. Start running Somedus requests today':
    'Obtenga información profunda. ¡Empiece a solicitar Somedus hoy!',
  Refine: 'Refinar',
  'Total data points': 'Puntos de datos totales',
  'Copy and paste the url of an image here':
    'Copie y pegue aquí la url de una imagen',
  'Add the name of the person that depict in the uploaded image':
    'Agregue el nombre de la persona que aparece en la imagen',
  'Search by image': 'Buscar por imagen',
  'Search image': 'Buscar imagen',
  'the extension supported are: .jpg, jpg, .png':
    'las extensiones soportadas son: .jpg, jpg, .png',
  'Remove file': 'Eliminar archivo',
  'Add to': 'Agregar a',
  Rename: 'Renombrar',
  Updating: 'Actualizando',
  'Rename session': 'Renombrar sesión',
  'Session name': 'Nombre de la sesión',
  'e.g. Airport': 'ej. Aeropuerto',
  'Session has been renamed': 'La sesión ha sido renombrada',
  'Session name already exists': 'El nombre de la sesión ya existe',
  'Remove Session?': '¿Remover sesión?',
  'Are you sure you want to remove this session?':
    '¿Está seguro que desea remover esta sesión?',
  'Session has been removed': 'La sesión ha sido removida',
  TRACKERS: 'LOCALIZADORES',
  'Discovery History': 'Descubrimiento Historial',
  'Created At': 'Creado El',
  failed: 'falló',
  pending: 'pendiente',
  expired: 'caducado',
  'access granted': 'acceso concedido',
  'Traveled to': 'Viajó a',
  'but no location found': 'pero no se encontró ubicación',
  'Target was last located in': 'El objetivo fue encontrado en',
  'Target was last seen in': 'Objetivo visto por última vez en',
  ago: 'hace',
  'Returned to': 'Regresó',
  'The previous location of the target was in':
    'La ubicación anterior del objetivo fue en',
  'History of locations abroad': 'Historial de ubicaciones en el extranjero',
  'Traveled from': 'Viajó desde',
  'Target was last located abroad in':
    'El objetivo fue ubicado en el extranjero en',
  before: 'antes',
  'History of devices': 'Historial de dispositivos',
  'Copy ID': 'Copiar ID',
  'Retrieved MSISDNs': 'MSISDNs obtenidos',
  'Changed device': 'Cambió dispositivo',
  License: 'Licencia',
  'Photo details': 'Detalles de la foto',
  'Would you like to save the changes you made in this case?':
    '¿Desea guardar los cambios hechos en este caso?',
  'Deleted target': 'Objetivo eliminado',
  'Date interval': 'Intervalo de fechas',
  'View summary': 'Ver resumen',
  'Multi sessions': 'Multiples sesiones',
  'Filtering the data': 'Filtrando datos',
  'Add Filters To Multi Sessions': 'Agregar filtros a múltiples sesiones',
  'Apply filters': 'Aplicar filtros',
  'Add Filters': 'Agregar filtros',
  'App Publisher Id': 'ID Publicador de la app',
  'Device Type': 'Tipo de Dispositivo',
  'Device Make': 'Fabricante Dispositivo',
  'Device Model': 'Modelo Dispositivo',
  'Device OS': 'Dispositivo OS',
  'Device OS Version': 'Versión Dispositivo OS',
  'Device Language': 'Idioma Dispositivo',
  'User Agent': 'User Agent',
  'User Year Of Birth': 'Año de nacimiento del usuario',
  'User YOB': 'FDN del Usuario',
  City: 'Ciudad',
  Isp: 'Isp',
  BSSID: 'BSSID',
  SSID: 'SSID',
  'Cell MCC': 'Cell MCC',
  'Cell MNC': 'Cell MNC',
  'Cell LAC': 'Cell LAC',
  'Isp Type': 'Tipo Isp',
  'Sim Carrier': 'Operador Sim',
  'Sim Country Code': 'Sim Codigo Pais',
  'GPS Accuracy': 'Precision GPS',
  Parameters: 'Parámetros',
  'WEBINT (Offline)': 'WEBINT (Offline)',
  'WEBINT (Online)': 'WEBINT (Online)',
  'Query type': 'Tipo de consulta',
  'Query type is': 'El tipo de consulta es',
  'Historical locations': 'Historial de ubicaciones',
  date: 'fecha',
  dates: 'fechas',
  'with locations for this number on': 'con ubicaciones para este número el',
  'with locations for this number between':
    'con ubicaciones para este número entre',
  Jan: 'Ene',
  Feb: 'Feb',
  Mar: 'Mar',
  Apr: 'Abr',
  Jun: 'Jun',
  Jul: 'Jul',
  Aug: 'Ago',
  Sep: 'Sep',
  Oct: 'Oct',
  Nov: 'Nov',
  Dec: 'Dic',
  'Choose the dates that you like to import':
    'Seleccione las fechas que le gustaría importar',
  locations: 'ubicaciones',
  clear: 'limpiar',
  'Successfully imported': 'Importación exitosa',
  'historical location to': 'ubicación histórica para',
  'historical locations to': 'ubicaciones históricas para',
  'The maximum amount of characters are': 'La cantidad máxima de caracteres es',
  Private: 'Privada',
  Public: 'Público',
  '#{targetsCount} of your targets participate in the "#{caseName}" groups':
    '#{targetsCount} de sus objetivos participaran en los grupos "#{caseName}"',
  '#{targetsCount} of your targets participate in the "#{caseName}" group':
    '#{targetsCount} de sus objetivos participaran en el grupo "#{caseName}"',
  'Add targets to Case': 'Agregar objetivos al Caso',
  'Targets found': 'Objetivos encontrados',
  'Opened Clusters': 'Clústeres abiertos',
  'Frequent Ad IDs': 'Ad IDs Frecuentes',
  Occurrence: 'Ocurrencia',
  'Ad ID': 'Ad ID',
  'IP Addresses': 'Dirección IP',
  SSIDs: 'SSIDs',
  BSSIDs: 'BSSIDs',
  'This device was used by': 'Este dispositivo fue utilizado por',
  'other unknown': 'otro desconocido',
  'You can view them by clicking on the button.':
    'puedes verlas haciendo clic en el botón.',
  'unknown MSISDN': 'desconocido MSISDN',
  'unknown MSISDNs': 'desconocido MSISDNs',
  'Devices used with': 'Dispositivos utilizados con',
  'Devices with multiple MSISDNs': 'Dispositivos con múltiples MSISDNs',
  'IMEI copied!': 'IMEI copiada!',
  'in discovery': 'en descubrimiento',
  'in the case': 'en el caso',
  custom: 'personalizado',
  'Simple location tracker': 'Rastraeador de ubicación simple',
  'Track until first location is found for each target':
    'Rastrear hasta que se encuentre la ubicación para cada objetivo',
  'Track until first location is found':
    'Rastrear hasta que se encuentre la ubicación',
  'Define the tracking details.': 'Definir los detalles del rastreador.',
  'Specify the type of the tracking.': 'Especificar el tipo de rastreador.',
  'Tracking started for all targets in the case':
    'Rastreador iniciado para todos los objetivos en el caso',
  'All targets': 'Todos los objetivos',
  'all targets': 'todos los objetivos',
  'Scheduler - track targets for a specific duration and frequency':
    'Programación - Rastreé objetivos durante una duración y frecuencia específica',
  'Scheduler - track the target for a specific duration and frequency':
    'Programación - realice un seguimiento de su objetivo por una duración y frecuencia específica',
  'Logon - track targets for a specific duration and frequency until a location is found for each target':
    'Conexión - Rastreé objetivos durante una duración y frecuencia específica hasta que una ubicación sea encontrada para cada objetivo',
  'Logon - track the target for a specific duration and frequency until a location is found':
    'Conexión - realice un seguimiento de su objetivo por una duración y frecuencia específica hasta que se encuentre su ubicación',
  'Tracking started for #{targetsCount} targets in the case':
    'Rastreador iniciado para los objetivos #{targetsCount} en el caso',
  'Search by target alias only': 'Buscar solo por Alias de Objetivo',
  'End when targets are in #{distance} proximity':
    'Terminar cuando los objetivos estén en una proximidad de #{distance}',
  'Enter the proximity distance in meters':
    'Ingrese la distancia de proximidad en metros',
  'proximity distance is required': 'La distancia de proximidad es requerida',
  'please use only number for proximity distance':
    'Por favor, use solo números para la distancia de proximidad',
  'Proximity distance:': 'Distancia de proximidad:',
  'Track proximity of targets': 'Rastrear la proximidad de los objetivos',
  'Track targets for a specific duration and frequency and receive alerts when they are close together.':
    'Rastrear objetivos por una duración y frecuencia específica y recibir alertas cuando estén cerca entre sí.',
  'proximity distance should not exceed #{maxDistance} meters':
    'La distancia de proximidad no debe de exceder los #{maxDistance} metros.',
};
