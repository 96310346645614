import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Address } from 'datalayer/models/platform-models/geolocation/address';
import { BaseService } from 'src/app/services/base.service';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { ProfileService } from '../../data-layer/services/social/profile/profile.service';
import { uniq } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class MergeDataHelperService extends BaseService {
  constructor(
    protected router: Router,
    protected snackBar: MatSnackBar,
    private profileService: ProfileService
  ) {
    super(router, snackBar);
  }

  mergeData(target: TargetItem, targetData: unknown): TargetItem {
    if (!targetData) return target;

    Object.entries(targetData).forEach(([key, value]: [string, unknown]) => {
      if (key === 'addresses') {
        value = this.getAddresses(value as Address[]);
      }

      if (key === 'nik' && !!value) {
        value = [value];
      }

      if (key === 'email') {
        key = 'emails';
        value = [value];
      }

      if (key === 'gender' && value) {
        value = this.transformGender(value as string);
      }

      if (key === 'relationshipStatus' && value) {
        target['maritalStatus'] = targetData[key];
      }

      if (Array.isArray(target[key]) && Array.isArray(value)) {
        const filteredValue = value
          .filter((item) => !target[key].includes(item))
          .map((item) => this.trimValues(item))
          .filter((item) => !!item);

        target[key].push(...filteredValue);
        target[key] = uniq(target[key]);
      } else if (!target[key]) {
        target[key] = this.trimValues(value);
      }
    });

    return target;
  }

  getAddresses(addresses: Address[]): string[] {
    if (!Array.isArray(addresses)) return [];

    return addresses.map((address) => {
      if (typeof address === 'string') {
        return address;
      }

      return address.country
        ? `${address.display}, ${address.country}`
        : address.display;
    });
  }

  private trimValues(value: unknown): unknown {
    if (typeof value === 'string') {
      return value.trim();
    }

    if (Array.isArray(value)) {
      return value.map((item) => this.trimValues(item));
    }

    return value;
  }

  private transformGender(value: string): string {
    value = value.toLowerCase();
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}
