import { Injectable } from '@angular/core';
import { MapStyleThemes } from 'src/app/account/discovery/models/MapStyles.model';
import { AuthResponse } from '../authentication/dto';
import { TransientUserWithJWTClaims } from '../authentication/types';

const PREVIOUS_TARGET_QUERIED = 'previousTargetQueried';
const AUTH_DATA = '__ss';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  getAuthToken(): string {
    return this.getSessionData()?.token;
  }

  getCurrentUser(): TransientUserWithJWTClaims {
    const currentUser = this.getSessionData()?.current_user;
    return currentUser;
  }

  getPreviousTargetQueried(): string {
    return localStorage.getItem(PREVIOUS_TARGET_QUERIED);
  }

  setCurrentPreviousTarget(number: string) {
    localStorage.removeItem(PREVIOUS_TARGET_QUERIED);
    localStorage.setItem(PREVIOUS_TARGET_QUERIED, number);
  }

  clearPreviousTarget() {
    localStorage.removeItem(PREVIOUS_TARGET_QUERIED);
  }

  clear() {
    const language = this.getLangauge();
    localStorage.clear();
    if (language) {
      this.setLanguage(language);
    }
  }

  public createCaseAnalyticsEntry(caseId: string) {
    localStorage.setItem(caseId, JSON.stringify({}));
  }

  public setLanguage(language: string) {
    localStorage.setItem('defaultLanguage', language);
  }

  public getLangauge() {
    return localStorage.getItem('defaultLanguage');
  }

  public setCaseAnalyticsData(caseId: string, key: string, data: any) {
    const savedData = JSON.parse(localStorage.getItem(caseId));
    savedData[key] = data;
    localStorage.setItem(caseId, JSON.stringify(savedData));
  }

  public getCaseAnalyticsData(caseId: string) {
    return JSON.parse(localStorage.getItem(caseId));
  }

  public removeCaseAnalyticsData(caseId: string) {
    localStorage.removeItem(caseId);
  }

  public getSessionData(): AuthResponse {
    try {
      return JSON.parse(localStorage.getItem(AUTH_DATA));
    } catch (error) {
      console.error(error);
    }
  }

  public setSessionData(data: AuthResponse) {
    try {
      localStorage.setItem(AUTH_DATA, JSON.stringify(data));
    } catch (error) {
      console.error(error);
    }
  }

  public setMapStyles(layerData: MapStyleThemes) {
    try {
      localStorage.setItem(`mapStyle`, layerData);
    } catch (error) {
      console.error(error);
    }
  }

  public getMapStyles(): MapStyleThemes {
    return localStorage.getItem(`mapStyle`) as MapStyleThemes;
  }

  setShowDiscoveryOnboarding(show: boolean) {
    localStorage.setItem('showDiscoveryOnboarding', show ? 'true' : 'false');
  }

  getShowDiscoveryOnboarding(): boolean {
    return localStorage.getItem('showDiscoveryOnboarding') === 'true'
      ? true
      : false;
  }
}
