<div class="intel-snackbar-flex-wrapper">
  <div class="main-wrapper">
    <span class="intel-snackbar">{{ text }}</span>

    <intellectus-icon-text-button
      class="action"
      [buttonIcon]="{ family: 'solid', name: 'xmark' }"
      [buttonSize]="'xsmall'"
      (buttonClick)="close()"
    ></intellectus-icon-text-button>
  </div>

  <intellectus-text-button
    [type]="'secondary-alt'"
    [size]="'xsmall'"
    (click)="navigate()"
    >{{ 'view' | translate }}</intellectus-text-button
  >
</div>
