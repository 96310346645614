export enum CaseAction {
  CaseDeletion = 'caseDeletion',
  CaseCreation = 'caseCreation',
  CaseRetrieval = 'caseRetrieval',
  CaseListing = 'caseListing',
  CaseTargetsRetrieval = 'caseTargetsRetrieval',
  CaseAddSocialEntities = 'caseAddSocialEntities',
  CaseFiltering = 'caseFiltering',
  CaseRenewal = 'caseRenewal',
  // Add more actions if needed
}
