import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NavigationEnd, Router } from '@angular/router';
import { isEqual } from 'lodash-es';
import { delay, distinctUntilChanged, filter, startWith } from 'rxjs/operators';
import { BaseComponent } from 'src/app/base/base.component';
import { ContactFormComponent } from 'src/app/components/contact-form/contact-form.component';
import { ResetPasswordComponent } from 'src/app/components/reset-password/reset-password.component';
import { TasksComponent } from 'src/app/components/tasks/tasks.component';
import {
  ExtraLocationInformationDialogComponent,
  extraLocationInformationTypes,
} from 'src/app/modules/trg-common/components/extra-location-information-dialog/extra-location-information-dialog.component';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { knowledgeBaseTooltips } from './models/knowledge-base-tooltips.model';
import { NavbarConstants, NavbarService } from './navbar.service';
// TODO: import applicationStateService in BaseComponent
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import {
  FontAwesomeFamily,
  FontAwesomeIcon,
} from '@fe-platform/shared-ui/intellectus';
import { UntilDestroy } from '@ngneat/until-destroy';
import { fromEvent, take } from 'rxjs';
import { ApplicationStateService } from 'src/app/services/application/application-state.service';
import { AuthService } from 'src/app/services/authentication/auth.service';
import {
  DashboardService,
  DashboardView,
} from 'src/app/services/dashboard/dashboard.service';
import { InstantMessagesStore } from 'src/app/services/instant-messages.store';
import { QueryExtractPeerService } from 'src/app/services/query/query-extract-peer.service';
import { RoleManagementService } from 'src/app/services/roles/role-management.service';
import { SiteSettingsService } from 'src/app/services/site-settings.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { User } from 'src/app/services/user/user.model';
import { UserService } from 'src/app/services/user/user.service';
import { WsService } from 'src/app/services/websocket/ws.service';
import {
  ExportPeerAction,
  ExtractPeerActionResult,
  ExtractPeerDialogComponent,
} from 'src/app/shared/components/extract-peer-dialog/extract-peer-dialog.component';
import { ApplicationMainPageUrls } from 'src/app/shared/models/application-main-page-urls.enum';
import { NavbarIdentifier } from 'src/app/shared/models/navbar-identifier.enum';
import {
  NetworkStatus,
  Query,
  QueryStatus,
  SubscriptionStatus,
} from 'src/app/shared/models/query-item.model';
import {
  LanguageOptions,
  SiteSettings,
  SupportRequest,
  UserRole,
} from 'src/app/shared/models/site-settings.model';
import { Skins, Themes } from 'src/app/shared/models/skins.model';
import {
  matomoActions,
  matomoCategories,
} from 'src/app/shared/values/matomo-config';
import { ThemeHelper } from '@shared/util/theme.helper';

@UntilDestroy()
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NavbarService],
})
export class NavbarComponent extends BaseComponent implements OnInit {
  @Input()
  public title: string;

  @Input('isMobile')
  public set isMobileSetter(isMobile: boolean) {
    this.isMobile = isMobile;
    this.setMobileMenuState(false);
    this.openNotificationList(false);
  }

  @Input()
  public highlightClass: string;

  @Output() emitHeight = new EventEmitter<number>();

  @Output()
  public readonly onSearchIconClick: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('targetHeight') targetElement: any;
  @ViewChild('logoContainer') logoContainer: ElementRef<HTMLDivElement>;
  @ViewChild('usernameContainer') usernameContainer: ElementRef<HTMLDivElement>;
  @ViewChild('routeWrpContainer') routeWrpContainer: ElementRef<HTMLDivElement>;
  @ViewChild('mobileNavContainer')
  mobileNavContainer: ElementRef<HTMLDivElement>;
  @ViewChild('navActions') navActions: ElementRef<HTMLDivElement>;
  @ViewChild('matToolbar') matToolbar: ElementRef<HTMLElement>;
  @ViewChild('topWrapper') topWrapper: ElementRef<HTMLDivElement>;

  public isMobile: boolean;
  public mobileMenuState = false;
  public notificationsMenuState = false;
  public isScrollable = false;
  public mobileNavMarginTop = 0;

  public signOutIcon: FontAwesomeIcon = {
    name: 'arrow-right-from-bracket',
    family: FontAwesomeFamily.SOLID,
  };

  readonly closeIcon: FontAwesomeIcon = {
    name: 'xmark',
    family: FontAwesomeFamily.SOLID,
  };

  returnUrl: string;
  skin: string;
  sessionUser = this.localStorageService.getCurrentUser();
  user: User;
  isAdmin = false;
  isSupport = false;
  isPower = false;
  isMobileResolution: boolean;
  enableSupportRequest = true;
  theme: Themes;
  isGeolocTheme = false;
  isWhiteTheme = false;
  flavor: string;
  navBarIdentifier: NavbarIdentifier = NavbarIdentifier.MAINNAVBAR;
  fullNavbar = false;
  navbarBehaviour = true;
  matomo = {
    actions: matomoActions,
    categories: matomoCategories,
  };
  knowledgeBaseTooltips: { [key in Themes]: string } = knowledgeBaseTooltips;
  knowledgeBaseTooltip: string;
  accurateLocation: boolean;
  showfloatingMsg: boolean;
  siteSettings: SiteSettings;
  availableExtractPeerActions = {
    getInfoAndLocate: ExportPeerAction.GET_INFO_AND_LOCATE,
    getInfo: ExportPeerAction.GET_INFO,
  };
  themeClasses: { [key in Themes]: string } = {
    [Themes.GEOLOC]: '',
    [Themes.WHITE]: '',
    [Themes.GEO]: '',
    [Themes.FRESHVALE]: 'f',
    [Themes.UNLIMITED]: '',
    [Themes.GENERSHIELD]: 'genershield-theme',
    [Themes.CLARITY]: 'clarity-theme',
  };

  usernameDisplayValue: string;
  oldDiscoveryEnabled = this.appConfigService.getConfigVariable(
    'oldDiscoveryEnabled'
  );

  public mobilePlatformSettingsOpened = false;
  public mobileUserSettingsOpened = false;

  private readonly timeToWait = 5000;
  navbarConstants: NavbarConstants;
  private readonly menuOpenedClass: string = 'menu-opened';
  private readonly notificationsOpenedClass: string = 'notifications-opened';

  constructor(
    private authSessionService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    private applicationStateService: ApplicationStateService,
    private dashboardService: DashboardService,
    private roleManagementService: RoleManagementService,
    private appConfigService: AppConfigService,
    private websocketService: WsService,
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private siteSettingsService: SiteSettingsService,
    private queryExtractPeerService: QueryExtractPeerService,
    private instantMessagesStore: InstantMessagesStore,
    public translationService: TranslationService,
    private navbarService: NavbarService,
    private cdRef: ChangeDetectorRef,
    private readonly renderer2: Renderer2
  ) {
    super();
    this.subscriptions.push(
      this.userService.getCurrentUser().subscribe((user: User) => {
        this.user = user;
        this.usernameDisplayValue = this.isSupport
          ? `${user.username} (${this.sessionUser.email})`
          : user.username;
      })
    );

    this.isMobileResolution =
      this.applicationStateService.getIsMobileResolution();
    this.theme = this.appConfigService.getConfigVariable('theme');
    this.flavor = this.applicationStateService.getSkin();
    // check when we change route
    this.router.events
      .pipe(
        filter(
          (event: NavigationEnd): boolean => event instanceof NavigationEnd
        ),
        startWith(this.router),
        distinctUntilChanged()
      )
      .subscribe((event: NavigationEnd): void => {
        this.navbarBehaviour =
          event.url !== `/${ApplicationMainPageUrls.WEBINT}` &&
          !event.url.includes(`${ApplicationMainPageUrls.WEBINT}(modal:`);
      });
  }

  ngOnInit() {
    this.appConfigService.authenticatedConfigLoaded$
      .pipe(
        filter((isLoaded) => !!isLoaded),
        take(1)
      )
      .subscribe(() => {
        this.navbarConstants = this.navbarService.getNavbarConstants();
        this.getHeaderHeightAndEmitToParent();
        this.listenResize();
      });
    this.knowledgeBaseTooltip =
      this.knowledgeBaseTooltips[this.theme] ||
      this.knowledgeBaseTooltips[Themes.GEO];
    if (this.theme === Themes.GEOLOC) {
      this.isGeolocTheme = true;
    }

    if (this.theme === Themes.WHITE) {
      this.isWhiteTheme = true;
    }

    const skinSubscription = this.applicationStateService.skin.subscribe(
      (data: Skins) => {
        this.skin = data;
      }
    );

    const webSocketAdvancedGeoSubscription = this.websocketService
      .onEvent('state-update-geolocation')
      .subscribe((query: Query) => {
        if (
          query?.gps?.locationType &&
          query?.createdBy === this.sessionUser.identity
        ) {
          if (!this.isMobile) {
            this.openExtraLocationInformationDialog(
              extraLocationInformationTypes.GPS,
              query
            );
          }
        } else if (
          query &&
          query.nmr &&
          (query.nmr.trialterationLocation || query.nmr.sectorLocation) &&
          query.createdBy === this.sessionUser.identity &&
          query['tag'] !== '' &&
          !query['tag'] &&
          !this.accurateLocation
        ) {
          if (!this.isMobile) {
            this.openExtraLocationInformationDialog(
              extraLocationInformationTypes.NMR,
              query
            );
          }
        }

        const validStatusForActiveQuery = [
          SubscriptionStatus.ACTIVE,
          NetworkStatus.ONLINE,
          NetworkStatus.BUSY,
        ];
        if (
          query?.supportActiveQuery &&
          query.status === QueryStatus.DONE &&
          query?.cell?.lac &&
          !query?.cell?.cellId &&
          validStatusForActiveQuery.includes(
            NetworkStatus[query.device.networkStatus]
          ) &&
          query.createdBy === this.sessionUser.identity
        ) {
          this.openExtraLocationInformationDialog(
            extraLocationInformationTypes.ACTIVE_LOCATE,
            query
          );
        }

        if (
          query?.queryArgs?.telno &&
          query.status !== QueryStatus.PENDING &&
          !query?.instantMessageProfiles
        ) {
          this.instantMessagesStore.fetchAllImPlatforms(
            query.queryArgs.telno,
            query.id
          );
        }
      });

    const linkAnalysisStateSubscription =
      this.applicationStateService.waitToSaveGraphOnLogout.subscribe((data) => {
        if (data.proceed) {
          this.authSessionService.logout();
        }
      });

    const webSocketSubscriptionCallInfoQuery = this.websocketService
      .onEvent('state-update-advanced-call-info')
      .pipe(delay(2000))
      .subscribe((query: Query) => this.callInfoPopUp(query));

    this.subscriptions.push(
      skinSubscription,
      webSocketAdvancedGeoSubscription,
      linkAnalysisStateSubscription,
      webSocketSubscriptionCallInfoQuery
    );
    this.isAdmin = this.roleManagementService.userIsAdmin();
    this.isSupport = this.roleManagementService.userIsSupportUser();
    this.isPower = this.roleManagementService.userIsPowerUser();

    this.enableCurrentAccurateLocationSetting();

    this.subscriptions.push(
      this.siteSettingsService
        .getStoreSiteSettings()
        .pipe(distinctUntilChanged(isEqual))
        .subscribe((settings: SiteSettings) => {
          this.siteSettings = settings;
          settings.supportRequest.forEach((setting: SupportRequest) => {
            if (
              this.roleManagementService.userIsStandardUser() &&
              setting.value === UserRole.USERS
            ) {
              this.enableSupportRequest = setting.checked;
            }

            if (
              this.roleManagementService.userIsPowerUser() &&
              setting.value === UserRole.POWER_USERS
            ) {
              this.enableSupportRequest = setting.checked;
            }
          });
        })
    );
  }

  public changeLanguage(event: MatSelectChange): void {
    this.translationService.language = <LanguageOptions>event.value;
    this.translationService.languageChange.next(<LanguageOptions>event.value);
    this.siteSettingsService.updateSiteLanguage(<LanguageOptions>event.value);
    this.localStorageService.setLanguage(<LanguageOptions>event.value);
    this.cdRef.markForCheck();
  }

  private callInfoPopUp(query: Query): void {
    if (
      !!this.siteSettings.extractPeerInformation ||
      !!this.siteSettings.extractPeerInformationAndLocate
    ) {
      this.queryExtractPeerService.extractPeer({
        extractPeerInformationAndLocate:
          !!this.siteSettings.extractPeerInformationAndLocate,
        query,
      });
      return;
    }
    this.dialog
      .open(ExtractPeerDialogComponent, {
        data: {
          query,
          extractPeerInformationAndLocate:
            this.siteSettings.extractPeerInformationAndLocate || false,
          extractPeerInformation:
            this.siteSettings.extractPeerInformation || false,
        },
        panelClass: 'extract-peer',
      })
      .afterClosed()
      .pipe(filter((actionResult) => !!actionResult))
      .subscribe((actionResult: ExtractPeerActionResult) => {
        if (actionResult.action === ExportPeerAction.GET_INFO) {
          this.queryExtractPeerService.extractPeer({
            extractPeerInformationAndLocate: false,
            query,
          });
        }
        if (actionResult.action === ExportPeerAction.GET_INFO_AND_LOCATE) {
          this.queryExtractPeerService.extractPeer({
            extractPeerInformationAndLocate: true,
            query,
          });
        }
        if (actionResult.selection) {
          this.updateSiteSettings({
            extractPeerInformationAndLocate:
              actionResult.selection === ExportPeerAction.GET_INFO_AND_LOCATE,
            extractPeerInformation:
              actionResult.selection === ExportPeerAction.GET_INFO,
          });
        }
      });
  }

  private updateSiteSettings(settings: Partial<SiteSettings>): void {
    this.siteSettingsService.updateSiteSettings(settings).subscribe();
  }

  changeExtractPeerAction(
    checkboxValue: boolean,
    action: ExportPeerAction
  ): void {
    this.siteSettingsService
      .updateSiteSettings(<Partial<SiteSettings>>{
        [action]: checkboxValue,
        [action === ExportPeerAction.GET_INFO
          ? ExportPeerAction.GET_INFO_AND_LOCATE
          : ExportPeerAction.GET_INFO]: false,
      })
      .subscribe();
  }

  enableCurrentAccurateLocationSetting() {
    this.subscriptions.push(
      this.userService
        .getCurrentAccurateLocation(
          this.roleManagementService.currentUser.identity
        )
        .subscribe((accurateLocation: boolean) => {
          this.accurateLocation = accurateLocation;
          this.userService.handlingAccurateLocation.next(accurateLocation);
        })
    );
  }

  public changeAccurateLocation(flag: boolean): void {
    this.accurateLocation = flag;
    this.subscriptions.push(
      this.userService
        .changeAccurateLocation(
          flag,
          this.roleManagementService.currentUser.identity
        )
        .subscribe((accurateLocation: boolean) => {
          this.userService.handlingAccurateLocation.next(flag);
        })
    );
  }

  logout() {
    if (this.router.url.includes('link-analysis')) {
      this.applicationStateService.waitToSaveGraphOnLogout.next({
        logout: true,
        proceed: false,
      });
      return;
    }
    this.authSessionService.logout();
  }

  userManual() {
    let filePath = `/assets/static/files/user_manual_${this.appConfigService.getLanguage()}_GEO.pdf`;
    if (this.theme === Themes.GEOLOC) {
      filePath = '/assets/static/files/user_manual_es_GEOLOC.pdf';
    }
    window.open(filePath, '_blank');
  }

  public async openKnowledgeBaseTab() {
    const kbUsername = this.appConfigService.getConfigVariable('kbUsername');
    const kbPassword = this.appConfigService.getConfigVariable('kbPassword');
    const url = this.appConfigService.getConfigVariable('knowledgeBaseUrl');
    const newWindow = window.open(
      `https://${kbUsername}:${kbPassword}@${url}`,
      '_blank'
    );
    setTimeout(() => {
      newWindow.location.href = 'https://intellectus.knewise.xyz';
    }, 700);
  }

  public async openLicenseTab() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/license'])
    );
    window.open(url, '_blank');
  }

  public redirectTo() {
    if (this.isMobile) {
      return;
    }

    if (
      (this.navbarConstants.enabledLandingScreen ||
        this.flavor === Skins.GEO4) &&
      this.router.url !== `/${ApplicationMainPageUrls.WEBINT}`
    ) {
      this.dashboardService.componentsView.next(DashboardView.LOG);
      this.router.navigate([ApplicationMainPageUrls.WEBINT]);
    } else if (this.flavor === Skins.CVTP) {
      this.router.navigate([ApplicationMainPageUrls.CORE]);
    }
  }

  openSupportForm(): void {
    this.dialog.open(ContactFormComponent, {
      width: '30vw',
      height: 'auto',
      panelClass: this.isGeolocTheme
        ? 'geoloc-theme'
        : this.isWhiteTheme
        ? 'white-theme'
        : '',
    });
  }

  openTasksDialog(flag): void {
    this.dialog.open(TasksComponent, {
      width: 'auto',
      height: '85vh',
      data: flag, // canceled task = true OR false
      panelClass: this.isGeolocTheme
        ? 'geoloc-theme'
        : this.isWhiteTheme
        ? 'white-theme'
        : '',
    });
  }

  public getLogoText(): string {
    if (this.flavor === Skins.CVTP) {
      return 'CVTP';
    } else if (this.theme === Themes.FRESHVALE) {
      return 'Freshvale';
    }
  }

  public getLogo(): string {
    if (this.flavor === Skins.CVTP) {
      return 'assets/static/images/cvd-logo.svg';
    }
    const dark = this.theme !== Themes.CLARITY;
    return ThemeHelper.getLogo(this.theme, dark);
  }

  public navigateToLinkAnalysis() {
    this.router.navigate([`${ApplicationMainPageUrls.WEBINT}/link-analysis`]);
  }

  getHeaderHeightAndEmitToParent() {
    setTimeout(() => {
      if (!this.mobileMenuState) {
        const headerHeight = this.targetElement.nativeElement.offsetHeight;
        this.emitHeight.emit(headerHeight);
      }
    }, 100);
  }

  public openChangePasswordDialog() {
    this.dialog.open(ResetPasswordComponent, {
      width: this.isMobileResolution ? '100vw' : '25vw',
      height: 'auto',
      panelClass: this.isGeolocTheme
        ? 'geoloc-theme'
        : this.isWhiteTheme
        ? 'white-theme'
        : '',
    });
  }

  public setMobileMenuState(newState: boolean): void {
    this.mobileMenuState = newState;

    if (newState) {
      this.renderer2.addClass(document.body, this.menuOpenedClass);
    } else {
      this.renderer2.removeClass(document.body, this.menuOpenedClass);
    }

    setTimeout((): void => {
      this.checkIfContentScrollable();
    });
  }

  public onPlatformSettingsClose(): void {
    this.mobilePlatformSettingsOpened = false;
  }

  public openPlatformSettings(): void {
    this.mobilePlatformSettingsOpened = true;
  }

  public onUserSettingsClose(): void {
    this.mobileUserSettingsOpened = false;
  }

  public openUserSettings(): void {
    this.mobileUserSettingsOpened = true;
  }

  private openExtraLocationInformationDialog(
    type: extraLocationInformationTypes,
    query: Query
  ) {
    this.dialog
      .open(ExtraLocationInformationDialogComponent, {
        width: '30vw',
        height: 'auto',
        disableClose: true,
        data: { type, query },
        panelClass: 'extra-location-information-dialog',
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (!result) return;

        this.showfloatingMsg = result;
        setTimeout(() => {
          this.showfloatingMsg = false;
        }, this.timeToWait);
      });
  }

  private listenResize(): void {
    this.subscriptions.push(
      fromEvent(window, 'resize').subscribe((): void => {
        this.getHeaderHeightAndEmitToParent();
        this.checkIfContentScrollable();
      })
    );
  }

  public checkIfContentScrollable(): void {
    if (this.isMobile) {
      this.isScrollable = this.mobileMenuState
        ? window.innerHeight < this.calculateMobileContentHeight()
        : false;

      if (!this.isScrollable) {
        this.mobileNavMarginTop =
          window.innerHeight - this.calculateMobileContentHeight();
      }
    } else {
      this.isScrollable = false;
    }
    this.cdRef.markForCheck();
  }

  private calculateMobileContentHeight(): number {
    return (
      this.logoContainer?.nativeElement.offsetHeight +
      this.usernameContainer?.nativeElement.offsetHeight +
      this.routeWrpContainer?.nativeElement.offsetHeight +
      this.mobileNavContainer?.nativeElement.offsetHeight +
      this.navActions?.nativeElement.offsetHeight +
      2
    ); // 2 is a height of credit section delimiters;
  }

  public openNotificationList(newState: boolean): void {
    this.notificationsMenuState = newState;

    if (newState) {
      this.renderer2.addClass(document.body, this.notificationsOpenedClass);
    } else {
      this.renderer2.removeClass(document.body, this.notificationsOpenedClass);
    }
  }
}
