import { HttpClient, HttpParams } from '@angular/common/http';
import { CQRSBaseEvent, EventChannel } from '@trg-commons/gio-data-models-ts';
import {
  BehaviorSubject,
  filter,
  map,
  Observable,
  Subject,
  takeUntil,
} from 'rxjs';
import { notificationTypeByPlatform } from './const/notification-type-by-platform';
import { SearchStatus } from './enum/search-status.enum';
import { IntelSearchMapByID } from './type/intel-search-by-id';
import {
  initialSearchState,
  IntelSearchState,
} from './type/intel-search-state';
import { SearchNotification } from './type/search-notification';
import { Inject, Injectable } from '@angular/core';
import { FAST_API_URL } from '@fe-platform/core/config';
import { JobStatus } from 'datalayer/models/background-jobs/background-job-status';
import { JobType } from 'datalayer/models/background-jobs/background-job-type';
import { BackgroundJob } from 'datalayer/models/background-jobs/background-job.model';
import { ProxyWsService } from 'src/app/modules/ad-ids/shared/proxy-ws.service';
import { AuthService } from '../authentication/auth.service';
import { MessageSubject } from '../websocket/message-subject.model';
import { Message } from '../websocket/websocket.class';
import { AuthState } from '../authentication/auth-state.enum';
import { take } from 'rxjs/operators';
import { v4 } from 'uuid';
import { transformCamelToSnakeRecursive } from '@shared/util/helper';
import { cloneDeep } from 'lodash';

@Injectable({ providedIn: 'root' })
export class IntelSearchTrackerService {
  public destroy$ = new Subject<void>();
  private readonly allowedMessageSubjects: MessageSubject[] = [
    MessageSubject.NotifyAnalysingResults,
    MessageSubject.NotifySearchingClosedDatabases,
    MessageSubject.NotifySearchingDarkWeb,
    MessageSubject.NotifySearchingInstantMessageProfiles,
    MessageSubject.NotifySearchingOpenWeb,
    MessageSubject.NotifySearchingSocialDatabases,
    MessageSubject.NotifySearchingSocialProfiles,
    MessageSubject.SearchComplete,
  ];

  private readonly state$: BehaviorSubject<IntelSearchMapByID> =
    new BehaviorSubject<IntelSearchMapByID>(new Map());

  private readonly minimized$: Subject<void> = new Subject<void>();

  private readonly drawerMinimized$: Subject<void> = new Subject<void>();

  private readonly searchCompleted$: Subject<string> = new Subject<string>();

  private readonly viewResultsClicked$: Subject<string> = new Subject<string>();

  private readonly searchAdded$: Subject<string> = new Subject<string>();

  private readonly searchRemoved$: Subject<string> = new Subject<string>();

  private readonly isDrawerExists$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private runningJobsLoaded = false;

  constructor(
    private readonly authService: AuthService,
    private readonly proxyWsService: ProxyWsService,
    private readonly httpClient: HttpClient,
    @Inject(FAST_API_URL) private readonly fastApiUrl: string
  ) {
    this.authService.authState$.subscribe((state: AuthState): void => {
      if (state !== AuthState.authenticated) {
        this.destroy$.next();
        this.clear();
        return;
      }

      this.listenWsEvents().subscribe();
    });
  }
  /** When passing no searchID returns all searches */
  public selectSearch(
    searchID = ''
  ): Observable<IntelSearchMapByID | undefined | IntelSearchState> {
    if (!searchID.length) return this.state$;
    return this.state$.pipe(
      map((state: IntelSearchMapByID) => {
        return state.get(searchID);
      })
    );
  }

  public initialRunningSearchesLoad(): Observable<IntelSearchMapByID> {
    return this.loadSearches().pipe(
      map((jobs: BackgroundJob[]): IntelSearchMapByID => {
        const newState: IntelSearchMapByID = this.state;
        if (!jobs.length) {
          this.runningJobsLoaded = true;
          return;
        }

        for (const job of jobs) {
          const searchID: string = job.reference_id;
          if (
            searchID &&
            notificationTypeByPlatform[job.query_args[0].platform]
          ) {
            newState.set(
              searchID,
              mapJobToState(newState, job, this.runningJobsLoaded)
            );
          }
        }
        this.updateState(newState);
        let loadedJobs = 0;

        Array.from(this.state.keys()).forEach(
          (key: string, i: number, arr: string[]): void => {
            this.loadSearches(key, true)
              .pipe(take(1))
              .subscribe((jobs: BackgroundJob[]): void => {
                this.jobsToState(jobs);
                loadedJobs++;
                this.runningJobsLoaded = loadedJobs === arr.length;
              });
          }
        );

        return this.state;
      })
    );
  }
  public loadSearchByID(
    id: string = undefined
  ): Observable<IntelSearchMapByID> {
    return this.loadSearches(id).pipe(
      map((jobs: BackgroundJob[]): IntelSearchMapByID => this.jobsToState(jobs))
    );
  }

  public insertSearch(id: string, searchArgs: any[]): void {
    this.state.set(id, {
      ...(this.state.has(id) ? this.state.get(id) : initialSearchState),
      ...this.getQueriesFromArgs(
        transformCamelToSnakeRecursive(cloneDeep(searchArgs))
      ),
      updatedAt: new Date(),
    });
    this.updateState(this.state);
    this.searchAdded$.next(id);
  }

  public insertTempSearch(searchArgs: any[]): string {
    const tempSearchID: string = v4();
    this.state.set(tempSearchID, {
      ...initialSearchState,
      ...this.getQueriesFromArgs(
        transformCamelToSnakeRecursive(cloneDeep(searchArgs))
      ),
      updatedAt: new Date(),
      temp: true,
    });
    this.updateState(this.state);
    return tempSearchID;
  }

  public get searchAdded(): Observable<string> {
    return this.searchAdded$.asObservable();
  }

  public minimize(): void {
    this.minimized$.next();
  }

  public get minimized(): Observable<void> {
    return this.minimized$.asObservable();
  }

  public minimizeDrawer(): void {
    this.drawerMinimized$.next();
  }

  public get drawerMinimized(): Observable<void> {
    return this.drawerMinimized$.asObservable();
  }

  public removeSearch(id: string): void {
    if (this.state.has(id)) {
      this.state.delete(id);
      this.updateState(this.state);
      this.searchRemoved$.next(id);
    }
  }

  public clear(): void {
    this.updateState();
  }

  public get searchCompleted(): Observable<string> {
    return this.searchCompleted$.asObservable();
  }

  private get state(): IntelSearchMapByID {
    return this.state$.getValue();
  }

  public get viewResultsClicked(): Observable<string> {
    return this.viewResultsClicked$.asObservable();
  }

  public onViewResults(id: string): void {
    this.viewResultsClicked$.next(id);
  }

  public get isDrawerExists(): Observable<boolean> {
    return this.isDrawerExists$.asObservable();
  }

  public setDrawerExistence(state: boolean): void {
    this.isDrawerExists$.next(state);
  }

  public toggleSearchInViewState(id: string): void {
    if (this.state.has(id)) {
      this.state.get(id).inView = !this.state.get(id).inView;
      this.updateState(this.state);
    }
  }

  public transformAllSearchesToHidden(): void {
    Array.from(this.state.keys()).forEach((id: string): void => {
      this.state.set(id, {
        ...this.state.get(id),
        hidden: true,
      });
    });
    this.updateState(this.state);
  }

  public setHiddenState(id: string, state: boolean): void {
    if (this.state.has(id)) {
      this.state.get(id).hidden = state;
      this.updateState(this.state);
    }
  }

  public getSearchCount(completedOnly = false): Observable<number> {
    return this.selectSearch().pipe(
      map((v: IntelSearchMapByID): number => {
        if (!completedOnly) {
          return v.size;
        }

        const runningSearches: IntelSearchMapByID = new Map<
          string,
          IntelSearchState
        >();
        for (const [key, state] of v.entries()) {
          if (!searchIsComplete(state)) {
            runningSearches.set(key, state);
          }
        }
        return runningSearches.size;
      })
    );
  }

  private getQueriesFromArgs(searchArgs: any[]): {
    searchType: string;
    searchText: string;
    fileNames?: string[];
  } {
    const searchText: string =
      searchArgs[0].arg_type === 'photo'
        ? (searchArgs[0].arg_value as any).name
        : searchArgs[0].arg_value;
    const searchType: string = searchArgs.some(
      (arg: any): boolean => arg.arg_type === 'photo'
    )
      ? 'photo'
      : searchArgs[0].arg_type;
    let fileNames: string[];

    if (searchType === 'photo') {
      fileNames = searchArgs
        .filter((arg: any): boolean => arg.arg_type === 'photo')
        .map((arg: any): string => arg.arg_value.filename);
    }

    return {
      searchText,
      searchType,
      fileNames,
    };
  }

  private updateState(state?: IntelSearchMapByID): void {
    this.state$.next(state || new Map());
  }

  private listenWsEvents(): Observable<void> {
    return this.proxyWsService.getMessage().pipe(
      filter(
        ({
          channel,
          body,
        }: CQRSBaseEvent<Message<SearchNotification>>): boolean =>
          channel === EventChannel.Notify &&
          body.subject &&
          this.allowedMessageSubjects.includes(body.subject)
      ),
      map((event: CQRSBaseEvent<Message<SearchNotification>>): void => {
        const newState: IntelSearchMapByID = mapMessageToState(
          this.state,
          event.correlationId,
          event.body
        );
        this.updateState(newState);
        if (
          this.state.get(event.correlationId)?.statuses &&
          searchIsComplete(this.state.get(event.correlationId))
        ) {
          this.searchCompleted$.next(event.correlationId);
          return;
        }

        if (!this.state.get(event.correlationId).searchText) {
          this.loadSearchByID(event.correlationId).subscribe();
        }
      }),
      takeUntil(this.destroy$)
    );
  }

  private loadSearches(
    id: string = undefined,
    includeCompletedJobs = false
  ): Observable<BackgroundJob[]> {
    const userIdentity = this.authService.user?.current?.identity;
    if (!userIdentity) {
      throw new Error(
        'cannot initialize intel search tracker service when unauthorized'
      );
    }
    const route = `${this.fastApiUrl}/background-jobs/`;
    let queryParams = new HttpParams();
    queryParams = queryParams.append('username', userIdentity);
    queryParams = queryParams.append('job_type', JobType.INTEL_SEARCH);
    queryParams = queryParams.append('job_status', JobStatus.PENDING);

    if (id) {
      queryParams = queryParams.append('reference_id', id);
    }

    if (includeCompletedJobs) {
      queryParams = queryParams.append('job_status', JobStatus.DONE);
    }

    return this.httpClient.get<BackgroundJob[]>(route, {
      params: queryParams,
    });
  }

  private jobsToState(jobs: BackgroundJob[]): IntelSearchMapByID {
    const newState: IntelSearchMapByID = this.state;
    for (const job of jobs) {
      const searchID: string = job.reference_id;
      if (searchID && notificationTypeByPlatform[job.query_args[0].platform]) {
        newState.set(
          searchID,
          mapJobToState(newState, job, this.runningJobsLoaded)
        );
      }
    }
    this.updateState(newState);
    return this.state;
  }
}
function mapMessageToState(
  state: IntelSearchMapByID,
  searchID: string,
  message: Message<SearchNotification>
): IntelSearchMapByID {
  const searchState: IntelSearchState =
    state.get(searchID) ?? initialSearchState;
  const key: string = message.subject.replace('notify.', '');
  let statuses: Record<string, boolean> = searchState.statuses;

  if (key !== 'search.complete') {
    statuses = {
      ...statuses,
      [key]: message.body.status === SearchStatus.Complete,
    };
  } else {
    Object.keys(statuses).forEach((key: string): void => {
      statuses[key] = true;
    });
  }

  state.set(searchID, {
    ...searchState,
    count: message.body.count,
    statuses,
    updatedAt: new Date(),
    hidden: searchState.hidden ? searchState.hidden : false,
    hideNullableSources: false,
  });
  return state;
}
function mapJobToState(
  state: IntelSearchMapByID,
  job: BackgroundJob,
  initialJobsLoaded: boolean
): IntelSearchState {
  const searchID: string = job.reference_id;
  const searchText: any = job.query_args[0].arg_value;
  const searchType: string = job.query_args[0].arg_type;
  const existingStatuses: Record<string, boolean> =
    state.get(searchID)?.statuses ?? {};
  const jobStatus: Record<string, boolean> = {
    [notificationTypeByPlatform[job.query_args[0].platform]]:
      job.status === JobStatus.DONE,
  };

  if (
    !Object.prototype.hasOwnProperty.call(existingStatuses, 'analyzing_results')
  ) {
    existingStatuses['analyzing_results'] = false;
  }

  return {
    searchText: searchType === 'photo' ? searchText.name : searchText,
    fileNames: searchType === 'photo' ? [searchText.filename] : [],
    count: 0,
    searchType,
    statuses: {
      ...existingStatuses,
      ...jobStatus,
    },
    updatedAt: new Date(),
    hidden: !initialJobsLoaded,
    temp: false,
    hideNullableSources: !initialJobsLoaded,
    inView: false,
  };
}
export function searchIsComplete(searchState: IntelSearchState): boolean {
  const statuses: Array<[string, boolean]> = Object.entries(
    searchState.statuses
  );
  return statuses.length > 0 && statuses.every(([_, v]) => v);
}

export function calculateCompletedItems(searchState: IntelSearchState): number {
  return Object.entries(searchState.statuses).filter(([_, v]) => v).length;
}

export function sortStatuses(
  statuses: Record<string, boolean>
): Array<[string, boolean]> {
  const result: Array<[string, boolean]> = Object.entries(statuses).sort(
    (value1, value2) => (value1[1] < value2[1] ? 1 : -1)
  );
  const analysisIndex: number = result.findIndex(
    (item) => item[0] === 'analyzing_results'
  );
  if (analysisIndex > -1) {
    result.push(result.splice(analysisIndex, 1)[0]);
  } else {
    result.push(['analyzing_results', false]);
  }

  return result;
}
