import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-redirect-button-snack-bar',
  templateUrl: './redirect-button-snack-bar.component.html',
  styleUrls: ['./redirect-button-snack-bar.component.scss'],
})
export class RedirectButtonSnackBarComponent {
  public text: string;

  public navigateTo: string;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: {
      text: string;
      navigateTo: string;
    },
    private readonly router: Router,
    private readonly intelSnackBar: MatSnackBar
  ) {
    this.text = data.text;
    this.navigateTo = data.navigateTo;
  }

  public navigate(): void {
    this.intelSnackBar.dismiss();
    this.router.navigateByUrl(this.navigateTo).then();
  }

  public close(): void {
    this.intelSnackBar.dismiss();
  }
}
