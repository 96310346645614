import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  MatLegacyMenu as MatMenu,
  MatLegacyMenuTrigger as MatMenuTrigger,
} from '@angular/material/legacy-menu';
import { NavbarMenuItem } from '../nav-bar.model';

@Component({
  selector: 'app-nav-menu-sub-item',
  templateUrl: './nav-menu-sub-item.component.html',
  styleUrls: ['./nav-menu-sub-item.component.scss'],
})
export class NavMenuSubItemComponent {
  @ViewChild(MatMenu, { static: true }) childMenu: MatMenu;
  @Input() items: NavbarMenuItem[] | undefined;
  @Input() menuTrigger: MatMenuTrigger | undefined;
  @Output() onMouseEnter = new EventEmitter<MatMenuTrigger>();
  @Output() onMouseLeave = new EventEmitter<MatMenuTrigger>();

  handleClick(item: NavbarMenuItem): void {
    if (item.onClick) {
      item.onClick();
    }
  }
}
