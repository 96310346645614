import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DeviceHistoyQueryParams,
  HistoricalDataRecommendationsParams,
  QueriesAPI,
  QueriesAPIParams,
  Query,
} from '../models/Query.model';
import { LabelRequest, LabelResponse } from '../models/QueryHistory.model';
import {
  QueriesHistoryAPI,
  QueriesHistoryAPIParams,
} from '../models/QueryHistory.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseToasterService } from '@fe-platform/shared-ui/intellectus';
import { environment } from 'environment/environment';
import { ImProfile } from 'datalayer/models/platform-models/im-profiles/im-profile';
import { ApiUtils } from 'src/app/account/shared/utils/api.utils';

@Injectable({
  providedIn: 'root',
})
export class GeolocationQueriesService {
  private baseURL: string = environment.discoveryAPIUri;
  private searchForAliasSrc = new BehaviorSubject<boolean>(false);
  public searchForAlias$ = this.searchForAliasSrc.asObservable();

  constructor(
    private httpClient: HttpClient,
    private toasterService: BaseToasterService
  ) {}

  getQueries(params: QueriesAPIParams): Observable<QueriesAPI> {
    let url = `${this.baseURL}/location-query?`;
    url += `page_size=${params.page_size}`;
    url += `&page=${params.page}`;
    url += `&sort_by=${params.sort_by}`;
    url += `&sort_order=${params.sort_order}`;
    if (params.search) url += `&search=${params.search}`;
    if (params.from) url += `&from=${params.from}`;
    if (params.to) url += `&to=${params.to}`;
    return this.httpClient.get<QueriesAPI>(url);
  }

  getQueriesHistory(
    params: QueriesHistoryAPIParams
  ): Observable<QueriesHistoryAPI> {
    let url = `${this.baseURL}/location-query/history?`;
    url += `page_size=${params.page_size}`;
    url += `&page=${params.page}`;
    url += `&sort_by=${params.sort_by}`;
    url += `&sort_order=${params.sort_order}`;
    if (params.msisdn_list?.length)
      url += `&msisdn_list=${ApiUtils.encodeMSISDNList(
        params.msisdn_list
      ).toString()}`;
    if (params.imsi_list?.length)
      url += `&imsi_list=${params.imsi_list.toString()}`;
    if (params.from) url += `&from=${params.from}`;
    if (params.to) url += `&to=${params.to}`;
    if (params.search) {
      this.searchForAliasSrc.getValue()
        ? (url += `&search_by_target_alias=${encodeURIComponent(
            params.search
          )}`)
        : (url += `&search=${encodeURIComponent(params.search)}`);
    }
    if (params.calculateInsights)
      url += `&calculateInsights=${params.calculateInsights}`;
    return this.httpClient.get<QueriesHistoryAPI>(url);
  }

  createQuery(queries: Object[]): Observable<any> {
    queries = queries.map((query) => {
      return { imsi: query['imsi'], msisdn: query['telno'] };
    });

    return this.httpClient.post<any>(`${this.baseURL}/location-query`, {
      query_type: 'LOCATION_QUERY',
      queries: queries,
    });
  }

  createIMQuery(queryId: string): Observable<void> {
    return this.httpClient.post<void>(
      `${this.baseURL}/location-query/${queryId}/im-query`,
      null
    );
  }

  manageLabel(id: string, labelRequest: LabelRequest): Observable<Query> {
    return this.httpClient.post<Query>(
      `${this.baseURL}/location-query/${id}/tag`,
      labelRequest
    );
  }

  addInstantMessageProfiles(
    id: string,
    request: ImProfile[]
  ): Observable<Query> {
    return this.httpClient.post<Query>(
      `${this.baseURL}/location-query/${id}/instant-message-profiles`,
      request
    );
  }

  getLabels(): Observable<LabelResponse[]> {
    return this.httpClient.get<LabelResponse[]>(`${this.baseURL}/label`);
  }

  getQuerySector(queryId: string): Observable<Query> {
    return this.httpClient.get<Query>(
      `${this.baseURL}/location-query/${queryId}/sector`
    );
  }

  getQueryCallInfo(queryId: string): Observable<Query> {
    return this.httpClient.get<Query>(
      `${this.baseURL}/location-query/${queryId}/call-info`
    );
  }

  handleQueryError(error: HttpErrorResponse) {
    if (error.status >= 400 && error.status <= 500) {
      this.toasterService.show({
        title: error.message,
      });
    } else if (error.status === 503 || error.status === 0) {
      this.toasterService.show({
        title: 'Service Unavailable',
      });
    } else {
      this.toasterService.show({
        title: 'An unknown error occurred',
      });
    }
  }

  getDeviceHistory(params: DeviceHistoyQueryParams): Observable<any> {
    let url = `${this.baseURL}/device-history?`;
    url += `imei_list=${params.imei_list}`;
    return this.httpClient.get<QueriesAPI>(url);
  }

  getHistoricalDataRecommendations(
    params: HistoricalDataRecommendationsParams
  ): Observable<any> {
    let url = `${this.baseURL}/historical-location/recommendation?`;
    url += `msisdn=${encodeURIComponent(params.msisdn)}`;
    return this.httpClient.get<any>(url);
  }

  importHistoricalData(
    params: HistoricalDataRecommendationsParams
  ): Observable<any> {
    let url = `${this.baseURL}/historical-location/import?`;
    url += `msisdn=${encodeURIComponent(params.msisdn)}`;
    return this.httpClient.post<any>(url, { dates: params.dates });
  }

  public toggleSearchForAlias() {
    this.searchForAliasSrc.next(!this.searchForAliasSrc.getValue());
  }
}
