import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  DataIMExport,
  IntelSearchMode,
  Profile,
  QueryArg,
  SearchGroupResult,
  SearchIntelModel,
  SingleSearchIntelApiResponse,
} from 'src/app/modules/search-intel/models/search-intel.model';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { BaseService } from 'src/app/services/base.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { transformSnakeToCamel } from 'src/app/shared/util/helper';
import { IntelPosts } from '../models/intel-posts.model';
import { IntelSearchArgTypes } from '../models/intel-search-arg-types.model';
import { HistoryFilterType } from '../models/search-history-filters.model';
import { SearchResultFilters } from '../models/search-result-filters.model';
import { displayValuesForSearchHistoryKeyFilters } from '../models/display-values-for-search-history-key-filters.model';
import {
  SearchArticleResult,
  SearchDarkWebResult,
  SearchFilters,
  SearchIntelResult,
  SearchLabels,
  SearchProfileResult,
  SearchResultTabs,
} from '../models/search-intel.model';
import { InputTypeGuesserService } from './input-type-guesser.service';
import { SearchGroupFilters } from 'src/app/modules/search-intel/models/search-group-filter.model';

@Injectable({
  providedIn: 'root',
})
export class SearchIntelService extends BaseService {
  currentSearchIntel: BehaviorSubject<{
    search: SearchIntelModel;
    noResultSearch: boolean;
  }> = new BehaviorSubject<{
    search: SearchIntelModel;
    noResultSearch: boolean;
  }>(null);
  clearQueue: Subject<boolean> = new Subject<boolean>();
  changeHistory: Subject<boolean> = new Subject<boolean>();
  searchText = new BehaviorSubject<string>(null);
  searchBtnListner = new Subject<{
    event: PointerEvent;
    filters: SearchFilters;
    searchMode: IntelSearchMode;
    navBarIdentifier: string;
    noResultSearch?: boolean;
    skipLocate?: boolean;
  }>();
  onFiltersChange = new Subject<{
    intelSearchId: string;
    filters: { types?: string[]; values?: { [key: string]: string[] } };
  }>();
  onArticlesFiltersChange = new Subject<{
    intelSearchId: string;
    filters: { [key: string]: string[] };
  }>();
  onDarkWebArticlesFiltersChange = new Subject<{
    intelSearchId: string;
    filters: { siteName: string; total: number; selected?: boolean }[];
  }>();
  onGroupsFiltersChange = new Subject<{
    intelSearchId: string;
    filters: { [key: string]: string[] };
  }>();

  onSearchResultTabChange = new Subject<{
    searchId: string;
    tab: SearchResultTabs;
  }>();

  showSearchResultFilters = new Subject<string>();
  onClearProfileFilter = new Subject<{
    searchId: string;
    value: { key: string; value: string };
  }>();
  onClearAllProfilesFilter = new Subject<{
    searchId: string;
    value: boolean;
  }>();
  onClearAllSearchHistoryFilters = new Subject<{ value: boolean }>();
  onClearSearchHistoryFilter = new Subject<{
    group: HistoryFilterType;
    value: string;
  }>();
  onClearArticleFilter = new Subject<{
    searchId: string;
    value: { group: string; value: string };
  }>();
  onClearDarkWebArticleFilter = new Subject<{
    searchId: string;
    value: { siteName: string; total: number; selected: boolean };
  }>();
  onClearAllArticlesFilter = new Subject<{
    searchId: string;
    value: boolean;
  }>();
  onClearAllDarkWebArticlesFilter = new Subject<{
    searchId: string;
    value: boolean;
  }>();
  openCreateTargetDialog = new Subject<void>();
  displayValuesForSearchHistoryKeyFilters =
    displayValuesForSearchHistoryKeyFilters;
  phoneNumberUtil = PhoneNumberUtil.getInstance();

  onIntelProfileChanged: EventEmitter<Profile[]> = new EventEmitter<
    Profile[]
  >();

  keywordSearchInprogress = new BehaviorSubject<{
    loading: boolean;
    value: string;
  }>(null);
  onSocialPostFiltersChange = new Subject<{
    intelSearchId: string;
    filters: { [key: string]: string[] };
  }>();
  private onSearchMarkedAsSeen: Subject<SearchIntelModel> = new Subject();
  public onSearchMarkedAsSeen$ = this.onSearchMarkedAsSeen.asObservable();

  constructor(
    private httpClient: HttpClient,
    protected router: Router,
    private translationService: TranslationService,
    private appConfigService: AppConfigService,
    protected snackBar: MatSnackBar,
    private inputTypeGuesserService: InputTypeGuesserService
  ) {
    super(router, snackBar);
  }

  private handleOsintError(e) {
    this.handleError(e);
    if (e.error.error && e.error.error.message) {
      this.showMessage(
        this.translationService.translate(`${e.error.error.message}`)
      );
    }
  }

  getProfilesFilters(searchIds: string[]) {
    let queryArgs = new HttpParams();
    searchIds.forEach((id) => {
      queryArgs = queryArgs.append('search_ids', id);
    });

    return this.httpClient
      .get(`${this.fastAPIurl}/intel-search/profiles-filters`, {
        params: queryArgs,
      })
      .pipe(
        map((data: { result: { [key: string]: object[] } }) => {
          return transformSnakeToCamel(data.result);
        })
      );
  }

  getArticleFilters(searchIds: string[]): Observable<SearchResultFilters> {
    let queryArgs = new HttpParams();
    searchIds.forEach((id) => {
      queryArgs = queryArgs.append('search_ids', id);
    });

    return this.httpClient
      .get(`${this.fastAPIurl}/intel-search/articles-filters`, {
        params: queryArgs,
      })
      .pipe(
        map((data: { result: SearchResultFilters }) => {
          return transformSnakeToCamel(data.result);
        })
      );
  }

  getGroupFilters(searchIds: string[]): Observable<SearchGroupFilters> {
    let queryArgs = new HttpParams();
    searchIds.forEach((id) => {
      queryArgs = queryArgs.append('search_ids', id);
    });

    return this.httpClient
      .get(`${this.fastAPIurl}/intel-search/groups-filters`, {
        params: queryArgs,
      })
      .pipe(
        map((data: { result: SearchGroupFilters }) => {
          return transformSnakeToCamel(data.result);
        })
      );
  }

  getProfiles(queryArgs): Observable<SearchProfileResult> {
    return this.httpClient
      .get(`${this.fastAPIurl}/intel-search/profiles`, { params: queryArgs })
      .pipe(
        map((data: { result: { [key: string]: object[] } }) => {
          return transformSnakeToCamel(data.result);
        })
      );
  }

  getArticles(queryArgs): Observable<SearchArticleResult> {
    return this.httpClient
      .get(`${this.fastAPIurl}/intel-search/articles`, { params: queryArgs })
      .pipe(
        map((data: { result: { [key: string]: object[] } }) => {
          return transformSnakeToCamel(data.result);
        })
      );
  }

  getDarkWeb(queryArgs): Observable<SearchDarkWebResult> {
    return this.httpClient
      .get(`${this.fastAPIurl}/intel-search/dark-web`, { params: queryArgs })
      .pipe(
        map((data: { result: { [key: string]: object[] } }) => {
          return transformSnakeToCamel(data.result);
        })
      );
  }

  public getImGroups(queryArgs: HttpParams): Observable<SearchGroupResult> {
    return this.httpClient
      .get(`${this.fastAPIurl}/intel-search/groups`, {
        params: queryArgs,
      })
      .pipe(
        map(
          (data: { result: { [key: string]: object[] } }): SearchGroupResult =>
            transformSnakeToCamel(data.result)
        )
      );
  }

  getAllSearchIntelWithPagination(
    queryArgs
  ): Observable<{ result: SearchIntelResult }> {
    const params = queryArgs;
    return this.httpClient
      .get(`${this.fastAPIurl}/intel-search`, { params: params })
      .pipe(
        map((data: any) => {
          return transformSnakeToCamel(data);
        }),
        map((res: { result: SearchIntelResult }) => {
          res.result.queries.forEach((intelSearch: SearchIntelModel) => {
            intelSearch.queryArgsDisplay = [];
            intelSearch.queryArgs.forEach((queryArgs) => {
              if (
                queryArgs &&
                queryArgs['argValue'] &&
                typeof queryArgs['argValue'] === 'object'
              ) {
                if (queryArgs['argType'] === 'photo') {
                  const name: string = queryArgs['argValue']['name'];
                  const and = ` <b>${this.translationService.translate(
                    'and'
                  )}</b> `;
                  intelSearch.queryArgsDisplay.push(
                    `${name ? name + and : ''}${
                      queryArgs['argValue']['filename']
                    }`
                  );
                } else {
                  const values = Object.values(queryArgs['argValue']).filter(
                    (i) => i
                  );
                  intelSearch.queryArgsDisplay.push(
                    values.join(
                      ` <b>${this.translationService.translate('and')}</b> `
                    )
                  );
                }
              } else {
                intelSearch.queryArgsDisplay.push(queryArgs.argValue);
              }
            });
          });
          return res;
        })
      );
  }

  getSingleSearchIntel(searchQueryId: string): Observable<SearchIntelModel> {
    return this.httpClient
      .get<SingleSearchIntelApiResponse>(
        `${this.fastAPIurl}/intel-search/id/${searchQueryId}`
      )
      .pipe(
        map((response: SingleSearchIntelApiResponse) =>
          transformSnakeToCamel(response.result)
        ),
        map((res: SearchIntelModel) => this.buildQueryArgsDisplay(res))
      );
  }

  private buildQueryArgsDisplay(res: SearchIntelModel): SearchIntelModel {
    res.queryArgsDisplay = [];

    res.queryArgs.forEach((queryArg: QueryArg) => {
      if (this.isArgValueObject(queryArg)) {
        res.queryArgsDisplay.push(this.buildDisplayForArgValueObject(queryArg));
      } else {
        res.queryArgsDisplay.push(queryArg.argValue as string);
      }
    });

    return res;
  }

  private isArgValueObject(queryArg: QueryArg): boolean {
    return queryArg && typeof queryArg.argValue === 'object';
  }

  private buildDisplayForArgValueObject(queryArg: QueryArg): string {
    const argValueObject = queryArg.argValue as unknown as {
      [key: string]: string;
    };

    if (queryArg.argType === 'photo') {
      const name: string = queryArg['argValue']['name'];
      const and = ` <b>${this.translationService.translate('and')}</b> `;
      return `${name ? name + and : ''}${queryArg['argValue']['filename']}`;
    } else {
      const values = Object.values(argValueObject).filter((i) => i);
      return values.join(
        ` <b>${this.translationService.translate('and')}</b> `
      );
    }
  }

  getCompleteSearchIntel(searchId: string): Observable<SearchIntelModel> {
    return this.httpClient.get(`${this.url}/intel-search/${searchId}`).pipe(
      map((data: any) => {
        return transformSnakeToCamel(data.result);
      }),
      map((search: SearchIntelModel): SearchIntelModel => {
        search.queryArgsDisplay = [];
        search.queryArgs.forEach((queryArgs) => {
          if (queryArgs && typeof queryArgs['argValue'] === 'object') {
            const values = Object.values(queryArgs['argValue']);
            search.queryArgsDisplay.push(
              values.join(
                ` <b>${this.translationService.translate('and')}</b> `
              )
            );
          } else {
            search.queryArgsDisplay.push(queryArgs.argValue);
          }
        });
        return search;
      })
    );
  }

  delteCompleteSearchIntel(searchId: string) {
    return this.httpClient
      .delete(`${this.fastAPIurl}/intel-search/${searchId}`)
      .pipe(map((data: any) => data.result));
  }

  delteAllSearchIntel(params: HttpParams) {
    return this.httpClient
      .delete(`${this.fastAPIurl}/intel-search/all`, { params: params })
      .pipe(map((data: any) => data.result));
  }

  createSearchIntel(
    searchArgs,
    queryFilters?: SearchFilters
  ): Observable<SearchIntelModel> {
    const bodyRequest = {
      query_args: searchArgs,
    };

    if (queryFilters && Object.keys(queryFilters).length) {
      bodyRequest['query_filters'] = queryFilters;
    }

    return this.httpClient
      .post(`${this.fastAPIurl}/intel-search`, bodyRequest)
      .pipe(
        catchError((e: HttpErrorResponse) => {
          this.handleOsintError(e);
          return throwError(() => e.error);
        }),
        map((data: any) => {
          return transformSnakeToCamel(data.result);
        }),
        map((intelSearch: SearchIntelModel) => {
          intelSearch.queryArgsDisplay = [];
          intelSearch.queryArgs.forEach((queryArgs) => {
            if (queryArgs && typeof queryArgs['argValue'] === 'object') {
              const values = Object.values(queryArgs['argValue']);
              intelSearch.queryArgsDisplay.push(
                values.join(
                  ` <b>${this.translationService.translate('and')}</b> `
                )
              );
            } else {
              intelSearch.queryArgsDisplay.push(queryArgs.argValue);
            }
          });
          return intelSearch;
        })
      );
  }

  moveInstantMessagingProfiles(
    data: DataIMExport,
    targetIds: string[]
  ): Observable<any> {
    return this.httpClient
      .post(`${this.url}/move-im-profiles`, { ...data, targetIds })
      .pipe(
        catchError((e: HttpErrorResponse) => {
          return throwError(e.error);
        })
      );
  }

  makeSearchHistoryAsFavorite(
    searchId: string,
    flag: boolean
  ): Observable<any> {
    return this.httpClient
      .post(`${this.fastAPIurl}/intel-search/${searchId}/favorite`, {
        is_favorite: flag,
        search_id: searchId,
      })
      .pipe(map((data: any) => data.result));
  }

  public buildQueryArgsByLabel(field: { value: string; label: SearchLabels }) {
    const queryArg = {
      arg_type: '',
      arg_value: String(field.value).trim(),
    };
    switch (field.label) {
      case SearchLabels.PHONE:
      case SearchLabels.POSSIBLE_TELNO:
        queryArg.arg_type = IntelSearchArgTypes.TELNO;
        break;
      case SearchLabels.EMAIL:
        queryArg.arg_type = IntelSearchArgTypes.EMAIL;
        break;
      case SearchLabels.HASHTAG:
        queryArg.arg_type = IntelSearchArgTypes.HASHTAG;
        break;
      case SearchLabels.CURP:
        queryArg.arg_type = IntelSearchArgTypes.CURP;
        break;
      case SearchLabels.NIK:
        queryArg.arg_type = IntelSearchArgTypes.NIK;
        break;
      case SearchLabels.NAME:
        queryArg.arg_type = IntelSearchArgTypes.NAME;
        break;
      case SearchLabels.NAME_WITH_SURNAME:
        queryArg.arg_type = IntelSearchArgTypes.NAME_WITH_SURNAME;
        break;
      case SearchLabels.NAME_WITH_MIDDLE_AND_SURNAME:
        queryArg.arg_type = IntelSearchArgTypes.NAME_WITH_MIDDLE_AND_SURNAME;
        break;
      case SearchLabels.IMAGE:
        queryArg.arg_type = IntelSearchArgTypes.PHOTO;
        break;
      case SearchLabels.URL:
        queryArg.arg_type = IntelSearchArgTypes.URL;
        break;
      case SearchLabels.USERNAME:
        queryArg.arg_type = IntelSearchArgTypes.USERNAME;
        break;
      case SearchLabels.USER_ID:
        queryArg.arg_type = IntelSearchArgTypes.USER_ID;
        break;
      case SearchLabels.VOTER_ID:
        queryArg.arg_type = IntelSearchArgTypes.VOTER_ID;
        break;
      case SearchLabels.IMEI:
        queryArg.arg_type = IntelSearchArgTypes.IMEI;
        break;
    }
    return queryArg;
  }

  public getQueryArgLabel(argType: string) {
    let label: SearchLabels;
    switch (argType) {
      case IntelSearchArgTypes.POSSIBLE_TELNO:
      case IntelSearchArgTypes.TELNO:
        label = SearchLabels.PHONE;
        break;
      case IntelSearchArgTypes.EMAIL:
        label = SearchLabels.EMAIL;
        break;
      case IntelSearchArgTypes.HASHTAG:
        label = SearchLabels.HASHTAG;
        break;
      case IntelSearchArgTypes.CURP:
        label = SearchLabels.CURP;
        break;
      case IntelSearchArgTypes.NAME:
        label = SearchLabels.NAME;
        break;
      case IntelSearchArgTypes.NIK:
        label = SearchLabels.NIK;
        break;
      case IntelSearchArgTypes.NAME_WITH_SURNAME:
        label = SearchLabels.NAME_WITH_SURNAME;
        break;
      case IntelSearchArgTypes.NAME_WITH_MIDDLE_AND_SURNAME:
        label = SearchLabels.NAME_WITH_MIDDLE_AND_SURNAME;
        break;
      case IntelSearchArgTypes.PHOTO:
        label = SearchLabels.IMAGE;
        break;
      case IntelSearchArgTypes.URL:
        label = SearchLabels.URL;
        break;
      case IntelSearchArgTypes.USERNAME:
        label = SearchLabels.USERNAME;
        break;
      case IntelSearchArgTypes.USER_ID:
        label = SearchLabels.USER_ID;
        break;
      case IntelSearchArgTypes.VOTER_ID:
        label = SearchLabels.VOTER_ID;
        break;
      case IntelSearchArgTypes.IMEI:
        label = SearchLabels.IMEI;
        break;
    }
    return label;
  }

  translateTags(
    value: string,
    tagKeys: string[],
    translateFn: (value: string) => string
  ): string {
    const values = value.split(': ');
    if (values?.length <= 1) return value;
    for (const [i, v] of values.entries()) {
      if (tagKeys.includes(v)) {
        values[i] = translateFn(v);
      }
    }
    return values.join(': ');
  }

  public getIntelSocialMediPosts(queryArgs): Observable<IntelPosts> {
    return this.httpClient
      .get(`${this.fastAPIurl}/intel-search/social-media`, {
        params: queryArgs,
      })
      .pipe(
        map((data: { result: { [key: string]: object[] } }) =>
          transformSnakeToCamel(data.result)
        )
      );
  }

  public createSearchIntelForPosts(searchArgs): Observable<SearchIntelModel> {
    const bodyRequest = {
      query_args: searchArgs,
      search_by_hashtag: true,
    };

    return this.httpClient
      .post(`${this.fastAPIurl}/intel-search`, bodyRequest)
      .pipe(
        map((data: any) => {
          return transformSnakeToCamel(data.result);
        }),
        map((intelSearch: SearchIntelModel) => {
          if (!intelSearch) {
            throw new Error('No result!');
          }
          return intelSearch;
        }),
        catchError((e: HttpErrorResponse | Error) => {
          if (e instanceof HttpErrorResponse) {
            this.handleOsintError(e);
          }
          return throwError(() => e);
        }),
        map((intelSearch: SearchIntelModel) => {
          intelSearch.queryArgsDisplay = [];
          intelSearch.queryArgs.forEach((queryArgs) => {
            if (queryArgs && typeof queryArgs['argValue'] === 'object') {
              const values = Object.values(queryArgs['argValue']);
              intelSearch.queryArgsDisplay.push(
                values.join(
                  ` <b>${this.translationService.translate('and')}</b> `
                )
              );
            } else {
              intelSearch.queryArgsDisplay.push(queryArgs.argValue);
            }
          });
          return intelSearch;
        })
      );
  }

  public getIntelPostFilters(
    searchIds: string[]
  ): Observable<SearchResultFilters> {
    let queryArgs = new HttpParams();
    searchIds.forEach((id) => {
      queryArgs = queryArgs.append('search_ids', id);
    });

    return this.httpClient
      .get(`${this.fastAPIurl}/intel-search/social-media-filters`, {
        params: queryArgs,
      })
      .pipe(
        map((data: { result: SearchResultFilters }) =>
          transformSnakeToCamel(data.result)
        )
      );
  }

  public markSearchAsSeen(search: SearchIntelModel): void {
    if (search.seenAt) {
      return;
    }
    search.seenAt = new Date().toDateString();
    this.onSearchMarkedAsSeen.next(search);
    this.setSeenOnIntelSearchResults([search.id]);
  }

  private setSeenOnIntelSearchResults(ids: string[]): void {
    const params = new HttpParams().appendAll({ ids });
    this.httpClient
      .post(`${this.fastAPIurl}/intel-search/read`, null, { params })
      .pipe(
        catchError((e: HttpErrorResponse | Error) => {
          if (e instanceof HttpErrorResponse) {
            this.handleOsintError(e);
          }
          return throwError(() => e);
        })
      )
      .subscribe();
  }
}
