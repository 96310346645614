<app-base-modal #modalRef [footerWithBorder]="false">
  <div header>{{ 'Create or add to case' | translate }}</div>

  <div body>
    <form [formGroup]="addToCaseForm">
      <ng-container>
        <div class="field-group">
          <label *ngIf="!!data?.targets">{{ 'Case' | translate }}</label>
          <label *ngIf="!!data?.group" class="group-label">
            <div class="group-link">{{ group.groupLink }}</div>
            <div class="delimiter"></div>
            <div class="members-count">
              {{ group.participantsCount }} {{ 'participants' | translate }}
            </div>
          </label>
          <mat-form-field class="example-chip-list input-box m-0">
            <mat-chip-list #chipList>
              <mat-chip
                *ngFor="let case of selectedCases"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="caseRemoved(case)"
                [class.expired]="caseCreditsChargesEnabled && case.expired"
                matTooltip="{{
                  caseCreditsChargesEnabled && case.expired
                    ? ('Case is expired' | translate)
                    : return
                }}"
                [matTooltipDisabled]="
                  !caseCreditsChargesEnabled && !case.expired
                "
              >
                {{ case.caseName }}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input
                class="assistant-font"
                #caseInput
                formControlName="caseName"
                (keydown)="onKeyDown($event)"
                name="caseName"
                placeholder="{{
                  'Create new case or search for existing one' | translate
                }}"
                (matChipInputTokenEnd)="addChip(caseInput)"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              />
            </mat-chip-list>
            <mat-autocomplete
              autoActiveFirstOption
              #auto="matAutocomplete"
              [displayWith]="displayFn"
              (optionSelected)="caseSelected($event)"
            >
              <mat-option
                *ngFor="let case of casesList$ | async"
                [value]="case"
              >
                {{ case.caseName }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div
            class="error-wrp"
            *ngIf="
              addToCaseForm.controls.caseName.invalid &&
              addToCaseForm.controls.caseName.dirty &&
              !selectedCases.length
            "
          >
            <mat-error
              *ngIf="addToCaseForm.controls.caseName.hasError('required')"
            >
              {{ 'Title is required' | translate }}
            </mat-error>
            <mat-error
              *ngIf="addToCaseForm.controls.caseName.hasError('maxlength')"
            >
              {{ 'You have more than 255 characters' | translate }}
            </mat-error>
            <mat-error
              *ngIf="addToCaseForm.controls.caseName.hasError('pattern')"
            >
              {{
                'Must be at least 1 character long and maximum characters allowed are 255, special charactes and preceding or following spaces are not allowed'
                  | translate
              }}
            </mat-error>
          </div>
        </div>
      </ng-container>
      <div
        class="col-md-12"
        *ngIf="caseCreditsChargesEnabled && creditsForExpired > 0"
      >
        <p class="ubuntu-font note-txt">
          {{ 'Expired cases will be renewed' | translate }}
        </p>
      </div>
      <div
        class="col-md-12"
        *ngIf="caseCreditsChargesEnabled && !selectedCases.length"
      >
        <p class="ubuntu-font note-txt">{{ caseCreditsMessage }}</p>
      </div>
    </form>
  </div>

  <div footer class="buttons">
    <intellectus-text-button
      *ngIf="selectedCases.length && !!selectedCases[0].createdAt"
      (click)="addToCase()"
    >
      {{ 'Add to existing case' | translate }}
      <span *ngIf="isUnlimitedTheme" class="demo-sign">DEMO</span>
      <ng-container *ngIf="caseCreditsChargesEnabled && creditsForExpired > 0">
        <span *ngIf="!isUnlimitedTheme">|</span>
        <span *ngIf="!isUnlimitedTheme" class="credits">{{
          creditsForExpired
        }}</span>
      </ng-container>
    </intellectus-text-button>

    <intellectus-text-button
      *ngIf="!selectedCases.length || !selectedCases[0].createdAt"
      [isDisabled]="!selectedCases.length"
      (click)="newCase()"
    >
      {{ 'Add to case' | translate }}
    </intellectus-text-button>

    <intellectus-text-link-button (click)="modalRef.onClose()">
      {{ 'Cancel' | translate }}
    </intellectus-text-link-button>
  </div>
</app-base-modal>
