import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AppConfigService } from '@app/config';
import { Observable, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Action } from 'src/app/shared/classes/action.class';
import { ExpiredDialogComponent } from 'src/app/shared/components/expired-dialog/expired-dialog.component';
import { BillingActions } from 'src/app/shared/models/billing-action.model';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { BaseService } from '../base.service';
import { TranslationService } from '../translation/translation.service';
import { TargetService } from './target.service';

@Injectable({
  providedIn: 'root',
})
export class TargetRenewalService extends BaseService {
  expireTargetDays = 0;
  targetCreditsChargesEnabled = false;
  constructor(
    public router: Router,
    public snackBar: MatSnackBar,
    private translationService: TranslationService,
    private appConfigService: AppConfigService,
    public dialog: MatDialog,
    private targetService: TargetService
  ) {
    super(router, snackBar);
    this.expireTargetDays =
      this.appConfigService.getConfigVariable('expireTargetDays');
    this.targetCreditsChargesEnabled = this.appConfigService.getConfigVariable(
      'enableCreditChargesForTarget'
    );
  }

  isTargetExpiredShowMessageOrRenew(
    targets: TargetItem[],
    allowRenew = false
  ): Observable<boolean> {
    if (!this.isTargetsExpired(targets)) {
      return of(true);
    }

    if (allowRenew) {
      return this.renewTargetDialog(targets);
    }

    this.showMessage(this.translationService.translate('Target is expired'));
    return of(false);
  }

  isTargetsExpired(targets: TargetItem[]): boolean {
    if (!this.targetCreditsChargesEnabled) {
      return false;
    }

    return targets.some((t) => t.expired);
  }

  isTargetExpired(target: TargetItem): boolean {
    if (!this.targetCreditsChargesEnabled) {
      return false;
    }

    return target.expired;
  }

  renewTargetDialog(targets: TargetItem[]): Observable<boolean> {
    const expiredTargets = targets.filter((t) => t.expired);
    return this.dialog
      .open(ExpiredDialogComponent, {
        width: '450px',
        data: {
          title: this.translationService.translate('Target is expired'),
          message: this.translationService.interpolate(
            'Click on the button below to renew for #{days} days',
            {
              days: this.expireTargetDays.toString(),
            }
          ),
          action: BillingActions.TARGET_MANAGEMENT,
          countOfActionsCharged: expiredTargets.length,
        },
        panelClass: 'expired-credit',
      })
      .afterClosed()
      .pipe(
        switchMap((result: Action) => {
          if (!result?.key.includes('success')) {
            return of(false);
          }
          return new Observable<boolean>((observable) => {
            const targetIds = expiredTargets.map((t) => t.id);
            const subscription: Subscription = this.targetService
              .renewMultipleTargets(targetIds)
              .subscribe(
                () => {
                  this.showMessage(
                    this.translationService.translate(
                      'Targets renewed successfully!'
                    )
                  );
                  expiredTargets.forEach((target) => (target.expired = false));
                  observable.next(true);
                },
                (error) => {
                  this.showMessage(
                    this.translationService.translate(
                      error ? error : 'Targets has not been renewed'
                    )
                  );
                  observable.next(false);
                },
                () => {
                  subscription.unsubscribe();
                  observable.complete();
                }
              );
          });
        })
      );
  }
}
