<ng-template
  #proximity
  let-near="near"
  let-subject="subject"
  let-accuracy="accuracy"
>
  <div class="click-notification">
    <span>
      {{ subject }}
      {{ 'was found near' | translate }} {{ near }} {{ 'in' | translate }}
      {{ accuracy }}km.
    </span>
  </div>
</ng-template>

<ng-template
  #geofenceCountry
  let-inside="inside"
  let-countryCode="countryCode"
  let-subject="subject"
>
  <div class="click-notification">
    <span>
      <b>{{ subject }}</b>
      {{ (inside ? 'entered' : 'left') | translate }}
      {{ countryCode | country }}.
    </span>
  </div>
</ng-template>

<ng-template
  #geoqueryArea
  let-inside="inside"
  let-area="area"
  let-subject="subject"
>
  <div class="click-notification">
    <span>
      {{ subject }}
      {{ 'was found' | translate }}
      {{ (inside ? 'inside' : 'outside') | translate }} {{ area }}.
    </span>
  </div>
</ng-template>

<ng-template
  #geoqueryScheduleEnded
  let-targetTelno="content?.schedule?.payload?.queryArgs?.telno"
  let-targetImsi="content?.schedule?.payload?.queryArgs?.imsi"
  let-id="id"
>
  <div class="click-notification">
    <span>
      {{ 'Tracking of' | translate }} {{ targetTelno || targetImsi }}
      {{ 'completed' | translate }}
    </span>
    <button
      mat-button
      color="primary"
      class="discovery-button"
      (click)="navigateToDiscovery(id, targetTelno, targetImsi, queryId)"
    >
      <span *ngIf="newDiscoveryEnabled" class="view-button">{{
        'View' | translate
      }}</span>
    </button>
  </div>
</ng-template>

<ng-template
  #geoqueryLogged
  let-imsi="content.query.queryArgs.imsi"
  let-telno="content.query.queryArgs.telno"
  let-queryId="content.query.id"
  let-id="id"
>
  <div class="click-notification">
    <span>
      {{ 'Location of' | translate }} {{ telno || imsi }}
      {{ 'found (Tracking terminated)' }}
    </span>
    <button
      mat-button
      color="primary"
      class="discovery-button"
      (click)="navigateToDiscovery(id, telno, imsi, queryId)"
    >
      <span *ngIf="newDiscoveryEnabled" class="view-button">{{
        'View' | translate
      }}</span>
    </button>
  </div>
</ng-template>

<ng-template #geoqueryLogonStatusOff let-subject="subject">
  <div class="click-notification">
    <span>
      {{ subject }}
      {{ 'is Offline. Last Known Location found' | translate }}.
    </span>
  </div>
</ng-template>

<ng-template #push let-subject="notificationText" let-timestamp="timestamp">
  <div class="click-notification">
    <span> {{ notificationText }}. </span>
  </div>
</ng-template>

<ng-template #releasenotes let-subject="notificationText" let-text="text">
  <div class="click-notification">
    <span>
      {{ notificationText }}
    </span>
    <div *ngIf="!isMobileResolution" class="button-action-link">
      <button
        mat-button
        class="cp-btn-ghost"
        [ngClass]="{ mobile: isMobileResolution }"
        (click)="showPopup(notificationText, text)"
      >
        {{ 'View' | translate }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #locationHistoryNotFound let-timestamp="timestamp">
  <span>
    <b>{{ 'Location History Not Found' | translate }}.</b>
  </span>
</ng-template>

<ng-template #locationHistoryNotFoundTellcell let-queryArgs="queryArgs">
  <div class="click-notification">
    <span>
      {{ 'Location History Not Found' | translate }}.&nbsp;{{
        'Request from Support' | translate
      }}?
    </span>
    <mat-icon
      class="clickable notification-new-query"
      matTooltip="{{ 'Location History Request' | translate }}"
      (click)="requestLocationHistory(queryArgs, id)"
      >done</mat-icon
    >
    <mat-icon
      class="clickable notification-dismiss"
      matTooltip="{{ 'Clear' | translate }}"
      (click)="markAsRead(id)"
      >clear
    </mat-icon>
  </div>
</ng-template>

<ng-template
  #locationHistoryFound
  let-queriesCount="queriesCount"
  let-timestamp="timestamp"
>
  <span>
    <b
      >{{ 'Location History Found' | translate }}.{{ queriesCount }}&nbsp;{{
        'entries were imported' | translate
      }}.</b
    >
  </span>
</ng-template>

<ng-template
  #tacticalNotifyOnCapture
  let-queryArgs="queryArgs"
  let-satellite="satellite"
  let-timestamp="timestamp"
>
  <span>
    <b
      >{{ queryArgs.imsi }}&nbsp;{{
        'has been captured on' | translate
      }}&nbsp;{{ satellite }}.</b
    >
  </span>
</ng-template>

<ng-template
  #radicalMonitoringOnDetect
  let-content="content"
  let-target="target"
>
  <p class="notification-label">
    {{ 'Radical monitoring matched a keyword for' | translate }}
    <span (click)="navigateToTarget(target.id)">{{
      target ? target.alias : ''
    }}</span>
  </p>
  <p
    class="notification-content"
    innerHTML="{{
      highlightText(content.payload?.data?.content, content.keywords)
    }}"
  ></p>

  <div class="notification-actions">
    <button
      class="notification-action"
      mat-flat-button
      (click)="navigateToRadicalMonitoring(target, content)"
    >
      <mat-icon>filter_none</mat-icon> {{ 'View item' | translate }}
    </button>
  </div>
</ng-template>

<ng-template
  #callLogUpload
  let-author="content?.author"
  let-targetTelno="content?.targetTelno"
  let-date="content?.date"
  let-id="id"
>
  <div class="click-notification">
    <span>
      {{ 'Call log upload request for author' | translate }}
      {{ author }}, {{ 'with msisdn' | translate }}
      {{ targetTelno }}
      {{ 'has finished' | translate }}.
    </span>
  </div>
</ng-template>

<ng-template
  #callLogUploadCanceled
  let-callLogUrl="content.callLogUrl"
  let-statusCode="content.statusCode"
  let-alias="content.alias"
  let-value="content.value"
  let-author="author"
  let-uploadedAt="content.uploadedAt"
  let-id="id"
>
  <div class="click-notification">
    <span>
      {{ 'Call log upload request for author' | translate }} {{ author }}
      {{ 'with msisdn' | translate }} {{ value }},
      {{ 'has been canceled and refunded' | translate }}.
    </span>
  </div>
</ng-template>
<ng-template
  #callLogUploadFinished
  let-callLogUrl="content.callLogUrl"
  let-statusCode="content.statusCode"
  let-alias="content.alias"
  let-value="content.value"
  let-uploadedAt="content.uploadedAt"
  let-msisdn="content.msisdn"
>
  <div
    *ngIf="enableCdrAnyFormatUpload"
    class="click-notification"
    (click)="markAsRead(id)"
  >
    <span>
      {{ 'Call Log for' | translate }} {{ msisdn || value }}
      {{ 'was uploaded successfully' | translate }}.
      <div *ngIf="!isMobileResolution" class="button-action-link">
        <button
          *ngIf="callLogUrl"
          mat-button
          class="cp-btn-ghost"
          [ngClass]="{ mobile: isMobileResolution }"
          (click)="navigateToCallLogUrl(callLogUrl)"
        >
          {{ 'View' | translate }}
        </button>
      </div>
    </span>
  </div>

  <ng-container *ngIf="!enableCdrAnyFormatUpload">
    <div class="click-notification" *ngIf="statusCode === 200">
      <span>
        {{ 'Call Log for' | translate }} {{ alias || value }}
        {{ 'was successfully delivered' | translate }}.
        <div *ngIf="!isMobileResolution" class="button-action-link">
          <button
            *ngIf="callLogUrl"
            mat-button
            class="cp-btn-ghost"
            [ngClass]="{ mobile: isMobileResolution }"
            (click)="navigateToCallLogUrl(callLogUrl)"
          >
            {{ 'View' | translate }}
          </button>
        </div>
      </span>
    </div>
  </ng-container>

  <div
    class="click-notification"
    *ngIf="statusCode !== 200"
    (click)="markAsRead(id)"
  >
    <span>
      {{ 'Call Log for' | translate }} {{ alias || value }},
      {{ 'has failed' | translate }}
    </span>
  </div>
</ng-template>

<ng-template
  #callLogUploadImporting
  let-callLogUrl="content.callLogUrl"
  let-msisdn="content.msisdn"
>
  <div class="click-notification" (click)="markAsRead(id)">
    <span>
      {{ 'Call Log for' | translate }} {{ msisdn || value }}
      {{ 'is importing' | translate }}.
    </span>
  </div>
</ng-template>

<ng-template
  #callLogUploadUnmapped
  let-callLogUrl="content.callLogUrl"
  let-msisdn="content.msisdn"
>
  <div class="click-notification" (click)="markAsRead(id)">
    <span>
      {{ 'Call Log for' | translate }} {{ msisdn || value }}
      {{ 'could not be mapped automatically' | translate }}.
    </span>
  </div>
</ng-template>

<ng-template
  #callLogUploadFailed
  let-callLogUrl="content.callLogUrl"
  let-msisdn="content.msisdn"
>
  <div class="click-notification" (click)="markAsRead(id)">
    <span>
      {{ 'Call Log for' | translate }} {{ msisdn || value }}
      {{ 'has failed to upload' | translate }}.
    </span>
  </div>
</ng-template>

<ng-template
  #advancedGeoQuery
  let-targetTelno="content.targetTelno"
  let-queryStatus="content.queryStatus"
>
  <div class="click-notification">
    <span>
      {{ targetTelno }}
      {{
        queryStatus === 'NO LOCATION'
          ? 'finished'
          : ('was located successfully' | translate)
      }}.
    </span>
  </div>
</ng-template>

<ng-template
  #querycallinfo
  let-targetTelno="content?.queryArgs?.telno"
  let-targetImsi="content?.queryArgs?.imsi"
  let-queryId="content?.id"
  let-id="id"
>
  <div class="click-notification">
    <span>
      {{ targetTelno || targetImsi }}
      {{ 'is on a call. Retrieve caller?' | translate }}
    </span>
    <button
      mat-button
      color="primary"
      class="discovery-button"
      (click)="navigateToDiscovery(id, targetTelno, targetImsi, queryId)"
    >
      <span *ngIf="newDiscoveryEnabled" class="view-button">{{
        'View' | translate
      }}</span>
    </button>
  </div>
</ng-template>

<ng-template
  #liveSession
  let-subject="notificationText"
  let-liveSession="liveSession"
>
  <div class="click-notification" (click)="markAsRead(id)">
    <span>
      {{ 'Live Session has been successfully obtained' | translate }}
    </span>
    <div>
      <a href="{{ liveSession.url }}" target="_blank">{{ liveSession.url }}</a>
    </div>
    <div>
      {{ liveSession.password }}
      <img
        class="copyToClipboard clickable"
        [copyClipboard]="liveSession.password"
        src="assets/static/images/copy_all.svg"
        matTooltip="{{ 'Copy to Clipboard' | translate }}"
      />
    </div>
  </div>
</ng-template>

<ng-template
  #extractusd
  let-text="content.text"
  let-id="id"
  let-target="target"
>
  <div class="click-notification" (click)="markAsRead(id)">
    <span>
      <b>{{ text }}</b>
    </span>
  </div>
</ng-template>

<ng-template #system let-text="content.text" let-actions="content.actions">
  <div class="system-notification">
    <p *ngIf="text">
      {{ text }}
    </p>
    <button
      *ngFor="let action of actions"
      mat-button
      class="notification-action"
      (click)="action.onClick()"
    >
      {{ action.label }}
    </button>
  </div>
</ng-template>

<ng-template
  #targetOrCaseExpired
  let-entityAlias="content.entityAlias"
  let-entityType="content.entityType"
>
  <div class="click-notification">
    <span *ngIf="entityType">
      {{ entityType | translate }} - {{ entityAlias }}
      {{ 'is expired' | translate }}
    </span>
  </div>
</ng-template>

<ng-template
  #somedusStatus
  let-entityAlias="content.targetAlias"
  let-targetId="content.targetId"
  let-platform="content.platform"
  let-status="content.status"
>
  <!-- TODO remove the disabled state of actions when we implement all mobile screens  -->
  <div class="click-notification">
    <span *ngIf="status === 'DONE'">
      SOMEDUS {{ platform | titlecase }} completed successfully. Information
      also added in
      <span
        class="underline"
        [ngClass]="{ mobile: isMobileResolution }"
        (click)="navigateToTarget(targetId)"
        >{{ entityAlias }}</span
      >
      profile
    </span>
    <span *ngIf="status === 'ABANDONED'">
      SOMEDUS Failed to retrieve {{ platform | titlecase }} details. Information
      not added in
      <span
        class="underline"
        [ngClass]="{ mobile: isMobileResolution }"
        (click)="navigateToTarget(targetId)"
        >{{ entityAlias }}</span
      >
      profile
    </span>
    <span *ngIf="status === 'EXPIRED'">
      SOMEDUS Expired to retrieve {{ platform | titlecase }} details.
      Information not added in
      <span
        class="underline"
        [ngClass]="{ mobile: isMobileResolution }"
        (click)="navigateToTarget(targetId)"
        >{{ entityAlias }}</span
      >
      profile
    </span>
  </div>
</ng-template>

<ng-template
  #geoqueryDeactivatedState
  let-robot="content.query.schedule.scheduleType"
  let-telno="content.query.queryArgs.telno"
  let-id="id"
>
  <div class="click-notification">
    <span>
      {{ robot | titlecase | translate }} {{ 'has terminated' | translate }}.
      {{ telno }} {{ 'is deactivated' | translate }}.
    </span>
  </div>
</ng-template>

<ng-template
  #avatarExpired
  let-expiredAvatars="expiredAvatars"
  let-soonToExpireAvatars="soonToExpireAvatars"
>
  <div *ngIf="soonToExpireAvatars?.length" class="click-notification">
    <div *ngIf="soonToExpireAvatars.length > 1; else single_avatar">
      {{ 'Virtual IDs will expire in 24 hours' }}
      <!-- TODO enable the div on all views (currently hidden until we implement all the mobile features) -->
      <div *ngIf="!isMobileResolution" class="button-action-link">
        <a routerLink="'virtual-id'">
          <button
            mat-button
            class="cp-btn-ghost"
            [ngClass]="{ mobile: isMobileResolution }"
          >
            {{ 'View' | translate }}
          </button></a
        >
      </div>
    </div>
    <ng-template #single_avatar>
      {{ 'Virtual ID' | translate }} -
      {{ soonToExpireAvatars[0].person.fullName }}
      {{ 'will expire soon' | translate }}
    </ng-template>
  </div>
  <div *ngIf="expiredAvatars?.length" class="click-notification">
    <div *ngIf="expiredAvatars.length > 1; else single_avatar">
      {{ 'Virtual IDs have expired' }}
      <div *ngIf="!isMobileResolution" class="button-action-link">
        <a routerLink="'virtual-id'">
          <button
            mat-button
            class="cp-btn-ghost"
            [ngClass]="{ mobile: isMobileResolution }"
          >
            {{ 'View' | translate }}
          </button></a
        >
      </div>
    </div>
    <ng-template #single_avatar let-avatar="expiredAvatars[0]">
      {{ 'Virtual ID' | translate }} - {{ expiredAvatars[0].person.fullName }}
      {{ 'is expired' | translate }}
    </ng-template>
  </div>
</ng-template>

<ng-template #targetMonitoringAlert let-id="id" let-text="text">
  <div class="click-notification">
    <span>
      {{ text }}
    </span>
  </div>
  <div *ngIf="!isMobileResolution" class="button-action-link">
    <button
      mat-button
      class="cp-btn-ghost"
      [ngClass]="{ mobile: isMobileResolution }"
      (click)="navigateToAlertProfile(id)"
    >
      {{ 'View' | translate }}
    </button>
  </div>
</ng-template>

<div
  *ngIf="template"
  class="notification row"
  [ngClass]="{
    read: notification.isRead,
    unread: !notification.isRead,
    mobile: isMobileResolution,
    'button-padding':
      (template === 'somedusStatus' ||
        template === 'callLogUploadFinished' ||
        template === 'avatarExpired' ||
        template === 'targetMonitoringAlert') &&
      !isMobileResolution
  }"
  (click)="createActionBasedOnNotificationType(notification)"
>
  <div class="createdAt col-12">
    {{ notification.createdAt | date: 'MMM d, y, H:mm:ss' }}
  </div>
  <div [ngClass]="notification.isRead ? 'read' : 'unread'"></div>
  <ng-container *ngIf="template === 'querycallinfo'">
    <div *ngTemplateOutlet="querycallinfo; context: context"></div>
  </ng-container>

  <ng-container *ngIf="template === 'callLogUploadFinished'">
    <div *ngTemplateOutlet="callLogUploadFinished; context: context"></div>
  </ng-container>

  <ng-container *ngIf="template === 'callLogUploadImporting'">
    <div *ngTemplateOutlet="callLogUploadImporting; context: context"></div>
  </ng-container>

  <ng-container *ngIf="template === 'callLogUploadUnmapped'">
    <div *ngTemplateOutlet="callLogUploadUnmapped; context: context"></div>
  </ng-container>

  <ng-container *ngIf="template === 'callLogUploadFailed'">
    <div *ngTemplateOutlet="callLogUploadFailed; context: context"></div>
  </ng-container>

  <ng-container *ngIf="template === 'callLogUploadCanceled'">
    <div *ngTemplateOutlet="callLogUploadCanceled; context: context"></div>
  </ng-container>

  <ng-container *ngIf="template === 'geoqueryArea'">
    <div *ngTemplateOutlet="geoqueryArea; context: context"></div>
  </ng-container>

  <ng-container *ngIf="template === 'geoqueryLogged'">
    <div *ngTemplateOutlet="geoqueryLogged; context: context"></div>
  </ng-container>

  <ng-container *ngIf="template === 'geoqueryLogonStatusOff'">
    <div *ngTemplateOutlet="geoqueryLogonStatusOff; context: context"></div>
  </ng-container>

  <ng-container *ngIf="template === 'proximity'">
    <div *ngTemplateOutlet="proximity; context: context"></div>
  </ng-container>

  <ng-container *ngIf="template === 'geofenceCountry'">
    <div *ngTemplateOutlet="geofenceCountry; context: context"></div>
  </ng-container>

  <ng-container *ngIf="template === 'geoqueryScheduleEnded'">
    <div *ngTemplateOutlet="geoqueryScheduleEnded; context: context"></div>
  </ng-container>

  <ng-container *ngIf="template === 'push'">
    <div *ngTemplateOutlet="push; context: context"></div>
  </ng-container>

  <ng-container *ngIf="template === 'releasenotes'">
    <div *ngTemplateOutlet="releasenotes; context: context"></div>
  </ng-container>

  <ng-container *ngIf="template === 'locationHistoryNotFound'">
    <div *ngTemplateOutlet="locationHistoryNotFound; context: context"></div>
  </ng-container>

  <ng-container *ngIf="template === 'locationHistoryNotFoundTellcell'">
    <div
      *ngTemplateOutlet="locationHistoryNotFoundTellcell; context: context"
    ></div>
  </ng-container>

  <ng-container *ngIf="template === 'locationHistoryFound'">
    <div *ngTemplateOutlet="locationHistoryFound; context: context"></div>
  </ng-container>

  <ng-container *ngIf="template === 'tacticalNotifyOnCapture'">
    <div *ngTemplateOutlet="tacticalNotifyOnCapture; context: context"></div>
  </ng-container>

  <ng-container *ngIf="template === 'radicalMonitoringOnDetect'">
    <div *ngTemplateOutlet="radicalMonitoringOnDetect; context: context"></div>
  </ng-container>

  <ng-container *ngIf="template === 'callLogUpload'">
    <div *ngTemplateOutlet="callLogUpload; context: context"></div>
  </ng-container>

  <ng-container *ngIf="template === 'advancedGeoQuery'">
    <div *ngTemplateOutlet="advancedGeoQuery; context: context"></div>
  </ng-container>

  <ng-container *ngIf="template === 'live-session'">
    <div *ngTemplateOutlet="liveSession; context: context"></div>
  </ng-container>

  <ng-container *ngIf="template === 'quarantine'">
    <div *ngTemplateOutlet="quarantine; context: context"></div>
  </ng-container>

  <ng-container *ngIf="template === 'extractusd'">
    <div *ngTemplateOutlet="extractusd; context: context"></div>
  </ng-container>

  <ng-container *ngIf="template === 'system'">
    <div *ngTemplateOutlet="system; context: context"></div>
  </ng-container>

  <ng-container *ngIf="template === 'targetOrCaseExpired'">
    <div *ngTemplateOutlet="targetOrCaseExpired; context: context"></div>
  </ng-container>

  <ng-container *ngIf="template === 'geoqueryDeactivatedState'">
    <div *ngTemplateOutlet="geoqueryDeactivatedState; context: context"></div>
  </ng-container>

  <ng-container *ngIf="template === 'somedusStatus'">
    <div *ngTemplateOutlet="somedusStatus; context: context"></div>
  </ng-container>

  <ng-container *ngIf="template === 'avatarExpired'">
    <div *ngTemplateOutlet="avatarExpired; context: context"></div>
  </ng-container>

  <ng-container *ngIf="template === 'targetMonitoringAlert'">
    <div *ngTemplateOutlet="targetMonitoringAlert; context: context"></div>
  </ng-container>
</div>

<div
  *ngIf="isMobileResolution && skin === 'FAMILY_FINDER'"
  class="notification-mobile"
>
  <div class="row middle-xs notification-item-mob">
    <div class="col-3 center-xs">
      <div>
        <mat-icon class="pin">place</mat-icon>
      </div>
    </div>
    <div class="col-9">
      <div class="notification-text">
        {{ notificationText }}
      </div>
    </div>
  </div>
</div>
